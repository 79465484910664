import React, { useState, useEffect } from "react";
import Urls, { BASE_URL, postRequest } from "../../../../services/common/urls";
import moment from "moment";
import "./jobFilesModal.css";
import { Form, DatePicker, Modal, Input, Button, Row, Col, message, Popconfirm, Spin } from "antd";
import { MinusSquareFilled } from "@ant-design/icons"
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
import {
  GetJobEntryList,
  ExecuteQuery,
  AddEntry,
} from "../../../../services/common/cnc_functions";
import { file } from "@babel/types";

const dateFormat = "YYYY-MM-DD HH:mm:ss";




const GuideWidget = (props) => {
  const { langIndex } = useSelector((x) => x.app);
  const { info, onClick, index, onDelete, files, security_level } = props;
  return <Row align={'middle'} justify={'space-between'}>
    <Col>
      <span className="custom-button" onClick={() => onClick(info)}>
        {info}
      </span>
    </Col>
    {security_level != 0 && (
      <Popconfirm
        title={lang(langIndex, 'jobentry_suretodelete')}
        onConfirm={() => onDelete(files, index)}
      >
        <Col>
          <span className="custom-button">
            {(info != "") && <MinusSquareFilled />}
          </span>
        </Col>
      </Popconfirm>
    )}
  </Row>;

}

function JobFilesModal(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { selCustomerId, Id, files, setFiles, updateEntry, isShowFiles, setIsShowFiles, security_level } = props;
  const [isBusy, setBusy] = useState(false);

  const updateJobEntry = (newFiles) => {
    let query =
      "UPDATE " +
      "`" +
      selCustomerId +
      "_jobdata` " +
      "SET `files`='" +
      newFiles +
      "' " +
      "WHERE `Id`=" +
      Id;
    setBusy(true)
    ExecuteQuery(query, (res) => {
      setFiles(newFiles);
      updateEntry(Id, { "files": newFiles });
      setBusy(false)
    });
  };

  const onClickGuide = (url) => {
    window.open(BASE_URL + "files/" + url)
  };

  const onClickDelete = (files, index) => {
    var fileList = files.split(':');
    var newFiles = "";
    for (var i = 0; i < fileList.length; i++) {
      if (i != index) {
        if (newFiles == "") {
          newFiles = fileList[i];
        } else {
          newFiles = newFiles + ":" + fileList[i];
        }
      }
    }
    updateJobEntry(newFiles);
  };

  const onClickCancel = () => {
    setIsShowFiles(false);
  };

  const GuideListUI = files != null ?  files.split(':').map((one_guide, index) => {
    return <GuideWidget
      key={`guide-key-${index}`}
      info={one_guide}
      index={index}
      files={files}
      security_level={security_level}
      onDelete={onClickDelete}
      onClick={onClickGuide}
    />
  }) : '';

  const fileSelectedHandler = (e) => {
    var newFiles = Array.from(e.target.files);
    var formData = new FormData();
    for (var i = 0; i < newFiles.length; i++) {
      formData.append("files[]", newFiles[i]);
    }
    setBusy(true)
    postRequest(
      Urls.UPLOAD_JOB_FILES,
      formData,
      (response) => {
        setBusy(false);
        var newFilesStr = files;
        if (newFilesStr == "") {
          newFilesStr = response.urls;
        } else {
          newFilesStr = newFilesStr + ":" + response.urls;
        }
        updateJobEntry(newFilesStr);
      }
    );
  };


  return (
    <div>
      <Modal
        title={lang(langIndex, "job_entry_files")}
        visible={isShowFiles}
        onCancel={onClickCancel}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        destroyOnClose={true}
        className="entry-add-modal"
      >
        <Spin spinning={isBusy}>
          {GuideListUI}
          {security_level != 0 && (
            <Row style={{ marginTop: 30 }}>
              <Col span={8}>
                <span>{lang(langIndex, "new_files")}</span>
              </Col>
              <Col span={16}>
                <form>
                  <input type="file" accept=".png, .jpg, .jpeg, .pdf" multiple onChange={fileSelectedHandler} />
                </form>
              </Col>
            </Row>
          )}

        </Spin>
      </Modal>
    </div>
  );
}

export default JobFilesModal;
