import React, { useState, useEffect } from "react";
import "./maintenancePage.css";
import { message, Button } from "antd";

import MaintenanceTable from "./maintenanceTable/maintenanceTable";
import MaintenanceModal from "./maintenanceModal/maintenanceModal";
import MaintenanceFilesModal from  "./maintenanceFilesModal/maintenanceFilesModal";

import {
  GetMaintenanceEntry,
  ExecuteQuery,
  AddMaintenanceEntry,
  DeleteMaintenanceEntry,
  UpdateMaintenanceEntry,
  GetIncycleTimes,
} from "../../../services/common/cnc_functions";

import { useSelector } from "react-redux";
import lang from "../../../services/lang";
import { file } from "@babel/types";

function MaintenancePage(props) {
  const authData = useSelector((x) => x.authService);
  const { username, security_level, fullname } = authData;
  const { langIndex } = useSelector((x) => x.app);
  const { selCustomerId, machineList } = props;
  const [entryList, setEntryList] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(undefined);
  const [isBusy, setIsBusy] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const [isShowFiles, setIsShowFiles] = useState(false);
  const [files, setFiles] = useState("");
  const [RecordId, setRecordId] = useState("");



  useEffect(() => {
    GetMaintenanceEntry(selCustomerId, (entryList) => {
      if (entryList != null || entryList.length > 0) {
        var workingItemList = [];
        entryList.forEach(function (entry) {
          entry.key = entry.id;
          if (entry.start > 0) {
            workingItemList.push(entry);
          }
        });
        setEntryList(entryList);
        if (workingItemList.length > 0)
          calcRemainTimes(workingItemList, entryList);
      } else {
      }
    });
  }, [machineList]);
  const calcRemainTimes = (workingItemList, initEntryList) => {
    var params = [];
    var machineIds = [];
    var startTimes = [];
    for (var i = 0; i < workingItemList.length; i++) {
      machineIds.push(workingItemList[i].machine_id);
      startTimes.push(workingItemList[i].start);
    }
    params = {
      customer_id: selCustomerId,
      machine_ids: machineIds,
      start_times: startTimes,
    };
    GetIncycleTimes(params, (res) => {
      var updateEntryList = initEntryList;
      for (var i = 0; i < res.length; i++) {
        var id = res[i].machine_id;
        var incycle_time = res[i].incycle_time;
        var entry = updateEntryList.filter((x) => x.machine_id == id);
        if (entry.length > 0) {
          entry = entry[0];
          entry.completed_hours = entry.frequency * 3600 - incycle_time;
        }
      }
      setEntryList([...updateEntryList]);
    });
  };

  const updateEntry = (data) => {

    //console.log("new Record ", data);

    const newData = [...entryList];
    
    const index = newData.findIndex((item) => item.id == data.id);
    
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...data });
      const newItem = newData[index];
      newItem.picture = newItem.picture.substring(newItem.picture.lastIndexOf("/") + 1); // Removes the whole link and just gets the name of the picture uploaded
      data = { ...data,customer_id: selCustomerId, entry_id: newItem.id, machine: newItem.machine_id, taskName: newItem.task_name, taskCategory: newItem.task_category, taskPicture: newItem.taskPicture, taskInstruction: newItem.task_instruction, frequency: newItem.frequency, cycleStartInterlock: newItem.interlock,taskPicture: newItem.picture, files: newItem.files};

      UpdateMaintenanceEntry(data, (res) => {
        setIsBusy(false);
        if (res.status == true) {
          setEntryList(newData);
          message.success(lang(langIndex, "msg_update_success"));
  }
  else{
    message.error(lang(langIndex, "msg_something_wrong"));
  }

    });
    setIsShowModal(false);
  };
}

  const addEntry = (data) => {
    setIsBusy(true);
    data = { ...data, customer_id: selCustomerId };
    //console.log(data + "  h");
    AddMaintenanceEntry(data, (res) => {
      setIsBusy(false);
      if (res.status == true) {
        var newItem = { ...res.data[0], key: res.data[0].id };
        const index = entryList.findIndex((item) => item.id == newItem.id);
        if (index > -1) {
          message.error(lang(langIndex, "msg_something_wrong"));
        } else {
          setEntryList([...entryList, newItem]);
          message.success(lang(langIndex, "msg_update_success"));
        }
      } else {
        message.error(lang(langIndex, "msg_something_wrong"));
      }
      setIsShowModal(false);
    });
  };

  const deleteEntry = (data) => {
    setIsBusy(true);
    data = { ...data, customer_id: selCustomerId, entry_id: data.id};
    var removedId = data.id;

    DeleteMaintenanceEntry(data, (res) => {
      setIsBusy(false);
      if (res.status == true) {
        
        const newData = entryList.filter(element => element.id != removedId); // makes the shown list include every item except the recently deleted item
        setEntryList(newData);       
        message.success(lang(langIndex, "msg_update_success"));
       
      }
      else {
        message.error(lang(langIndex, "msg_something_wrong"));
      }
      setIsShowModal(false);
    });
  };

  

  const onClickEditRecord = (entry) => {
    setSelectedEntry(entry);
    setIsShowModal(true);
  };
  const onDeleteRecord = (entry) => {
    const newData = entryList.filter((element) => element != entry);
    setEntryList(newData);
    //console.log("before delete");

    let query =
      "DELETE FROM " +
      "`" +
      selCustomerId +
      "_maintenance` " +
      "WHERE " +
      "`" +
      selCustomerId +
      "_maintenance`.`id` = "+
      entry.id;

    //console.log(query);
    ExecuteQuery(query, (res) => {
      message.success(lang(langIndex, "msg_delete_success"));
      //console.log("After delete");
    });
  };

  return (
    <div>
      {isShowFiles && (
        <MaintenanceFilesModal
          files={files}
          setFiles={setFiles}
          addEntry={addEntry}
          deleteEntry={deleteEntry}
          updateEntry={updateEntry}
          Id={RecordId}
          security_level={security_level}
          selCustomerId={selCustomerId}
          isShowFiles={isShowFiles}
          setIsShowFiles={setIsShowFiles}
        />
      )}

      {isShowModal && (
        <MaintenanceModal
          addEntry={addEntry}
          deleteEntry={deleteEntry}
          updateEntry={updateEntry}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          machineList={machineList}
          initEntryInfo={selectedEntry}
        />
      )}
      <div style={{ textAlign: "right" }}>
        <Button
          loading={isBusy}
          ghost
          onClick={() => {
            setSelectedEntry(undefined);
            setIsShowModal(true);
          }}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          {lang(langIndex, "maintenance_addnew")}
        </Button>
      </div>
      <div>
      <MaintenanceTable
          machineList={machineList}
          matintenanceEntryList={entryList}
          onClickEditRecord={onClickEditRecord}
          onDeleteRecord={deleteEntry}
          setRecordId={setRecordId}
          setFiles={setFiles}
          setIsShowFiles={setIsShowFiles}
        />
      </div>
    </div>
  );
}

export default MaintenancePage;
