import React from 'react'
import './OneEmail.css'

function OneEmail(props) {
    const { email, deleteEmail } = props;

    return (
        <div className="one-email-container-style">
            <span className="one-email-style">{email}</span>
            <a className="one-email-delete-style" onClick={() => deleteEmail(email)}>X</a>
        </div>
    )
}

export default OneEmail
