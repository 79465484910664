import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import MyLayout from "../../components/Layout/MyLayout";
import CNCPages from "../CNCPages/CNCPages";
import "./Wrapper.css";
import {
  onSelectMenu,
  UpdateAppConfig,
} from "../../services/redux/reducers/app";
import {
  GetAllCustomerInfo,
  GetInfo,
} from "../../services/common/cnc_functions";
import { PAGETYPE } from "../../services/common/constants";
import { Route, Switch } from "react-router-dom";
import SensorPage from "../sensorPage/sensor_page";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import TMSPage from "../TMSPage/TMSPage";
import MaintenancePages from "../MaintenancePages/MaintenancePages";
import lang from "../../services/lang";

function Wrapper(props) {
  const dispatch = useDispatch();
  const authData = useSelector((x) => x.authService);
  const cncService = useSelector((x) => x.cncService);
  const appData = useSelector((x) => x.app);
  const { langIndex } = useSelector((x) => x.app);
  const [isLoading, setIsLoading] = useState(false);
  const screenSize = useWindowSize();
  // console.log("AUTHDATA", authData);
  if (authData.isAuth == false || authData.customer_id == undefined) {
    window.location.href = "/login";
  }
  
  useEffect(() => {
    UpdateAppConfig(
      { screenSize: { width: screenSize.width, height: screenSize.height } },
      dispatch
    );
  }, [screenSize]);

  useEffect(() => {
    setIsLoading(true);
    GetAllCustomerInfo(authData.customer_id, dispatch, (res) => {
      setIsLoading(false);
      if (res == false) {
        message.error(lang(langIndex, "msg_something_wrong"));
        window.location.href = "/login";
        return;
      }
      const customerIdList = Object.keys(res);
      let selCustomerId = appData.customer_id;
      if (selCustomerId == "" || !customerIdList.includes(selCustomerId)) {
        selCustomerId = customerIdList[0];

        UpdateAppConfig(
          { customer_id: selCustomerId, page_type: PAGETYPE.cnc },
          dispatch
        );
        return <div></div>;
      }
    });
  }, [authData.customer_id]);
  // console.log("CNC__DATAL:", cncService);

  const customerInfoList = cncService.customerInfoList;
  const customerIdList = Object.keys(customerInfoList);
  if (customerIdList.length == 0) {
    return <div></div>;
  }
  let selCustomerId = appData.customer_id;
  if (selCustomerId == "" || !customerIdList.includes(selCustomerId)) {
    return <div></div>;
  }

  return (
    <div className="main-background">
      {isLoading && (
        <Spin spinning={isLoading} size="large">
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 20,
              color: "#eeeeee",
              height: "100vh",
              marginTop: 200,
              background: "transparent",
            }}
          >
            {lang(langIndex, "loading_customer_info")}
          </div>
        </Spin>
      )}
      {!isLoading && (
        <MyLayout
          screenSize={screenSize}
          // username={username}
          // security_level={security_level}
          // fullname={fullname}
          // routeProps={props}
        >
          <Switch>
            <Route exact path="/pages/machine" component={CNCPages} />
            <Route exact path="/pages/tms" component={TMSPage} />
            <Route exact path="/pages/iiot" component={SensorPage} />
            <Route exact path="/pages/maintenance" component={MaintenancePages} />
            <Route exact path="/pages" component={CNCPages} />
          </Switch>
        </MyLayout>
      )}
    </div>
  );
}

export default Wrapper;

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
