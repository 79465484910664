import React, { useState, useEffect } from "react";
import "./TimeSettings.css";
import { Input } from "antd";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
function TimeSettings(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { customerInfo, updateCustomerInfo } = props;
  const [customerTimezone, setCustomerTimezone] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);
  useEffect(() => {
    // console.log(customerInfo)
    setCustomerTimezone(customerInfo.timezone);
  }, []);

  const onUpdateTimezone = () => {
    if (customerInfo.timezone == customerTimezone) return;
    let newInfo = customerInfo;
    newInfo.timezone = customerTimezone;
    updateCustomerInfo(newInfo);
  };
  return (
    <div>
      <h1
        className="time-setting-page-title"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {lang(langIndex, "plant_timesetting")}
      </h1>

      <div
        style={{ display: isCollapsed ? "none" : "block" }}
        className="time-setting-content-style"
      >
        <h1 className="timezone-title-style"> {lang(langIndex, "plant_timezone")}:</h1>
        <div className="timezone-value-container-style">
          <Input
            value={customerTimezone}
            onChange={(e) => setCustomerTimezone(e.target.value)}
            className="timezone-value-style"
            type={"number"}
          />
        </div>
        <div className="timezone-value-change-button-container-style">
          <div
            className="timezone-value-change-button"
            onClick={onUpdateTimezone}
          >
            {lang(langIndex, "plant_update")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeSettings;
