// @flow strict

import React from "react";
import "./VariablesWidget.css";
import GaugeChart from "react-gauge-chart";
import lang from "../../../../../services/lang";
import { useState } from "react";

const variables = {
  Utilization: "24hr Utilization",
  oee: "OEE",
  availability: "Availability",
  quality: "Quality",
};

function VariablesWidget(props) {
  const variableKeyList = Object.keys(variables);
  const { machineInfo, hstInfo, langIndex, isMobile } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div
      onClick={() => {
        setCurrentIndex((currentIndex + 1) % variableKeyList.length);
      }}
    >
      <GaugeChart
        id={"gauge_" + machineInfo["id"]}
        nrOfLevels={20}
        colors={[
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#FFFF00",
          "#FFFF00",
          "#00ff00",
          "#00ff00",
        ]}
        arcWidth={0.3}
        percent={
          hstInfo !== undefined
            ? parseFloat(hstInfo[variableKeyList[currentIndex]]) / 100.0
            : 0
        }
      />
      {isMobile == true ? (
        <h5 style={{ color: "white", textAlign: "center" }}>
          {lang(langIndex, "cnc_utilization")}
        </h5>
      ) : (
        <h3 style={{ color: "white", textAlign: "center" }}>
          {/* {lang(langIndex, "cnc_utilization")} */}
          {variables[variableKeyList[currentIndex]]}
        </h3>
      )}
    </div>
  );
}

export default VariablesWidget;
