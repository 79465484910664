import React, { useState, useEffect } from "react";
import { message, Row, Col, Divider, Table } from "antd";
import "./jobEntryPage.css";

import {
  GetJobEntryList,
  ExecuteQuery,
  AddEntry,
  DeleteJobEntry,
  UpdateJobEntry,
} from "../../../services/common/cnc_functions";

import JobEntryTable from "./jobEntryTable/jobEntryTable";
import { useSelector } from "react-redux";
import lang from "../../../services/lang";
import Countdown from "antd/lib/statistic/Countdown";

function JobEntryPage(props) {
  const { langIndex } = useSelector((state) => state.app);
  const { selCustomerId } = props;
  const { security_level } = props;
  const [entryList, setEntryList] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchDate, setSearchDate] = useState("");

  useEffect(() => {
    GetJobEntryList(selCustomerId, (entryList) => {
      if (entryList != null || entryList.length > 0) {
        entryList.forEach(function (entry) {
          entry.key = entry.Id;
        });
        setEntryList(entryList);
      } else {
      }
    });
  }, []);

  const updateEntry = (key, row) => {
    const newData = [...entryList];
    const index = newData.findIndex((item) => key === item.key);
    
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
          const newItem = newData[index];
          var data = newData[index];
          data = { ...data,customer_id: selCustomerId, entry_id: newItem.Id, partNumber: newItem.partNumber, description: newItem.description, dueDate: newItem.dueDate, orderDate: newItem.orderDate, programNumber: newItem.programNumber, qtyCompleted: newItem.qtyCompleted, qtyRequired: newItem.qtyRequired, targetCycleTime: newItem.targetCycleTime, partsPerCycle: newItem.partsPerCycle, aux1data: newItem.aux1data, aux2data: newItem.aux2data, aux3data: newItem.aux3data };


      //     data = { ...data, customer_id: selCustomerId, entry_id: 2, description: newItem.description, dueDate: newItem.dueDate, orderDate: newItem.orderDate, partNumber: newItem.partNumber, programNumber: newItem.programNumber, qtyCompleted: newItem.qtyCompleted, qtyRequired: newItem.qtyRequired, targetCycleTime: newItem.targetCycleTime, partsPerCycle: newItem.partsPerCycle, aux1data: newItem.aux1data, aux2data: newItem.aux2data, aux3data: newItem.aux3data };

          UpdateJobEntry(data, (res) => {
            setIsBusy(false);
            if (res.status == true) {
          
      }
      setEntryList(newData);
    // })
  })
}
}

  const addEntry = (data) => {
    setIsBusy(true);
    data = { ...data, customer_id: selCustomerId };
    AddEntry(data, (res) => {
      setIsBusy(false);
      if (res.status == true) {
        var newItem = { ...res.data[0], key: res.data[0].Id };
        const index = entryList.findIndex((item) => item.Id == newItem.Id);
        //console.log(index);
        if (index > -1) {
          message.error(lang(langIndex, "msg_something_wrong"));
        } else {
          //console.log(newItem);
          setEntryList([...entryList, newItem]);
          message.success(lang(langIndex, "msg_update_success"));
        }
      } else {
        message.error(lang(langIndex, "msg_something_wrong"));
      }
    });
  };


  const deleteEntry = (data) => {
    setIsBusy(true);
    data = { ...data, customer_id: selCustomerId, entry_id: data.Id};
    var removedId = data.Id;

    DeleteJobEntry(data, (res) => {
      setIsBusy(false);
      if (res.status == true)  {
        
        const newData = entryList.filter(element => element.Id != removedId); // makes the shown list include every item except the recently deleted item
   
        
        setEntryList(newData);       
        
          message.success(lang(langIndex, "msg_update_success"));
       
      }
    });
  };


  return (
    <div>
      <JobEntryTable
        entryList={entryList.filter(
          x => x.jobID.includes(searchKey) && x.dueDate.includes(searchDate)
        )}
        updateEntry={updateEntry}
        selCustomerId={selCustomerId}
        addEntry={addEntry}
        setSearchKey={setSearchKey}
        setSearchDueDate={setSearchDate}
        isBusy={isBusy}
        setIsBusy={setIsBusy}
        security_level={security_level}
        deleteEntry={deleteEntry}
      />
    </div>
  );
}

export default JobEntryPage;
