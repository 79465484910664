const axios = require('axios').default;
export function postRequest(url, param, callback) {

    const username = localStorage.getItem('username');
    if (username != null) {
        try {
            param['username'] = username;
        } catch (_) { }
    }

    axios.post(url, param)
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            console.log(error)
        });
}