import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Spin, message } from "antd";
import "./CNCPages.css";
import Content from "./MainPage/Content";

import SettingModal from "./profilePage/SettingModal";
import ReportPage from "./reportPage/ReportPage";
import JobEntryPage from "./jobEntryPage/jobEntryPage";
import PlantSettingsPage from "./plantSettingsPage/plantSettingsPage";
import MaintenancePage from "./maintenancePage/maintenancePage";
import { PAGETYPE } from "../../services/common/constants";
import {
  GetStatus,
  ExecuteQuery,
  updateCustomerInfo,
  updateMachineListInfo,
} from "../../services/common/cnc_functions";
import { UpdateAppConfig } from "../../services/redux/reducers/app";
import lang from "../../services/lang";

function CNCPages(props) {
  const appData = useSelector((state) => state.app);
  const authData = useSelector((x) => x.authService);
  const { username, security_level, fullname } = authData;
  const { langIndex } = appData;
  const { screenSize } = props.app;
  // console.log("screenSize", screenSize);
  const [settingModalVisible, setSettingModalVisible] = useState(false);

  const pageType = props.app.page_type;
  const selCustomerId = props.app.customer_id;

  useEffect(() => {
    GetStatus(selCustomerId, props.dispatch);
  }, [selCustomerId]);

  // change customertimezone
  const onUpdateCustomerInfo = (newItem) => {
    updateCustomerInfo(selCustomerId, { ...newItem }, props.dispatch);
    let query =
      "UPDATE " +
      "`" +
      selCustomerId +
      "_info`" +
      "SET " +
      "`logo`='" +
      newItem.logo +
      "', " +
      "`name`='" +
      newItem.name +
      "', " +
      "`timezone`=" +
      newItem.timezone +
      ", " +
      "`report_days`='" +
      newItem.report_days +
      "', " +
      "`emails`='" +
      newItem.emails +
      "'";
    ExecuteQuery(query, (res) => {
      message.success(lang(langIndex, "msg_update_success"));
    });
  };

  const onUpdateMachineList = (machineInfo) => {
    updateMachineListInfo(selCustomerId, [...machineInfo], props.dispatch);
  };

  const onSetAutoScroll = (val) => {
    UpdateAppConfig({ isAutoScroll: val }, props.dispatch);
  };

  const customerInfo = props.cncService.customerInfoList[selCustomerId];
  const machineList = props.cncService.machineInfoList[selCustomerId];
  const viewMode = props.app.viewMode;
  var isPageLoading = machineList == undefined;

  return (
    <div>
      {isPageLoading && (
        <Spin spinning={isPageLoading} size="large">
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 20,
              color: "#eeeeee",
              height: "100vh",
              marginTop: 200,
              background: "transparent",
            }}
          >
            {lang(langIndex, 'loading_machine_info')}
          </div>
        </Spin>
      )}
      {!isPageLoading && (
        <div>
          {settingModalVisible ? (
            <SettingModal
              settingModalVisible={settingModalVisible}
              setSettingModalVisible={setSettingModalVisible}
              selCustomerId={selCustomerId}
            />
          ) : null}

          {pageType == PAGETYPE.cnc && (
            <Content
              customer_id={selCustomerId}
              customerInfo={customerInfo}
              machineList={machineList}
              security_level={security_level}
              viewMode={viewMode}
              setAutoScroll={onSetAutoScroll}
              screenSize={screenSize}
            />
          )}

          {pageType == PAGETYPE.cnc_job && (
            <JobEntryPage 
              selCustomerId={selCustomerId} 
              security_level={security_level}
            />
          )}

          {pageType == PAGETYPE.cnc_report && (
            <ReportPage
              customer_id={selCustomerId}
              customerInfo={customerInfo}
              machineList={machineList}
              screenSize={screenSize}
            />
          )}

          {pageType == PAGETYPE.cnc_plant_setting && (
            <PlantSettingsPage
              selCustomerId={selCustomerId}
              customerInfo={customerInfo}
              updateCustomerInfo={onUpdateCustomerInfo}
              machineList={machineList}
              setMachineInfoList={onUpdateMachineList}
            />
          )}

          {/* {
                    pageType == PAGETYPE.cnc_maintenance && < MaintenancePage
                        selCustomerId={selCustomerId}
                        machineList={machineList}
                    />
                } */}
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state, props) => ({
  cncService: state.cncService,
  app: state.app,
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CNCPages);
