import React from 'react'
import { Modal, Row, Col, Button, Input, Divider, Switch, Spin } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
import Urls, { postRequest } from '../../../services/common/urls';
import './SettingModal.css';
import { useState } from 'react';
import { useEffect } from 'react';

function SettingModal(props) {
    const { settingModalVisible, setSettingModalVisible, selCustomerId } = props;
    const [currentEmail, setCurrentEmail] = useState('');
    const [emailList, setEmailList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // console.log(selCustomerId);
        const url = Urls.GET_TABLE;
        const param = {
            table: selCustomerId + "_info",
            where: ""
        };
        postRequest(url, param, (res) => {
            // console.log(res);
            var data = res.data[0];
            if (data == undefined) {
                setSettingModalVisible(false);
            }
            var _mailStringList = data['emails'].split(';');
            var _emailList = _mailStringList.filter(v => v != "").map((str) => {
                var tmp = str.split(':');
                var _email = { email: tmp[0] };

                if (tmp.length > 1) {
                    _email['enabled'] = tmp[1] == '1';
                } else {
                    _email['enabled'] = true;
                }
                return _email;
            });
            setEmailList([..._emailList]);
            setIsLoading(false);
        });
    }, []);


    const onSaveSettings = () => {

        var strEmail = "";
        for (var i = 0; i < emailList.length; i++) {
            strEmail += emailList[i]['email'] + ":";
            strEmail += emailList[i]['enabled'] ? "1" : "0";
            strEmail += ";";
        }

        const url = Urls.EXECUTE_QUERY;
        var query = "UPDATE `" + selCustomerId + "_info` SET `emails`='" + strEmail + "'";
        const param = {
            query: query,
        };
        // console.log(param);
        postRequest(url, param, (res) => {

        });

        setSettingModalVisible(false);
    }
    const onAddEmail = () => {
        console.log(currentEmail);

        var _emailList = emailList;
        _emailList.push({
            email: currentEmail,
            enabled: true,
        })

        setCurrentEmail("");
    }

    const onChangeEmailEnable = (email, val) => {
        // console.log(email, val);
        var _emailList = emailList.map((newMail) => {
            if (newMail == email) {
                return { ...newMail, enabled: val };
            } else {
                return newMail;
            }
        });

        setEmailList([..._emailList]);
    }

    const onDeleteEmail = (email) => {
        console.log("delete", email);
        var _emailList = emailList.filter((v) => v !== email);
        setEmailList([..._emailList]);
    }

    const emailListUI = emailList.map((email, index) => {
        return (<Row className='setting-modal-email-list-style' key={index}>
            <Col span={12}>
                {email.email}
            </Col>
            <Col span={6}>
                <Switch size='default' checked={email.enabled} onChange={(v) => onChangeEmailEnable(email, v)}></Switch>
            </Col>
            <Col span={6}>
                <Button type={'danger'} icon={<DeleteOutlined />} onClick={() => onDeleteEmail(email)}></Button>
            </Col>
        </Row>);
    });

    return (
        <Modal className="setting-modal-style"
            title={null}
            visible={settingModalVisible}
            onOk={onSaveSettings}
            onCancel={() => setSettingModalVisible(false)}
        >
            {isLoading ?
                <Spin spinning={isLoading} size="large">
                    <div style={{ width: "100%", textAlign: "center", fontSize: 20, height: 100 }}>
                        Loading...
                    </div>
                </Spin>
                :
                <div>
                    <div className="setting-modal-title-style">
                        Settings
                </div>
                    <div>
                        <Row className='setting-modal-add-email-style'>
                            <Col span={8}>
                                Email:
                        </Col>
                            <Col span={8}>
                                <Input value={currentEmail} onChange={(e) => setCurrentEmail(e.target.value)}></Input>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                <Button type={"ghost"} style={{ color: 'white' }} onClick={onAddEmail}>ADD</Button>
                            </Col>
                        </Row>
                        <Divider />
                        {emailListUI}
                    </div>
                </div>
            }
        </Modal>
    )
}

export default SettingModal
