import React from "react";
import { Input } from "antd";
import "./CustomerName.css";
import { useSelector } from "react-redux";
import lang from "../../../../../services/lang";

function CustomerName(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { customerName, setCustomerName } = props;
  return (
    <div>
      <h1 className="customername-title-style">
        {lang(langIndex, "plant_customername")}:
      </h1>
      <div className="customername-value-container-style">
        <Input
          className="customername-value-style"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
      </div>
    </div>
  );
}

export default CustomerName;
