export const PAGETYPE = {
    submenu: "submenu",

    cnc: "CNC",
    tms: "TMS",
    iiot: "IIot",

    cnc_job: "CNC_JOB",
    cnc_report: "CNC_REPORT",
    cnc_plant_setting: "CNC_PLANT_SETTING",
    cnc_maintenance: "CNC_MAINTENANCE"
}

export const VIEWMODE = {
    listView: 0,
    tileView: 1
}

export const MENU_DIVIDER = "___";


export const menuReport = "menu_report";
export const sizeMobile = 500
export const sizePad = 1100

export const SENSOR_TYPE = {
    other: 0,
    temperature: 1,
    current: 2,
    vibration: 3,
    moisture: 4,
};
export const SENSOR_TITLE_BY_ID = {
    other: "Other",
    temperature: "Temperature Sensor",
    current: "Current Sensor",
    vibration: "Vibration Sensor",
    moisture: "Moisture Sensor",
}

export const SENSOR_UNIT_BY_ID = {
    other: [""],
    temperature: ["°C", "°F", ""],
    current: ["mA", "A", ""],
    vibration: ["Hz", ""],
    moisture: ["%", ""],
}
// 5min, 10min, 20min, 1hr
export const SENSOR_ALERT_FREQUENCY = {
    noAlert: 0,
    min5: 5,
    min10: 10,
    min20: 20,
    hr1: 60,
}
export const SENSOR_ALERT_FREQUENCY_TITLE = {
    noAlert: "no alert",
    min5: "5 min",
    min10: "10 min",
    min20: "20 min",
    hr1: "1 hour"
}