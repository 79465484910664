import * as actionType from "../actionType";
import Urls, { postRequest } from "../../common/urls";
import { VIEWMODE } from "../../common/constants";

const initialState = {
  isDetailPage: false,
  customerInfoList: {},
  machineInfoList: {},
  machineDetailList: {},
};

const reducer = (state = initialState, action) => {
  let _customerInfoList = { ...state.customerInfoList };
  switch (action.type) {
    case actionType.cncChangePage:
      return {
        ...state,
        isDetailPage: action.data,
      };
      break;
    case actionType.cncSetAllCustomerInfo:
      return {
        ...state,
        customerInfoList: action.data,
      };
    case actionType.cncUpdateCustomerInfo:
      _customerInfoList[action.data.customer_id] = {
        ...action.data.customer_info,
      };

      return {
        ...state,
        customerInfoList: { ..._customerInfoList },
      };
      break;
    case actionType.cncUpdateGroupMachineInfo:
      _customerInfoList[action.data.customer_id] = {
        ..._customerInfoList[action.data.customer_id],
        groupInfo: [...action.data.groupInfo],
      };

      return {
        ...state,
        customerInfoList: { ..._customerInfoList },
      };
      break;
    case actionType.cncUpdateMachineListInfo:
      let _machineInfoList = { ...state.machineInfoList };
      _machineInfoList[action.data.customer_id] = [...action.data.machine_info];
      return {
        ...state,
        machineInfoList: { ..._machineInfoList },
      };
    case actionType.cncUpdateMachineDetailInfo:
      let _machineDetailList = { ...state.machineDetailList };
      _machineDetailList[action.data.customer_id] = {
        gantt: [...action.data.gantt_list],
        hst: [...action.data.hst_list],
        status: [...action.data.status_list],
      };
      return {
        ...state,
        machineDetailList: { ..._machineDetailList },
      };

      break;
    default:
      return state;
  }
};
export default reducer;

export function UpdateCustomerGroupInfo(customer_id, groupInfo, dispatch) {
  dispatch({
    type: actionType.cncUpdateGroupMachineInfo,
    data: {
      customer_id: customer_id,
      groupInfo: groupInfo,
    },
  });
}
