// @flow strict

import { message, Spin } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ManageMachineGroup,
  GetMachineGroup,
} from "../../../../services/common/cnc_functions";
import lang from "../../../../services/lang";
import { UpdateCustomerGroupInfo } from "../../../../services/redux/reducers/cnc_service";
import GroupManageDialog from "./GroupManageDialog/GroupManageDialog";
import "./GroupSettings.css";
import GroupTableWidget from "./GroupTableWidget/GroupTableWidget";

function GroupSettings(props) {
  const dispatch = useDispatch();
  const { langIndex } = useSelector((x) => x.app);
  const [isShowModal, setIsShowModal] = useState(false);
  const [initGroupInfo, setInitGroupInfo] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const cncService = useSelector((x) => x.cncService);
  const {
    selCustomerId,
    customerInfo,
    updateCustomerInfo,
    machineList,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  // sync with redux
  const customerInfoList = cncService.customerInfoList;
  const customerIdList = Object.keys(customerInfoList);
  //   console.log("customerInfoList", customerInfoList);

  const onClickAddGroup = () => {
    setInitGroupInfo(null);
    setIsShowModal(true);
  };

  const addGroup = (groupInfo) => {
    setIsBusy(true);
    // console.log("ADD GROUP INFO", groupInfo);
    ManageMachineGroup(selCustomerId, groupInfo, (res) => {
      //   console.log("ADD GROUP", res);
      setIsBusy(false);
      if (res == null) {
        message.error(lang(langIndex, "msg_something_wrong"));
        return;
      }
      UpdateCustomerGroupInfo(selCustomerId, res, dispatch);
    });

    // UpdateCustomerGroupInfo
  };
  const editGroup = (groupInfo) => {
    setIsBusy(true);
    // console.log("EDIT GROUP INFO", groupInfo);
    ManageMachineGroup(selCustomerId, groupInfo, (res) => {
      //   console.log("ADD GROUP", res);
      setIsBusy(false);
      if (res == null) {
        message.error(lang(langIndex, "msg_something_wrong"));
        console.log("this runs here");
        return;
      }
      UpdateCustomerGroupInfo(selCustomerId, res, dispatch);
    });
  };
  const onClickEditGroup = (groupInfo) => {
    setInitGroupInfo(groupInfo);
    setIsShowModal(true);
  };
  const onClickDeleteGroup = (groupInfo) => {
    // console.log(groupInfo);
    ManageMachineGroup(
      selCustomerId,
      { id: groupInfo.id, is_delete: true },
      (res) => {}
    );
    const machineGroupList =
      cncService.customerInfoList[selCustomerId]["groupInfo"];
    UpdateCustomerGroupInfo(
      selCustomerId,
      machineGroupList.filter((x) => x.id != groupInfo.id),
      dispatch
    );
  };

  //   console.log("machineList", machineList);
  return (
    <div>
      {isShowModal === true && (
        <GroupManageDialog
          machineList={machineList}
          initGroupInfo={initGroupInfo}
          addGroup={addGroup}
          editGroup={editGroup}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
        />
      )}
      <div>
        <h1
          className="group-setting-page-title"
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ display: "inline-block", marginRight: 50 }}
        >
          {lang(langIndex, "plant_groupsetting")}
        </h1>
        <a
          style={{
            display: isCollapsed ? "none" : "inline-block",
          }}
          onClick={onClickAddGroup}
        >
          {" "}
          + {lang(langIndex, "plant_addgroup")}
        </a>
      </div>
      <Spin spinning={isBusy}>
        {!isCollapsed && (
          <div>
            {machineList.length == 0 ? (
              <div>{lang(langIndex, "cnc_nodata")}</div>
            ) : (
              <div>
                <GroupTableWidget
                  langIndex={langIndex}
                  machineList={machineList}
                  machineGroupList={
                    cncService.customerInfoList[selCustomerId]["groupInfo"]
                  }
                  customerInfoList={customerInfoList}
                  onClickEditGroup={onClickEditGroup}
                  onClickDeleteGroup={onClickDeleteGroup}
                />
              </div>
            )}
          </div>
        )}
      </Spin>
    </div>
  );
}

export default GroupSettings;
