import { Button, Col, Row, Space } from "antd";
import React, { useState } from "react";
import "./alertSettingContainer.css";
import SensorUpdateModal from "../../sensorUpdateModal/sensorUpdateModal";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
function AlertSettingContainer(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { sensorInfo } = props;
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  var unit =
    sensorInfo == undefined || sensorInfo.unit == undefined
      ? ""
      : sensorInfo.unit;
  return (
    <div className="sensor-alert-setting-container">
      {isShowUpdateModal && (
        <SensorUpdateModal
          sensorInfo={sensorInfo}
          setIsShowUpdateModal={setIsShowUpdateModal}
          isShowUpdateModal={isShowUpdateModal}
        />
      )}
      <Row align={"middle"}>
        <Col className="sensor-alert-setting-title">
          {lang(langIndex, "iiot_alertsetting")}
        </Col>
        <Col className="sensor-alert-setting-button-container">
          <Button
            className="sensor-alert-setting-update-button"
            onClick={() => setIsShowUpdateModal(true)}
            ghost
          >
            {lang(langIndex, "iiot_updatesetting")}
          </Button>
        </Col>
      </Row>
      <div className="sensor-alert-setting-limit">
        <span className="sensor-alert-setting-limit-title">
          {lang(langIndex, "iiot_minlimit")}:{" "}
        </span>
        {sensorInfo.min_limit}
      </div>
      <div className="sensor-alert-setting-limit">
        <span className="sensor-alert-setting-limit-title">
          {lang(langIndex, "iiot_maxlimit")}:{" "}
        </span>
        {sensorInfo.max_limit}
      </div>
      <div className="sensor-alert-setting-limit">
        <span className="sensor-alert-setting-limit-title">
          {lang(langIndex, "iiot_alertto")}:{" "}
        </span>
        {sensorInfo.alert_emails}
      </div>
    </div>
  );
}

export default AlertSettingContainer;
