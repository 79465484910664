import React, { useState, useEffect } from "react";
import "./ReportDays.css";
import DayCheck from "./DayCheck/DayCheck";
import { useSelector } from "react-redux";
import lang from "../../../../../services/lang";
function ReportDays(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { customerInfo, updateCustomerInfo } = props;
  const [reportDays, setReportDays] = useState("");
  const days = ["M", "T", "W", "T", "F", "S", "S"];
  useEffect(() => {
    setReportDays(customerInfo.report_days);
  }, []);

  const onUpdateReportDays = () => {
    if (customerInfo.report_days == reportDays) return;
    let newInfo = customerInfo;
    newInfo.report_days = reportDays;
    updateCustomerInfo(newInfo);
  };
  const setChecked = (index, checked) => {
    var tmpDays = reportDays;
    if (checked) {
      tmpDays += index + "";
    } else {
      tmpDays = tmpDays.replace(index + "", "");
    }
    setReportDays(tmpDays);
    onUpdateReportDays();
  };
  // console.log(reportDays);
  const daysUI = days.map((day, index) => {
    const checked = reportDays.includes(index + "");
    const title = day;
    return (
      <DayCheck
        key={index}
        checked={checked}
        title={title}
        index={index}
        setChecked={setChecked}
      />
    );
  });

  return (
    <div className="reportdays-setting-content-style">
      <h1 className="reportdays-title-style">
        {lang(langIndex, "plant_reportday")}:
      </h1>
      <div className="reportdays-value-container-style">
        {/* <Input value={customerTimezone} onChange={(e) => setCustomerTimezone(e.target.value)} className="reportdays-value-style" type={"number"} /> */}
      </div>
      <div className="reportdays-value-change-button-container-style">
        {daysUI}
      </div>
    </div>
  );
}

export default ReportDays;
