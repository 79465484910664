import * as actionType from "../actionType";
import Urls, { postRequest } from "../../common/urls";
import { VIEWMODE } from "../../common/constants";

const initialState = {
  isAuth: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.actSetAuthInfo:
      return {
        ...action.data,
        isAuth: true,
      };
      break;
    case actionType.actSignOut:
      return {
        isAuth: false,
      };
      break;
    default:
      return state;
  }
};
export default reducer;
export function SignOut(dispatch) {
  dispatch({
    type: actionType.actSignOut,
    data: "",
  });
}

export function Login(username, password, dispatch, callback) {
  const url = Urls.LOGIN;
  const param = {
    username: username,
    password: password,
  };
  console.log(url,param)
  postRequest(url, param, (res) => {
    if (res != null && res.data.length > 0) {
      try {
        const info = res.data[0];
        const data = {
          username: username,
          customer_id: info["customer_id"],
          security_level: info["security_level"],
          fullname: info["username_full"],
        };
        localStorage.setItem("username", username);
        dispatch({
          type: actionType.actSetAuthInfo,
          data: data,
        });
        callback(true);
      } catch (e) {
        callback(false);
      }
    } else {
      callback(false);
    }
  });
}
