import React, { useEffect, useState } from "react";
import "./Content.css";
import { Spin } from "antd";
import { connect, useSelector } from "react-redux";

import {
  GetMachineListData,
  GetCustomerCurrentTime,
  pad,
  readOperatorList,
} from "../../../services/common/cnc_functions";

import { CSSTransition } from "react-transition-group";
import MachineDetailPage from "../machineDetailPage/MachineDetailPage";
import MachineItemContainer from "./MachineItemContainer";
import SensorPage from "../../sensorPage/sensor_page";
import { PAGETYPE } from "../../../services/common/constants";
import lang from "../../../services/lang";

function Content(props) {
  const { langIndex } = useSelector((state) => state.app);
  const { customer_id } = props;
  const { customerInfo } = props;
  const { machineList } = props;
  const { security_level } = props;
  const { viewMode, screenSize } = props;
  const { setAutoScroll } = props;

  // const [isContentLoading, setIsContentLoading] = useState(true);

  const [tick, setTick] = useState(true);
  const [intervalId, setIntervalID] = useState(null);
  const [selectedMachine, setSelectedmachine] = useState("");
  const [operatorList, setOperatorList] = useState([]);

  const timezone = customerInfo["timezone"];
  const customerTime = GetCustomerCurrentTime(timezone);
  // console.log('customer time', customerTime);
  const startDate =
    pad(customerTime.getMonth() + 1) +
    "/" +
    pad(customerTime.getDate()) +
    "/" +
    pad(customerTime.getFullYear());
  let endDate = GetCustomerCurrentTime(timezone);
  endDate.setDate(endDate.getDate() + 1);
  endDate =
    pad(endDate.getMonth() + 1) +
    "/" +
    pad(endDate.getDate()) +
    "/" +
    pad(endDate.getFullYear());

  const timer = () => {
    setTick((t) => !t);
  };

  const onClickMachine = (machine) => {
    setAutoScroll(false);
    setSelectedmachine(machine);
  };

  useEffect(() => {
    // console.log(machineList);
    setSelectedmachine("");
    try {
      clearInterval(intervalId);
    } catch (_) {}

    readOperatorList((res) => {
      setOperatorList(res);
      GetMachineListData(
        customer_id,
        timezone,
        startDate,
        endDate,
        "",
        props.dispatch
      );
      setIntervalID(setInterval(timer, 10000));
    });
    return () => {
      clearInterval(intervalId);
    };
  }, [customer_id]);

  useEffect(() => {
    GetMachineListData(
      customer_id,
      timezone,
      startDate,
      endDate,
      "",
      props.dispatch
    );
  }, [tick]);

  const contentData = props.cncService.machineDetailList[customer_id];
  const isContentLoading = contentData == undefined;
  const hstList = contentData == undefined ? [] : contentData.hst;
  const ganttList = contentData == undefined ? [] : contentData.gantt;
  const statusList = contentData == undefined ? [] : contentData.status;

  let groupInfo = props.cncService.customerInfoList[customer_id];
  let validMachineList = machineList;

  if (
    props.app.group_id != undefined &&
    props.app.group_id != "" &&
    props.app.group_id != "all"
  ) {
    if (groupInfo != undefined) {
      groupInfo = groupInfo.groupInfo;
      const group = groupInfo.find((x) => x.id == props.app.group_id);
      if (group != undefined) {
        const avail_machine_ids = group.machine_list.split(",");
        validMachineList = validMachineList.filter((x) =>
          avail_machine_ids.includes(x.machine_id)
        );
      }
    }
  }

  return (
    <div>
      {isContentLoading && (
        <Spin spinning={isContentLoading} size="large">
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 20,
              color: "#eeeeee",
              height: "100vh",
              marginTop: 200,
              background: "transparent",
            }}
          >
            {lang(langIndex, "loading_gantt_data")}
          </div>
        </Spin>
      )}
      {!isContentLoading && (
        <div>
          {selectedMachine == "" && (
            <MachineItemContainer
              machineList={validMachineList}
              customer_id={customer_id}
              customerInfo={customerInfo}
              hstList={hstList}
              ganttList={ganttList}
              onClickMachine={onClickMachine}
              viewMode={viewMode}
              operatorList={operatorList}
              statusList={statusList}
              screenSize={screenSize}
            />
          )}

          <CSSTransition
            in={selectedMachine !== ""}
            timeout={1000}
            classNames="pageSliderLeft"
            unmountOnExit={true}
          >
            <MachineDetailPage
              customer_id={customer_id}
              customerInfo={customerInfo}
              machineInfo={selectedMachine}
              hstList={hstList}
              security_level={security_level}
              ganttList={ganttList}
              onCloseMachine={setSelectedmachine}
              operatorList={operatorList}
              statusList={statusList}
              screenSize={screenSize}
              dismissible
            />
          </CSSTransition>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  cncService: state.cncService,
  app: state.app,
});
const mapDispatchToProps = (dispatch, props) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(Content);
