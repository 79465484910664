import React, { useState, useEffect } from "react";
import "./CustomerSettings.css";
import CustomerName from "./CustomerName/CustomerName";
import CustomerLogo from "./CustomerLogo/CustomerLogo";
import { Button } from "antd";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
function CustomerSettings(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { customerInfo, updateCustomerInfo } = props;
  const [customerLogo, setCustomerLogo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);
  useEffect(() => {
    setCustomerLogo(customerInfo.logo);
    setCustomerName(customerInfo.name);
  }, []);
  const onUpdateCustomerInfo = () => {
    if (customerInfo.logo == customerLogo && customerName == customerInfo.name)
      return;
    let newInfo = customerInfo;
    newInfo.logo = customerLogo;
    newInfo.name = customerName;
    updateCustomerInfo(newInfo);
  };
  return (
    <div>
      <h1
        className="customer-setting-page-title"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {lang(langIndex, "plant_customersetting")}
      </h1>
      <div style={{ display: isCollapsed ? "none" : "block" }}>
        <div className="customer-setting-container-style">
          <CustomerName
            customerName={customerName}
            setCustomerName={setCustomerName}
          />
          <CustomerLogo
            customerLogo={customerLogo}
            setCustomerLogo={setCustomerLogo}
          />
        </div>
        <div className="customer-setting-container-style">
          <a
            className="customer-setting-update-button"
            onClick={onUpdateCustomerInfo}
          >
            {lang(langIndex, "plant_update")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default CustomerSettings;
