import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getSensorList } from "../../services/redux/reducers/sensor_service";
import "./sensor_page.css";
import AddSensorModal from "./AddSensorModal/AddSensorModal";
import SensorDashboardPage from "./SensorDashboardPage/SensorDashboardPage";
import DetailPage from "./detail_page/detail_page";
import { CSSTransition } from "react-transition-group";
import lang from "../../services/lang";

function SensorPage(props) {
  const { langIndex } = useSelector((state) => state.app);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [selectedSensorId, setSelectedSensorId] = useState("");
  const [customerInfo, setCustomerInfo] = useState({});
  const customerId = props.app.customer_id;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSelectedSensorId("");
    setIsLoading(true);
    getSensorList(customerId, props.dispatch, (res) => {
      setIsLoading(false);
    });
    if (customerId != undefined || customerId != "") {
      var customer = props.cncService.customerInfoList[customerId];
      if (customer != undefined) {
        setCustomerInfo(customer);
      }
    }
  }, [customerId]);

  const sensorList = props.sensorService.sensorList[customerId];
  const selectedSensorInfo =
    selectedSensorId == "" || sensorList == undefined
      ? ""
      : sensorList.filter((x) => x.sensor_id == selectedSensorId)[0];

  useEffect(() => {
    if (selectedSensorInfo == undefined) {
      setSelectedSensorId("");
    }
  }, [selectedSensorInfo]);
  return (
    <div className="sensor-page-background">
      {isShowAddModal && (
        <AddSensorModal
          isShowAddModal={isShowAddModal}
          setIsShowAddModal={setIsShowAddModal}
        />
      )}

      {isLoading && (
        <Spin spinning={isLoading} size="large">
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 20,
              color: "#eeeeee",
              height: "100vh",
              marginTop: 200,
              background: "transparent",
            }}
          >
            {lang(langIndex, 'loading_sensor_list')}
          </div>
        </Spin>
      )}

      {!isLoading && (
        <div>
          {(selectedSensorId == "" || selectedSensorInfo == undefined) && (
            <SensorDashboardPage
              customerId={customerId}
              setIsShowAddModal={setIsShowAddModal}
              sensorList={sensorList}
              selectedSensorId={selectedSensorId}
              setSelectedSensorId={setSelectedSensorId}
              timezone={customerInfo.timezone}
            />
          )}

          <CSSTransition
            in={selectedSensorId !== "" && selectedSensorInfo != undefined}
            timeout={1000}
            classNames="pageSliderLeft"
            unmountOnExit={true}
          >
            <div>
              {selectedSensorId !== "" && selectedSensorInfo != undefined && (
                <DetailPage
                  setSelectedSensorId={setSelectedSensorId}
                  sensorInfo={selectedSensorInfo}
                  timezone={customerInfo.timezone}
                />
              )}
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  app: state.app,
  sensorService: state.sensorService,
  cncService: state.cncService,
});
const mapDispatchToProps = (dispatch, props) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(SensorPage);
