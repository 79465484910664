import { Row } from "antd";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import DetailPage from "../detail_page/detail_page";
import OneItem from "../one_item/one_item";
import "./SensorDashboardPage.css";
import {
  deleteSensor,
  getSensorInfo,
} from "../../../services/redux/reducers/sensor_service";
import { Col } from "antd";
function SensorDashboardPage(props) {
  const { customerId, setIsShowAddModal, sensorList } = props;
  const { setSelectedSensorId, timezone } = props;

  const [tick, setTick] = useState(true);
  const [intervalId, setIntervalID] = useState(null);
  const intervalTime = 5000;

  useEffect(() => {
    try {
      clearInterval(intervalId);
    } catch (e) {}
    setIntervalID(setInterval(timer, intervalTime));
    return () => {
      clearInterval(intervalId);
    };
  }, [customerId]);

  const timer = () => {
    setTick((t) => !t);
  };

  useEffect(() => {
    if (sensorList != undefined && sensorList.length > 0) {
      var sensorIdList = sensorList.map((info) => info.sensor_id);
      getSensorInfo(customerId, sensorIdList, props.dispatch);
    }
  }, [tick]);

  const onSelectSensor = (sensorName) => {
    // SetSelectedSensor(true, sensorName, props.dispatch);
  };

  const addTileWidget = (
    <Col xs={24} sm={12} md={8} lg={6} key={`sensor_list_key_add-button`}>
      <div
        className="add-sensor-button-container"
        onClick={() => setIsShowAddModal(true)}
      >
        <div className="add-sensor-button-title">+</div>
      </div>
    </Col>
  );

  const onDeleteSensor = (sensorId) => {
    deleteSensor(props.app.customer_id, sensorId, props.dispatch);
  };

  const sensorInfoList = props.sensorService.sensorInfo[customerId];
  const sensorUIList =
    sensorList == undefined || sensorList.length == 0
      ? null
      : sensorList.map((sensor) => {
          const sensorInfo =
            sensorInfoList == undefined
              ? undefined
              : sensorInfoList[sensor.sensor_id];

          return (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              key={`sensor_list_key_${sensor.sensor_id}`}
            >
              <OneItem
                sensor={sensor}
                sensorInfo={sensorInfo}
                onSelectSensor={onSelectSensor}
                timezone={timezone}
                onDeleteSensor={onDeleteSensor}
                setSelectedSensorId={setSelectedSensorId}
              />
            </Col>
          );
        });

  return (
    <div>
      <Row>
        {addTileWidget}
        {sensorUIList}
      </Row>
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  app: state.app,
  sensorService: state.sensorService,
});
const mapDispatchToProps = (dispatch, props) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SensorDashboardPage);
