import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Col, Row } from "antd";
import "./MyLayout.css";
import MyHeader from "./MyHeader";
import MyBreadcrumb from "./MyBreadcrumb";
import MySidebar from "./MySidebar";
import { Fab, Action } from "react-tiny-fab";
import { FullscreenExitOutlined } from "@ant-design/icons";
import "react-tiny-fab/dist/styles.css";
import logo from "../../assets/images/logo.png";
import { UpdateAppConfig } from "../../services/redux/reducers/app";
import { withRouter } from "react-router";

function MyLayout(props) {
  const dispatch = useDispatch();
  const authData = useSelector((x) => x.authService);
  const appData = useSelector((x) => x.app);
  const cncService = useSelector((x) => x.cncService);
  const { screenSize } = appData;

  const { username, security_level, fullname } = authData;
  const [autoScrollSpeed, setAutoScrollSpeed] = useState(50);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [tick, setTick] = useState(true);
  const [intervalId, setIntervalID] = useState(null);
  const [dy, setDy] = useState(10);
  const [isWaitingToTop, setIsWaitingToTop] = useState(false);
  const fixedInterval = 50;

  // sync with redux
  const customerInfoList = cncService.customerInfoList;
  const customerIdList = Object.keys(customerInfoList);

  const isMenuCollapsed = appData.is_menu_collapsed;
  const selCustomerId = appData.customer_id;
  const selMenuType = appData.page_type;
  const customerInfo = customerInfoList[selCustomerId];
  const viewMode = appData.viewMode;
  const autoScroll = appData.isAutoScroll;

  const setIsMenuCollapsed = (val) => {
    UpdateAppConfig({ is_menu_collapsed: val }, dispatch);
  };

  const setSelMenuType = (val) => {
    UpdateAppConfig({ page_type: val }, dispatch);
  };

  const setViewMode = (val) => {
    UpdateAppConfig({ viewMode: val }, dispatch);
  };
  
  const setAutoScroll = (val) => {
    UpdateAppConfig({ isAutoScroll: val }, dispatch);
  };

  // section for scrolling
  useEffect(() => {
    return () => {
      if (intervalId != null) clearInterval(intervalId);
    };
  }, []);

  // totally 30 seconds
  const myRef = useRef(null);
  useEffect(() => {
    myRef.current.scrollTop = 0;

    if (intervalId != null) clearInterval(intervalId);
    if (autoScroll == true) {
      var height =
        myRef.current.scrollHeight - myRef.current.offsetHeight + 0.0;
      var duration = 31 - autoScrollSpeed * 0.3 + 0.0; // seconds
      let _dy = height / (20 * duration);
      setDy(_dy);
      // console.log("interval", (duration / step) * 1000, "intervalId", intervalId);
      setIntervalID(setInterval(timer, fixedInterval));
    }
  }, [autoScroll, autoScrollSpeed]);
  const timer = () => {
    setTick((t) => !t);
  };

  useEffect(() => {
    // console.log("Scroll Top", myRef.current.scrollTop, "Height", myRef.current.scrollHeight, "OffsetHeight", myRef.current.offsetHeight);
    if (
      myRef.current.scrollTop >
        myRef.current.scrollHeight - myRef.current.offsetHeight - dy &&
      autoScroll == true
    ) {
      myRef.current.scrollTop = myRef.current.scrollHeight;
      setIsWaitingToTop(true);
      clearInterval(intervalId);
      setTimeout(function () {
        // myRef.current.scrollTop = 0;
        myRef.current.scrollTo({ behavior: "smooth", top: 0 });
        setTimeout(function () {
          setIntervalID(setInterval(timer, fixedInterval));
          setIsWaitingToTop(false);
        }, 1000);
      }, 500);
    } else {
      // myRef.current.scrollTo({ behavior: 'smooth', top: myRef.current.scrollTop + dy });
      myRef.current.scrollTop = myRef.current.scrollTop + dy;
    }
  }, [tick]);

  return (
    <Layout>
      {!isFullScreen && (
        <MySidebar
          isMenuCollapsed={isMenuCollapsed}
          setIsMenuCollapsed={setIsMenuCollapsed}
          customerIdList={customerIdList}
          selCustomerId={selCustomerId}
          customerInfo={customerInfo}
          customerInfoList={customerInfoList}
          autoScroll={autoScroll}
          setAutoScroll={setAutoScroll}
          autoScrollSpeed={autoScrollSpeed}
          setAutoScrollSpeed={setAutoScrollSpeed}
          isWaitingToTop={isWaitingToTop}
          screenSize={screenSize}
          setSelMenuType={setSelMenuType}
          selMenuType={selMenuType}
          security_level={security_level}
        />
      )}

      <Layout style={{ marginLeft: 10 }}>
        {!isFullScreen && (
          <MyHeader
            selCustomerId={selCustomerId}
            username={username}
            isMenuCollapsed={isMenuCollapsed}
            setIsMenuCollapsed={setIsMenuCollapsed}
            customerInfo={customerInfo}
            screenSize={screenSize}
            fullname={fullname}
            security_level={security_level}
            setSelMenuType={setSelMenuType}
            selMenuType={selMenuType}
          />
        )}
        {!isFullScreen && (
          <MyBreadcrumb
            customerInfo={customerInfo}
            viewMode={viewMode}
            setViewMode={setViewMode}
            setIsFullScreen={setIsFullScreen}
            selCustomerId={selCustomerId}
            setAutoScroll={setAutoScroll}
            screenSize={screenSize}
            security_level={security_level}
            setSelMenuType={setSelMenuType}
            selMenuType={selMenuType}
          />
        )}
        <div
          className="content-scrollbar-style"
          ref={myRef}
          style={{
            height: !isFullScreen
              ? window.innerHeight - 120
              : window.innerHeight - 20,
            overflow: "auto",
            overflowX: "hidden",
            margin: "10px 16px",
          }}
        >
          {props.children}
        </div>
      </Layout>

      {isFullScreen && (
        <Fab
          // mainButtonStyles={mainButtonStyles}
          // actionButtonStyles={actionButtonStyles}
          position={{ bottom: 24, right: 24 }}
          icon={<FullscreenExitOutlined />}
          onClick={() => setIsFullScreen(false)}
        ></Fab>
      )}
    </Layout>
  );
}

export default withRouter(MyLayout);
