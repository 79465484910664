import React, { useState } from "react";
import "./ReportSettings.css";
import ReportDays from "./ReportDays/ReportDays";
import ReportEmails from "./ReportEmails/ReportEmails";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
function ReportSettings(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { customerInfo, updateCustomerInfo } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <div>
      <h1
        className="report-setting-page-title"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {lang(langIndex, "plant_reportsetting")}
      </h1>
      <div
        style={{ display: isCollapsed ? "none" : "block" }}
        className="report-setting-content-container-style"
      >
        <ReportDays
          customerInfo={customerInfo}
          updateCustomerInfo={updateCustomerInfo}
        />
        <ReportEmails
          customerInfo={customerInfo}
          updateCustomerInfo={updateCustomerInfo}
        />
      </div>
    </div>
  );
}

export default ReportSettings;
