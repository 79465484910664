import React, { useState, useEffect } from "react";

import "./plantSettingsPage.css";
import UserSettings from "./UserSettings/UserSettings";
import TimeSettings from "./TimeSettings/TimeSettings";
import ReportSettings from "./ReportSettings/ReportSettings";
import CustomerSettings from "./CustomerSettings/CustomerSettings";
import MachineSettings from "./MachineSettings/MachineSettings";
import { useSelector } from "react-redux";
import lang from "../../../services/lang";
import GroupSettings from "./GroupSettings/GroupSettings";

function PlantSettingsPage(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { selCustomerId, customerInfo, updateCustomerInfo } = props;
  const { machineInfoList, setMachineInfoList } = props;
  const [machineList, setMachineList] = useState([]);
  const [entryList, setEntryList] = useState([]);

  useEffect(() => {
    // console.log(customerInfo);
    // console.log(selCustomerId);
  }, []);
  return (
    <div>
      <h2 className="plant-page-title">
        {lang(langIndex, "plant_plantsettings")}
      </h2>
      <div className="settings-part-container">
        <br></br>
        <CustomerSettings
          selCustomerId={selCustomerId}
          customerInfo={customerInfo}
          updateCustomerInfo={updateCustomerInfo}
        />
        <br></br>
        <UserSettings
          selCustomerId={selCustomerId}
          customerInfo={customerInfo}
        />
        <br></br>
        <MachineSettings
          selCustomerId={selCustomerId}
          customerInfo={customerInfo}
          machineInfoList={machineInfoList}
          setMachineInfoList={setMachineInfoList}
          machineList={machineList}
          setMachineList={setMachineList}
        />
        <br></br>
        <TimeSettings
          selCustomerId={selCustomerId}
          customerInfo={customerInfo}
          updateCustomerInfo={updateCustomerInfo}
        />
        <br></br>
        <ReportSettings
          selCustomerId={selCustomerId}
          customerInfo={customerInfo}
          updateCustomerInfo={updateCustomerInfo}
        />
        <br></br>
        <GroupSettings
          selCustomerId={selCustomerId}
          customerInfo={customerInfo}
          updateCustomerInfo={updateCustomerInfo}
          machineInfoList={machineInfoList}
          machineList={machineList}
          setMachineList={setMachineList}
        />
      </div>
    </div>
  );
}

export default PlantSettingsPage;
