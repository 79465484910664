import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Row, Col, Divider } from "antd";
import "./ReportPage.css";
import MachineContainer from "./MachineContainer/MachineContainer";
import FactoryContainer from "./FactoryContainer/FactoryContainer";
import ReportConfiguration from "./ReportConfiguration/ReportConfiguration";

import GeneratePDF from "./ReportConfiguration/GeneratePDF/GeneratePDF";
import ReportPeriod from "./ReportConfiguration/ReportPeriod/ReportPeriod";
import ExportCSVConfiguration from "./ExportCSVConfiguration/ExportCSVConfiguration";
import ExportCSVButton from "./ExportCSVConfiguration/ExportCSVButton/ExportCSVButton";
import { GetCustomerCurrentTime, GetOperatorList, GetJobList } from "../../../services/common/cnc_functions";
import { useSelector } from "react-redux";
import lang from "../../../services/lang";

function ReportPage(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { customer_id, customerInfo, machineList, screenSize } = props;
  const [selectedMachine, setSelectedMachine] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [fromReportDate, setFromReportDate] = useState("");
  const [toReportDate, setToReportDate] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  useEffect(() => {
    GetOperatorList(customer_id, (operatorList) => {
      if (operatorList != null && operatorList.length > 0) {
        operatorList.forEach(function (operator) {
          operator.key = operator.Id;
        });
        setOperatorList(operatorList);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    GetJobList(customer_id, (jobList) => {
      if (jobList != null && jobList.length > 0) {
        jobList.forEach(function (job) {
          job.key = job.Id;
          
        });
        setJobList(jobList);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    var tmpEmails = customerInfo.emails.split(";");
    var emailList = [];
    for (var i = 0; i < tmpEmails.length; i++) {
      var email = tmpEmails[i].split(":")[0];
      if (email != "") {
        emailList.push(email);
      }
    }
    setSelectedEmails(emailList);
    if (customerInfo["timezone"] != undefined) {
      const timezone = customerInfo["timezone"];
      let _cusTime = GetCustomerCurrentTime(timezone);
      const dateFormat = "MM/DD/YYYY";
      let _cusTimeString = moment(_cusTime).format(dateFormat);
      setToReportDate(_cusTimeString);
      setFromReportDate(_cusTimeString);
    }
  }, [customerInfo]);

  return (
    <div>
      <Row>
        <Col span={9}>
          <MachineContainer
            customer_id={customer_id}
            customerInfo={customerInfo}
            machineList={machineList}
            operatorList={operatorList}
            jobList={jobList}
            selectedMachine={selectedMachine}
            setSelectedMachine={setSelectedMachine}
            selectedOperator={selectedOperator}
            setSelectedOperator={setSelectedOperator}
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
          />
        </Col>
        <Col span={1} style={{ textAlign: "left" }}>
          <Divider
            type="vertical"
            style={{
              height: "100%",
              color: "#eeeeee",
              borderWidth: 2,
              borderColor: "#eeeeee",
            }}
          ></Divider>
        </Col>
        <Col span={14}>
          <div className="data-operation-page">
            <div className="data-period-container">
              <div>
                <h3 style={{ color: "#eeeeee" }}>
                  {lang(langIndex, "report_reportperiod")}:
                </h3>
              </div>
              <div>
                <ReportPeriod
                  customerInfo={customerInfo}
                  fromReportDate={fromReportDate}
                  setFromReportDate={setFromReportDate}
                  toReportDate={toReportDate}
                  setToReportDate={setToReportDate}
                  selectedEmails={selectedEmails}
                />
              </div>
            </div>

            <div className="report-section-container">
              <ReportConfiguration
                customerInfo={customerInfo}
                fromReportDate={fromReportDate}
                setFromReportDate={setFromReportDate}
                toReportDate={toReportDate}
                setToReportDate={setToReportDate}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                screenSize={screenSize}
              />
              <div style={{ marginLeft: 50 }}>
                <GeneratePDF
                  customer_id={customer_id}
                  fromReportDate={fromReportDate}
                  toReportDate={toReportDate}
                  selectedEmails={selectedEmails}
                  selectedMachine={selectedMachine}
                  selectedOperator={selectedOperator}
                  selectedJob={selectedJob}
                  isGeneratingPDF={isGeneratingPDF}
                  setIsGeneratingPDF={setIsGeneratingPDF}
                  customerInfo={customerInfo}
                  machineInfo={machineList.filter(
                    (x) => selectedMachine.includes(x.machine_id)
                  )}
                />
              </div>
            </div>
            <div className="export-to-csv-section-container">
              <ExportCSVConfiguration
                customerInfo={customerInfo}
                fromReportDate={fromReportDate}
                setFromReportDate={setFromReportDate}
                toReportDate={toReportDate}
                setToReportDate={setToReportDate}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                screenSize={screenSize}
              />
              <div style={{ marginLeft: 50 }}>
                <ExportCSVButton
                  customer_id={customer_id}
                  fromReportDate={fromReportDate}
                  toReportDate={toReportDate}
                  selectedEmails={selectedEmails}
                  selectedMachine={selectedMachine}
                  selectedOperator={selectedOperator}
                  selectedJob={selectedJob}
                  isGeneratingPDF={isGeneratingPDF}
                  setIsGeneratingPDF={setIsGeneratingPDF}
                  customerInfo={customerInfo}
                  machineInfo={machineList.filter(
                    (x) => selectedMachine.includes(x.machine_id)
                  )}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ReportPage;
