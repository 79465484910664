import { Col, Radio, Row } from "antd";
import React from "react";
import "./OptionInput.css";
function OptionInput(props) {
  const { initValue, field, updateValue, title } = props;
  return (
    <div className="app-setting-option-input-container">
      <Radio.Group
        // onChange={updateValue}
        value={initValue[field]}
        className="app-setting-option"
        onChange={(e) => {
          console.log("field", field);
          console.log("inputValue", initValue);
          updateValue(field, e.target.value);
        }}
      >
        <Row>
          <Col span={12}>
            <Radio value={"0"}>{title[0]}</Radio>
          </Col>
          <Col span={12}>
            <Radio value={"1"}>{title[1]}</Radio>
          </Col>
        </Row>
      </Radio.Group>
    </div>
  );
}

export default OptionInput;
