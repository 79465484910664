import React from 'react'
import Chart from 'react-google-charts';
import './sensor_trend_chart.css'
function SensorTrendChart(props) {
    const { data, chartColor, screenSize } = props;

    var chartData = [[{ type: 'datetime', label: 'Last Update' }, "Data1", "Data2", "Data3"], ...data];
    // console.log(chartData);
    const options = {
        // width: "100%",
        height: "100%",
        // legend: 'none',
        // backgroundColor: 'transparent',
        backgroundColor: '#eeeeee',
        colors: [...chartColor],
        // chartArea: { 'width': '90%', 'height': '90%' },        
        hAxis: {
            title: 'Time',
        },
        vAxis: {
            title: 'Sensor Value',
        },
    };

    return (
        <div className="sensor-trend-chart-container" style={{ height: screenSize.height / 2 }}>
            {data.length == 0
                ?
                <div>No valid data</div>
                :
                <Chart
                    chartType="LineChart"
                    data={chartData}
                    options={options}
                />
            }
        </div>
    )
}

export default SensorTrendChart
