const axios = require("axios").default;
const sensorBaseUrl = "https:/test.slymms.com/sensor/";

export const SENSOR_IMAGE_BASE_URL = "";

export const MACHINE_IMAGE_BASE_URL =
  "https:/test.slymms.com/backend/images/machine/";
export const MAINTENANCE_IMAGE_BASE_URL =
  "https:/test.slymms.com/backend/images/maintenance/";
export const BASE_URL = "https:/test.slymms.com/backend/";
export const BASE_URL1 = "https:/test.slymms.com/backend/";

//export const BASE_URL = "http://192.168.0.68/mms/";
export default {
  LOGIN: BASE_URL + "login.php",
  GET_CUSTOMER_ALL_INFO: BASE_URL + "get_all_customer_info.php",
  // GET_TABLE: BASE_URL + "get_table",
  GET_TABLE: BASE_URL + "get_table.php",
  TEST_URL: BASE_URL + "test.php",
  GET_MACHINE_GROUP: BASE_URL + "get_machine_group_list.php",
  MANAGE_MACHINE_GROUP: BASE_URL + "manage_machine_group.php",
  // GET_GANTT: BASE_URL + "get_gantt.php",
  GET_MACHINE_DATA: BASE_URL + "get_machine_data.php",
  GET_MACHINE_INSTALL_CONFIG: BASE_URL + "get_machine_install_config.php",
  GET_JOB_LIST: BASE_URL + "get_job_list.php",

  GET_OPERATOR_LIST: BASE_URL + "get_operator_list.php",
  EXECUTE_QUERY: BASE_URL + "execute_query.php",

  SEND_EMAIL: "https://test.api.slymms.com/emailPage/oneMailSender.php",

  ADD_ENTRY: BASE_URL + "add_entry.php",
  DELETE_JOB_ENTRY: BASE_URL + "delete_job_entry.php",
  UPDATE_JOB_ENTRY: BASE_URL + "update_job_entry.php",
  
  ADD_USER: BASE_URL + "add_user.php",
  UPDATE_USER: BASE_URL + "update_user.php",
  DELETE_USER: BASE_URL + "delete_user.php",
  
  ADD_MACHINE: BASE_URL + "add_machine.php",

  ADD_MAINTENANCE_ENTRY: BASE_URL + "add_maintenance_entry.php",
  DELETE_MAINTENANCE_ENTRY: BASE_URL + "delete_maintenance_entry.php",
  UPDATE_MAINTENANCE_ENTRY: BASE_URL + "update_maintenance_entry.php",

  GET_REPORT_PERIOD_DATA: BASE_URL + "get_period_report_data.php",
  GET_REPORT_PERIOD_DATA_BY_OPERATOR: BASE_URL + "get_period_report_data_by_operator.php",

  GET_HST_PERIOD_DATA: BASE_URL + "get_hst_period_data.php",
  GET_INCYCLE_TIMES: BASE_URL + "get_incycle_times.php",
  GET_APP_SETTING: BASE_URL + "get_app_setting.php",

  UPDATE_APP_SETTING: BASE_URL + "update_app_setting.php",

  UPDATE_GANTT_STATUS: BASE_URL + "update_gantt_status.php",

  UPLOAD_JOB_FILES: BASE_URL + "upload_files.php",
  UPLOAD_JOBENTRY_CSV: BASE_URL + "upload_job_entry_csv.php",


  PDF_SENDER: "https://test.api.slymms.com/emailPage/pdf_sender.php",
  PDF_DOWNLOADER: "https://test.api.slymms.com/emailPage/pdf_downloader.php",

  CREATE_NEW_FACTORY: BASE_URL + "create_new_factory.php",

  SENSOR_ADD_SENSOR: sensorBaseUrl + "add_sensor.php",
  SENSOR_GET_LIST: sensorBaseUrl + "get_sensor_list.php",
  SENSOR_GET_INFO_LIST: sensorBaseUrl + "get_sensor_info_list.php",
  SENSOR_GET_TABLE: sensorBaseUrl + "get_table.php",
  SENSOR_GET_SENSOR_DATA: sensorBaseUrl + "get_sensor_data.php",
  SENSOR_UPDATE_SENSOR: sensorBaseUrl + "update_sensor.php",
  SENSOR_DELETE_SENSOR: sensorBaseUrl + "delete_sensor.php",
};

export function postRequest(url, param, callback) {
  const username = localStorage.getItem("username");
  // if (username != null) {
  //   try {
  //     param["username"] = username;
  //   } catch (_) {}
  // }
  // console.log(url, param);
  axios
    .post(url, param)
    .then((res) => {
      //console.log(res);
      callback(res.data);
    })
    .catch((error) => {
      callback(null);
      console.log(error);
      
      
    });
}
