import React from "react";
import { useSelector } from "react-redux";
import lang from "../../services/lang";
import "./TMSPage.css";
function TMSPage(props) {
  const { langIndex } = useSelector((state) => state.app);
  return (
    <div>
      <div className="coming_soon">{lang(langIndex, "coming_soon")}</div>
    </div>
  );
}

export default TMSPage;
