import React from "react";
import { Layout, Row, Col } from "antd";
import { Button, Typography } from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  SettingOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import {
  menuReport,
  sizePad,
  sizeMobile,
} from "../../services/common/constants";
import { useDispatch, useSelector } from "react-redux";
import { SignOut } from "../../services/redux/reducers/auth_service";
import { withRouter } from "react-router";
const { Header } = Layout;
const { Text } = Typography;
function MyHeader(props) {
  const dispatch = useDispatch();
  const { username, screenSize, customerInfo } = props;
  const { security_level, fullname } = props;
  const { isMenuCollapsed, setIsMenuCollapsed, selCustomerId } = props;
  const logOut = () => {
    SignOut(dispatch);
    props.history.push("/login");
  };
  return screenSize.width >= sizePad ? (
    // desktop version
    <Header style={{ paddingLeft: 10, height: 40, margin: 0, marginRight: 10 }}>
      <Row align="middle" justify="end">
        <Col span={2} style={{ textAlign: "left" }}>
          <Button
            type="ghost"
            style={{ color: "white" }}
            onClick={() => props.setIsMenuCollapsed(!props.isMenuCollapsed)}
            icon={
              !props.isMenuCollapsed ? (
                <DoubleLeftOutlined />
              ) : (
                <DoubleRightOutlined />
              )
            }
          />
        </Col>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text style={{ color: "white", fontSize: 18, marginRight: 5 }}>
            <UserOutlined />
            {username}
          </Text>
          <Button
            type="ghost"
            icon={<LogoutOutlined />}
            style={{ color: "white" }}
            onClick={logOut}
          ></Button>
        </Col>
      </Row>
    </Header>
  ) : screenSize.width >= sizeMobile ? (
    // iPad version
    <Header
      style={{
        paddingLeft: 10,
        height: 40,
        margin: 0,
        paddingRight: 10,
        marginRight: 10,
      }}
    >
      <Row align="middle" justify={"center"}>
        <Col span={3} style={{ textAlign: "left" }}>
          <Button
            type="ghost"
            style={{ color: "white" }}
            onClick={() => props.setIsMenuCollapsed(!props.isMenuCollapsed)}
            icon={<MenuOutlined />}
          />
        </Col>
        <Col span={8}>
          <h3 style={{ color: "#dddddd" }}>{customerInfo["name"]}</h3>
        </Col>
        <Col span={7} style={{ textAlign: "center" }}>
          <img
            src={customerInfo["logo"]}
            alt="customer-logo"
            className="img-mobile"
          />
        </Col>
        <Col span={5} style={{ textAlign: "right" }}>
          <Text style={{ color: "white", fontSize: 18, marginRight: 5 }}>
            <UserOutlined />
            &nbsp;{fullname}&nbsp;
          </Text>
          <Button
            type="ghost"
            icon={<LogoutOutlined />}
            style={{ color: "white" }}
            onClick={logOut}
          ></Button>
        </Col>
      </Row>
    </Header>
  ) : (
    // mobile version
    <Header
      style={{
        paddingLeft: 10,
        height: 40,
        margin: 0,
        paddingRight: 10,
        marginRight: 10,
      }}
    >
      <Row align="middle" justify="end">
        <Col span={3} style={{ textAlign: "left" }}>
          <Button
            type="ghost"
            style={{ color: "white" }}
            onClick={() => props.setIsMenuCollapsed(!props.isMenuCollapsed)}
            icon={<MenuOutlined />}
          />
        </Col>
        <Col span={18}>
          <h3 style={{ color: "#dddddd" }}>{customerInfo["name"]}</h3>
        </Col>
        <Col span={3} style={{ textAlign: "right" }}>
          <Button
            type="ghost"
            icon={<LogoutOutlined />}
            style={{ color: "white" }}
            onClick={logOut}
          ></Button>
        </Col>
      </Row>
    </Header>
  );
}

export default withRouter(MyHeader);
