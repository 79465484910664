import React, { useState, useEffect } from "react";
import "./ImageUploader.css";
import { Input, Row, Col, message } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import Axios from "axios";
import { postRequest, BASE_URL } from "../../../../../../services/common/urls";
import { useSelector } from "react-redux";
import lang from "../../../../../../services/lang";
//

function ImageUploader(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { user_picture, updateImage, isLogo } = props;
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    setImageUrl(user_picture);
  }, [user_picture]);
  const onDropImage = (e) => {
    const files = Array.from(e.target.files);
    var formData = new FormData();

    formData.append("file", files[0]);
    postRequest(
      "https:/test.slymms.com/backend/upload_user_image.php",
      formData,
      (response) => {
        console.log(response.url);
        if (response.status == true) {
          setImageUrl(BASE_URL + "/" + response.url);
          updateImage(BASE_URL + "/" + response.url);
        } else {
          message.error(lang(langIndex, 'msg_something_wrong'));
        }
      }
    );
  };

  return (
    <form className="user-manage-avatar-container">
      <div className="user-image-container">
        <img
          src={imageUrl}
          className={
            isLogo == true
              ? "logo-image-style"
              : "user-manage-dialog-avatar-style"
          }
        />
      </div>
      <div className="user-manage-avatar-add-button-container">
        <label htmlFor="upload_image">
          <CameraOutlined
            className="user-manage-avatar-add-button-style"
            size={50}
          />
          <input
            type="file"
            id="upload_image"
            name="upload_image"
            style={{ display: "none" }}
            accept="image/x-png,image/gif,image/jpeg"
            onChange={onDropImage}
          />
        </label>
      </div>
    </form>
  );
}

export default ImageUploader;
