export const appOnSelectMenu = 'APP-ON-SELECT-MENU';
export const appToggleMenuCollapse = "APP-TOGGLE-MENU-COLLAPSE";
export const appUpdateConfigParams = "APP_UPDATE_CONFIG_PARAMS";

export const actSetAuthInfo = "act-set-auth-info";
export const actSignOut = "act-set-sign-out";

export const cncChangePage = 'CNC_CHANGE_PAGE';
export const cncGetMachineData = 'CNC_GET_MACHINE_DATA';
export const cncSetAllCustomerInfo = "CNC_SET_ALL_CUSTOMER_INFO";
export const cncUpdateCustomerInfo = 'CNC_UPDATE_CUSTOMER_INFO';
export const cncUpdateMachineListInfo = 'CNC_UPDATE_MACHINE_LIST_INFO';
export const cncUpdateMachineDetailInfo = 'CNC_UPDATE_MACHINE_DETAIL_INFO';
export const cncUpdateGroupMachineInfo = "CNC_UPDATE_GROUP_MACHINE_INFO";


export const sensorUpdateSensorList = 'SENSOR_UPDATE_SENSOR_LIST';
export const sensorUpdateSensorInfo = 'SENSOR_UPDATE_SENSOR_INFO';
export const sensorChangePage = 'SENSOR_CHANGE_PAGE';
export const sensorSelectSenesor ="SENSOR_UPDATE_SELECTED_SENSOR";
export const sensorAddNewSensor = 'SENSOR_ADD_NEW_SENSOR';
export const sensorUpdateSensor = 'SENSOR_UPDATE_SENSOR';
export const sensorDeleteSensor = "SENSOR_DELETE_SENSOR";

export const setLang = "SET-CURRENT-LANGUAGE";