import React from "react";
import OneGantt from "../../MainPage/MachineItem_Grid/OneGantt";
import { Row, Col, Typography } from "antd";

import moment, { duration } from "moment";

import { sizePad } from "../../../../services/common/constants";
import {
  pad,
  GetCustomerCurrentTime,
  ConvertTimespanToDateBasedOnTimezone,
  getBetweenTime,
} from "../../../../services/common/cnc_functions";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";

function TimelineContainer(props) {
  const { langIndex } = useSelector((x) => x.app);
  const {
    ganttInfo,
    machineInfo,
    customerInfo,
    setShowDetailGanttModal,
    currentDate,
    security_level,
    statusList,
    screenSize,
  } = props;
  const { myGanttList, setMyGanttList } = props;
  const { customer_id } = props;
  let statusName = lang(langIndex, "cnc_offline");
  let duration = " - - - ";
  if (ganttInfo.length > 0) {
    duration =
      ganttInfo[ganttInfo.length - 1]["end"] -
      ganttInfo[ganttInfo.length - 1]["start"];
    // getBetweenTime
    duration = getBetweenTime(
      ganttInfo[ganttInfo.length - 1]["start"],
      ganttInfo[ganttInfo.length - 1]["end"]
    );
    console.log();
    statusName = ganttInfo[ganttInfo.length - 1].status;
  } else {
    duration = " - - - ";
  }
  function infoStatusUI(title, value) {
    return (
      <Row style={{ textAlign: "left", color: "#eeeeee" }}>
        <Col span={6}>{title}</Col>
        <Col span={18}>{value}</Col>
      </Row>
    );
  }
  if (statusList.length > 0) {
    try {
      var tmp = statusList.filter(
        (x) =>
          x.machine_id.toLowerCase().trim() ==
          machineInfo["machine_id"].toLowerCase().trim()
      );
      if (tmp.length > 0) {
        var tmpStatus = tmp[0].status;
        if (tmpStatus.toLowerCase().trim() == "offline") {
          statusName = lang(langIndex, "cnc_offline");
        }
      }
    } catch (err) {}
  }

  // compare last gantt and current time, and set the status of machine
  if (ganttInfo != undefined && ganttInfo.length > 0) {
    // get last gantt data
    var lastGanttTime = ganttInfo[ganttInfo.length - 1].end;
    var currentTime = Math.round(new Date().getTime() / 1000);
    // console.log(currentTime, lastGanttTime, currentTime - lastGanttTime)
    if (currentTime - lastGanttTime > 60 * 5) {
      statusName = lang(langIndex, "cnc_offline");
    }
  }
  // if(ganttInfo.length > 0)
  //     console.log(ganttInfo[ganttInfo.length - 1]['start']);
  return (
    <div className="timeline-container">
      <div style={{ borderBottom: "1px solid white", textAlign: "left" }}>
        <Row style={{ padding: 0, margin: 0 }}>
          <Col span={10} style={{ textAlign: "left" }}>
            <h4 style={{ color: "#eeeeee" }}>
              {lang(langIndex, "cnc_timeline")}
            </h4>
          </Col>
          <Col span={14} style={{ textAlign: "right" }}>
            <h4
              className="see-detail-gantt-modal"
              onClick={() => setShowDetailGanttModal(true)}
            >
              {screenSize.width >= sizePad
                ? lang(langIndex, "cnc_seedetail")
                : lang(langIndex, "cnc_detailchart")}
            </h4>
          </Col>
        </Row>
      </div>
      {infoStatusUI(`${lang(langIndex, "cnc_status")}:`, statusName)}
      {infoStatusUI(
        `${lang(langIndex, "cnc_starttime")}:`,
        statusName == lang(langIndex, "cnc_offline")
          ? "- - -"
          : ganttInfo.length
          ? ConvertTimespanToDateBasedOnTimezone(
              ganttInfo[ganttInfo.length - 1]["start"],
              customerInfo["timezone"]
            ).toLocaleString()
          : "- - -"
      )}
      {infoStatusUI(
        `${lang(langIndex, "cnc_duration")}:`,
        statusName == lang(langIndex, "cnc_offline") ? "- - -" : duration
      )}
      <OneGantt
        ganttInfo={ganttInfo}
        machineInfo={machineInfo}
        customerInfo={customerInfo}
        currentDate={currentDate}
        customer_id={customer_id}
        myGanttList={myGanttList}
        security_level={security_level}
        setMyGanttList={setMyGanttList}
      />
    </div>
  );
}

export default TimelineContainer;
