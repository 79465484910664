import React, { useState, useEffect } from "react";
import "./MachineManageDialog.css";
import { Modal, Row, Col, Input } from "antd";
import MachineImageUploader from "./MachineImageUploader/MachineImageUploader";
import { useSelector } from "react-redux";
import lang from "../../../../../services/lang";

function MachineManageDialog(props) {
  const { langIndex } = useSelector((x) => x.app);
  const {
    initMachineInfo,
    isShowModal,
    setIsShowModal,
    addMachine,
    editMachine,
  } = props;
  const [machineInfo, setMachineInfo] = useState({
    machine_picture_url: "blank machine.png",
  });
  useEffect(() => {
    if (initMachineInfo != null) {
      setMachineInfo({ ...initMachineInfo });
    }
  }, []);
  const onClickOk = () => {
    if (initMachineInfo == null) {
      addMachine(machineInfo);
    } else {
      editMachine(machineInfo);
    }
  };
  const onClickCancel = () => {
    setIsShowModal(false);
  };
  const updateImage = (url) => {
    setMachineInfo({ ...machineInfo, machine_picture_url: url });
  };

  return (
    <div>
      <Modal
        title={
          initMachineInfo == null
            ? lang(langIndex, "plant_machineadddialog")
            : lang(langIndex, "plant_machineeditdialog")
        }
        visible={isShowModal}
        onOk={onClickOk}
        onCancel={onClickCancel}
        destroyOnClose={true}
        className="machine-setting-modal-style"
      >
        <div style={{ textAlign: "center" }}>
          <MachineImageUploader
            machine_picture={machineInfo.machine_picture_url}
            updateImage={updateImage}
          />
        </div>
        <div>
          <Row style={{ marginTop: 10 }}>
            <Col span={8}>
              <span>{lang(langIndex, "plant_machineid")}</span>
            </Col>
            <Col span={16}>
              <Input
                style={{ background: "#1e1e1e", color: "#eeeeee" }}
                value={
                  machineInfo.machine_id == undefined
                    ? ""
                    : machineInfo.machine_id
                }
                onChange={(e) =>
                  setMachineInfo({ ...machineInfo, machine_id: e.target.value })
                }
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default MachineManageDialog;
