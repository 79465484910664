import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import "./detail_page.css";
import { Modal, Button, Row, Col, DatePicker, Spin } from "antd";

import {
  ArrowLeftOutlined,
  SettingOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";

import {
  getDetailedSensorData,
  getEnumKeyByValue,
} from "../../../services/common/functions";
import SensorTrendChart from "../../../components/sensorPageWidgets/sensor_trend_chart/sensor_trend_chart";
import moment from "moment";
import DetailPageValueContainer from "./detailPageValueContainer/detailPageValueContainer";
import {
  SENSOR_TITLE_BY_ID,
  SENSOR_TYPE,
} from "../../../services/common/constants";
import { GetCustomerCurrentTime } from "../../../services/common/cnc_functions";
import AlertSettingContainer from "./alertSettingContainer/alertSettingContainer";
import ExportCSVDialog from "./exportCSVDialog/ExportCSVDialog";
import lang from "../../../services/lang";

const dateFormat = "YYYY-MM-DD HH:mm";

function DetailPage(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { sensorInfo, setSelectedSensorId, timezone } = props;
  // console.log('Selected Sensor info', sensorInfo);

  const [tick, setTick] = useState(true);
  const [intervalId, setIntervalID] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [isReading, setIsReading] = useState(false);
  const [isShowingExportModal, setShowingExportModal] = useState(false);
  const intervalTime = 15000;

  const [sensorData, setSensorData] = useState([]);

  const timer = () => {
    setTick((t) => !t);
  };

  const app_info = useSelector((state) => state.app);
  const customer_info = useSelector(
    (state) => state.cncService["customerInfoList"][app_info["customer_id"]]
  );

  useEffect(() => {
    const timezone = customer_info["timezone"];
    var curTime = GetCustomerCurrentTime(timezone);
    setCurrentTime(moment(curTime));

    setIntervalID(setInterval(timer, intervalTime));
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!isReading && currentTime != null) {
      var curTime = currentTime.format("YYYY-MM-DD");
      getDetailedSensorData(sensorInfo.sensor_id, curTime, (res) => {
        if (res == null) return;
        setSensorData(res);
      });
    }
  }, [tick]);
  useEffect(() => {
    if (currentTime != null) {
      setIsReading(true);
      var curTime = currentTime.format("YYYY-MM-DD");
      getDetailedSensorData(sensorInfo.sensor_id, curTime, (res) => {
        setIsReading(false);
        if (res == null) return;
        setSensorData(res);
      });
    }
  }, [currentTime]);

  const valueList = sensorData.map((info) => {
    return [
      new Date(info.reading_time),
      parseFloat(info.value1),
      parseFloat(info.value2),
      parseFloat(info.value3),
    ];
  });

  const exportToCSV = () => {
    setShowingExportModal(true);
  };

  var ownReadTime = "-";
  var isStatusWorking = false;
  if (sensorData.length > 0) {
    ownReadTime = sensorData[sensorData.length - 1].reading_time;
    ownReadTime = moment(ownReadTime);
    // console.log(ownReadTime);

    var customerTime = GetCustomerCurrentTime(timezone);

    customerTime = moment(customerTime);
    // console.log(customerTime);

    var duration = moment.duration(customerTime.diff(ownReadTime));
    var minutes = duration.asMinutes();
    if (minutes <= 30) {
      isStatusWorking = true;
      ownReadTime = lang(langIndex, "iiot_connectiongood");
    } else {
      isStatusWorking = false;
      ownReadTime = lang(langIndex, "iiot_connectionlost");
    }
  }
  var unit =
    sensorInfo == undefined || sensorInfo.unit == undefined
      ? ""
      : sensorInfo.unit;

  const updateSetting = (
    <div style={{ fontSize: 18 }}>
      <div className="sensor-detail-one-sensor-status">
        <div className="sensor-detail-one-sensor-status-title">
          {lang(langIndex, "iiot_connectionstatus")}:
        </div>
        <div
          className="sensor-detail-one-sensor-status-value"
          style={{ color: isStatusWorking ? "#eeeeee" : "#888888" }}
        >
          {ownReadTime}
        </div>
      </div>
      <div className="sensor-detail-one-sensor-id">
        <div className="sensor-detail-one-sensor-info-title">
          {lang(langIndex, "iiot_sn")}:
        </div>
        <div className="sensor-detail-one-sensor-info-value">
          {sensorInfo.sensor_id}
        </div>
      </div>
    </div>
  );
  return (
    <div className="detail-page-container-style">
      <ExportCSVDialog
        isShowingExportModal={isShowingExportModal}
        setShowingExportModal={setShowingExportModal}
        sensor_id={sensorInfo.sensor_id}
      />
      <ArrowLeftOutlined
        className="sensor-backward-button"
        onClick={() => setSelectedSensorId("")}
      />
      <Row align={"center"}>
        <Col xs={24} sm={12} md={6}>
          <div className="detail-page-container-image-container">
            <img
              className="sensor-detail-page-image-style"
              src={
                sensorInfo.image == ""
                  ? "https:/test.slymms.com/images/sensors/test sensor.png"
                  : sensorInfo.image
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <div className="sensor-detail-one-sensor-name">
            <div className="sensor-detail-one-sensor-info-title">
              {lang(langIndex, "iiot_name")}:
            </div>
            <div className="sensor-detail-one-sensor-info-value">
              {sensorInfo.sensor_name}
            </div>
          </div>
          <div className="sensor-detail-one-sensor-id">
            <div className="sensor-detail-one-sensor-info-title">
              {lang(langIndex, "iiot_type")}:
            </div>
            <div className="sensor-detail-one-sensor-info-value">
              {
                SENSOR_TITLE_BY_ID[
                  getEnumKeyByValue(SENSOR_TYPE, sensorInfo.type)
                ]
              }
            </div>
          </div>
          <div className="sensor-detail-one-sensor-location">
            <div className="sensor-detail-one-sensor-info-title">
              {lang(langIndex, "iiot_location_c")}:
            </div>
            <div className="sensor-detail-one-sensor-info-value">
              {sensorInfo.location}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} className={"chart-description-container"}>
          <Row style={{ fontSize: 18 }}>
            <Col flex={"220px"}> </Col>
            <Col flex={"auto"}>
              <Row>
                <Col xs={0} sm={8}></Col>
                <Col xs={0} sm={8} style={{ textAlign: "center" }}>
                  Max
                </Col>
                <Col xs={0} sm={8} style={{ textAlign: "center" }}>
                  Min
                </Col>
              </Row>
            </Col>
          </Row>
          <DetailPageValueContainer
            title={
              sensorInfo.for_value1 == ""
                ? `- ${lang(langIndex, "iiot_valuedescription")}1`
                : `- ${sensorInfo.for_value1}`
            }
            valueList={sensorData.map((x) => parseFloat(x.value1))}
            unit={unit}
            color={"red"}
          />
          <DetailPageValueContainer
            title={
              sensorInfo.for_value2 == ""
                ? `- ${lang(langIndex, "iiot_valuedescription")}2`
                : `- ${sensorInfo.for_value2}`
            }
            valueList={sensorData.map((x) => parseFloat(x.value2))}
            unit={unit}
            color={"blue"}
          />
          <DetailPageValueContainer
            title={
              sensorInfo.for_value3 == ""
                ? `- ${lang(langIndex, "iiot_valuedescription")}3`
                : `- ${sensorInfo.for_value3}`
            }
            valueList={sensorData.map((x) => parseFloat(x.value3))}
            unit={unit}
            color={"green"}
          />
        </Col>
      </Row>
      <div className="detail-page-trend-container">
        <div className="detail-page-trend-title">{lang(langIndex, "iiot_trendchart")}</div>
        <div className="detail-page-trend-chart">
          {isReading ? (
            <Spin spinning={isReading} size="large">
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: 20,
                  color: "#eeeeee",
                  background: "transparent",
                }}
              >
                {lang(langIndex, "loading_sensor_data")}
              </div>
            </Spin>
          ) : (
            <SensorTrendChart
              data={valueList}
              chartColor={["red", "blue", "green"]}
              screenSize={app_info.screenSize}
            />
          )}
        </div>
      </div>
      <Row style={{ marginTop: 10 }}>
        <Col xs={0} sm={0} md={8}>
          {updateSetting}
        </Col>
        <Col xs={0} sm={12} md={8}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <button className="detail-page-export-button" onClick={exportToCSV}>
              {lang(langIndex, "iiot_exporttocsv")}
            </button>
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} style={{ textAlign: "right" }}>
          <div>
            <Button
              type="ghost"
              style={{ color: "white" }}
              onClick={() =>
                setCurrentTime(moment(currentTime.add(-1, "days")))
              }
              icon={<DoubleLeftOutlined />}
            />
            <DatePicker
              className="detail-page-one-date"
              // showTime={{ format: 'HH:mm' }}
              // format="YYYY-MM-DD HH:mm"
              format="MM/DD/YYYY"
              onChange={(value) => setCurrentTime(value)}
              value={currentTime == "" ? moment() : currentTime}
            />
            <Button
              type="ghost"
              style={{ color: "white" }}
              onClick={() => setCurrentTime(moment(currentTime.add(1, "days")))}
              icon={<DoubleRightOutlined />}
            />
          </div>
        </Col>
        <Col xs={24} sm={0}>
          <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
            <button className="detail-page-export-button" onClick={exportToCSV}>
              {lang(langIndex, "iiot_exporttocsv")}
            </button>
          </div>
        </Col>
        <Col xs={24} sm={24} md={0}>
          {updateSetting}
        </Col>
      </Row>
      <div className="sensor-detail-alert-setting-container">
        <AlertSettingContainer sensorInfo={sensorInfo} />
      </div>
    </div>
  );
}

export default DetailPage;
