import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Input } from "antd";

import "./LoginPage.css";
import Urls, { postRequest } from "../../services/common/urls";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Login, SignOut } from "../../services/redux/reducers/auth_service";
import lang from "../../services/lang";
function LoginPage(props) {
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [isWrong, setIsWrong] = useState(false);
  const appData = useSelector((state) => state.app);
  // console.log("APPDATA", appData);
  const { langIndex } = appData;
  
  useEffect(() => {
    SignOut(dispatch);
  }, []);

  const onClickSigninButton = async () => {
    Login(username, password, dispatch, (res) => {
      if (res == true) {
        props.history.push("/pages");
      } else {
        setIsWrong(true);
      }
    });
  };

  return (
    <div className="background page">
      <div className="LoginDialog">
        {/* <h4 className="whiteText">LOGIN</h4> */}
        <div className="login-page-logo-container">
          <img className="login-page-logo-image" alt="logo" src={logo} />
          <h4 className="login-page-title">{lang(langIndex, "login_desc1")}</h4>
          <h4 className="login-page-title">{lang(langIndex, "login_desc2")}</h4>
          <h4 className="login-page-title">{lang(langIndex, "login_desc3")}</h4>
          <h4></h4>
          <h4></h4>
        </div>
        <div className="row">
          <div className="col-3 whiteText" style={{ textAlign: "left" }}>
            {lang(langIndex, "login_username")}
          </div>
          <div className="col-9">
            <Input
              placeholder={lang(langIndex, "login_username")}
              type="text"
              style={{ background: "transparent", color: "#cccccc" }}
              value={username}
              onChange={(e) => {
                setusername(e.target.value);
                setIsWrong(false);
              }}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-3 whiteText" style={{ textAlign: "left" }}>
            {lang(langIndex, "login_password")}
          </div>
          <div className="col-9">
            <Input
              placeholder={lang(langIndex, "login_password")}
              type="password"
              style={{ background: "transparent", color: "#cccccc" }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setIsWrong(false);
              }}
              onPressEnter={onClickSigninButton}
            />
          </div>
        </div>

        <div style={{ marginTop: 20, textAlign: "center" }}>
          {isWrong ? (
            <h5
              className="whiteText"
              style={{ fontSize: 13, color: "#C8242B" }}
            >
              {lang(langIndex, "login_error")}
            </h5>
          ) : (
            <h5 className="whiteText" style={{ fontSize: 13 }}>
              __
            </h5>
          )}
        </div>
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <Button
            ghost={true}
            onClick={onClickSigninButton}
            className="login-dlg-button"
          >
            {lang(langIndex, "login_login")}
          </Button>
        </div>
        <div className="login-page-link-text" style={{ marginTop: 20 }}>
          {lang(langIndex, "login_has_keycode")}{" "}
          <Link to={"/register"}>{lang(langIndex, "login_register_here")}</Link>
        </div>
        <div className="login-page-link-text" style={{ marginTop: 15 }}>
          {lang(langIndex, "login_privacy")}
          <a href="https://www.slytrackr.com/legal.html">
            {lang(langIndex, "login_here")}
          </a>
        </div>
        <div style={{ marginTop: 20, textAlign: "center" }}>
          {/* <a style={{ textAlign: 'center', fontSize: 12, color: '#cccccc' }}> Forgot Password? </a>
                            <a href="#" style={{ textAlign: 'center', fontSize: 12, color: 'white' }}>Click here to Reset Password.</a> */}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
