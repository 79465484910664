import React, { useState } from "react";
import { List } from "antd";
import {
  GetCustomerCurrentTime,
  pad,
  GetMachineListDataOneTime,
} from "../../../../services/common/cnc_functions";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";

function HistoryContainer(props) {
  const { langIndex } = useSelector((x) => x.app);

  const {
    customer_id,
    machineInfo,
    customerInfo,
    setCurrentDate,
    setMyHstList,
    setMyGanttList,
    currentDate,
    todayString,
  } = props;
  const { setIsMachineDetailPageContentLoading } = props;
  let dateArray = [""];
  const timezone = GetCustomerCurrentTime(customerInfo["timezone"]);
  for (var i = 1; i < 30; i++) {
    var c = GetCustomerCurrentTime(customerInfo["timezone"]);
    c.setDate(c.getDate() - i);
    dateArray.push(
      pad(c.getMonth() + 1) + "/" + pad(c.getDate()) + "/" + c.getFullYear()
    );
  }
  // console.log(dateArray);
  const onSelectDate = (date) => {
    if (date == "") {
      setCurrentDate("");
      return;
    }

    const startDate = date;
    let endDate = Date.parse(date);
    endDate = new Date(endDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate =
      pad(endDate.getMonth() + 1) +
      "/" +
      pad(endDate.getDate()) +
      "/" +
      pad(endDate.getFullYear());
    console.log("Date clicked", startDate, endDate);

    setIsMachineDetailPageContentLoading(true);
    GetMachineListDataOneTime(
      customer_id,
      customerInfo["timezone"],
      startDate,
      endDate,
      "",
      (res) => {
        if (res != null) {
          // console.log(res.data);
          // extractMachineData(res.data.hst, res.data.gantt);
          setMyGanttList([...res.data.gantt]);
          setMyHstList([...res.data.hst]);
          setCurrentDate(startDate);
          setIsMachineDetailPageContentLoading(false);
        }
      }
    );
  };
  return (
    <div className="history-container">
      <div style={{ borderBottom: "1px solid white", textAlign: "left" }}>
        <h4 style={{ color: "#eeeeee" }}>{lang(langIndex, "cnc_history")}</h4>
      </div>
      <div style={{ maxHeight: 180, overflow: "auto" }}>
        <List
          header={null}
          footer={null}
          bordered
          dataSource={dateArray}
          size={"small"}
          renderItem={(item) => {
            return (
              <List.Item
                className={
                  item == currentDate
                    ? "history-item history-item-selected "
                    : "history-item"
                }
                onClick={() => {
                  onSelectDate(item);
                }}
              >
                {item == "" ? todayString : item}
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
}

export default HistoryContainer;
