import React, { useEffect, useRef } from 'react'
import logo from '../../../assets/images/logo.png'
import MachineItem from './MachineItem_Grid/MachineItem';
import MachineItemTile from './MachineItem_Tile/MachineItemTile';
import { Row } from 'antd';
import { sizePad, sizeMobile, VIEWMODE } from '../../../services/common/constants';
function MachineItemContainer(props) {
    const { machineList, viewMode, customerInfo, customer_id, hstList, ganttList, onClickMachine, operatorList, statusList } = props;
    const { screenSize } = props;

    const machineUIList = machineList.map((item) => {
        // console.log(item);
        if (viewMode == VIEWMODE.listView) {
            return (
                <MachineItem key={machineList.indexOf(item)}
                    machineInfo={item}
                    customer_id={customer_id}
                    customerInfo={customerInfo}
                    hstList={hstList}
                    ganttList={ganttList}
                    onClickMachine={onClickMachine}
                    operatorList={operatorList}
                    statusList={statusList}
                    screenSize={screenSize}
                />);
        } else {
            return (
                <MachineItemTile key={machineList.indexOf(item)}
                    machineInfo={item}
                    customer_id={customer_id}
                    customerInfo={customerInfo}
                    hstList={hstList}
                    ganttList={ganttList}
                    onClickMachine={onClickMachine}
                    operatorList={operatorList}
                    statusList={statusList}
                    screenSize={screenSize}
                />);
        }
    });
    return (
        <div className="content-page">
            <div
                style={{ minHeight: (screenSize.height - 100 - (screenSize.width >= sizePad ? 80 : screenSize.width >= sizeMobile ? 60 : 50)) }}
            >
                <Row
                    justify={"start"}
                    align={'top'}
                    gutter={16}
                >
                    {machineUIList}
                </Row>
            </div>
            <div style={{ textAlign: 'center' }} >
                <img className="logo-image" alt="logo"
                    src={logo}
                    style={{ width: screenSize.width >= sizePad ? 200 : screenSize.width >= sizeMobile ? 150 : 100 }} />
            </div>
        </div>
    )
}

export default MachineItemContainer
