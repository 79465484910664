import React, { useState, useEffect } from "react";
import "./ReportEmails.css";
import { Input, message } from "antd";
import OneEmail from "./OneEmail/OneEmail";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import lang from "../../../../../services/lang";
function ReportEmails(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { customerInfo, updateCustomerInfo } = props;
  const [emailList, setEmailList] = useState([]);
  const [typingEmail, setTypingMail] = useState("");
  useEffect(() => {
    if (customerInfo.emails == null) {
      customerInfo.emails = "";
    }
    var tmpList = customerInfo.emails.split(";");
    var mails = [];
    for (var i = 0; i < tmpList.length; i++) {
      var tmp = tmpList[i].split(":")[0];
      if (tmp != "") mails.push(tmpList[i].split(":")[0]);
    }

    setEmailList(mails);
    

  }, []);


  const deleteEmail = (email) => {
    var tmpList = emailList;
    var index = tmpList.indexOf(email);
    tmpList.splice(index,1);
    setEmailList(tmpList);
    onUpdateReportDays();
  };

  const addMail = () => {
    if (typingEmail == "") return;
    if (typingEmail.includes("@") && typingEmail.includes(".")) {
      var tmpList = emailList;
      tmpList.push(typingEmail);
      setEmailList(tmpList);
      setTypingMail("");
      onUpdateReportDays();
    } else {
      message.warning("Please type valid email.");
    }
  };
  const onUpdateReportDays = () => {
    var emails = emailList.join(";");
    let newInfo = customerInfo;
    newInfo.emails = emails;
    updateCustomerInfo(newInfo);
  };
  const emailUI = emailList.map((email, index) => {
    return <OneEmail key={index} email={email} deleteEmail={deleteEmail} />;
  });
  return (
    <div className="reportemails-setting-content-style">
      <h1 className="reportemails-title-style">{lang(langIndex, "plant_reportemail")}:</h1>
      <div className="reportemails-mail-input-style">
        <Input
          className="reportemails-input"
          value={typingEmail}
          type={"email"}
          onChange={(e) => setTypingMail(e.target.value)}
        />
      </div>
      <div className="reportemails-mail-add-button-style">
        <a onClick={addMail}> {lang(langIndex, "plant_reportadd")}</a>
      </div>
      <div className="reportemails-maillist-container-style">{emailUI}</div>
    </div>
  );
}

export default withRouter(ReportEmails);
