import { Col, Popconfirm, Row } from "antd";
import React from "react";
import SimpleInfoContainer from "../../../components/sensorPageWidgets/simple_info_container/simple_info_container";
import {
  SENSOR_TITLE_BY_ID,
  SENSOR_TYPE,
} from "../../../services/common/constants";
import { getEnumKeyByValue } from "../../../services/common/functions";
import "./one_item.css";
import "./glowEffect.scss";
import moment from "moment";
import { GetCustomerCurrentTime } from "../../../services/common/cnc_functions";
import { ToolOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import lang from "../../../services/lang";
{
  /* <ToolOutlined /> */
}
{
  /* <DeleteOutlined /> */
}
function OneItem(props) {
  const { langIndex } = useSelector((x) => x.app);
  const {
    sensor,
    sensorInfo,
    onSelectSensor,
    timezone,
    onDeleteSensor,
    setSelectedSensorId,
  } = props;
  // SENSOR_TITLE_BY_ID[SENSOR_TYPE[sensor.type]]
  //console.log(getEnumKeyByValue(SENSOR_TYPE, sensor.type));
  var ownReadTime = sensorInfo == undefined ? "-" : sensorInfo.reading_time;
  var isStatusWorking = false;
  if (ownReadTime != "-") {
    ownReadTime = moment(ownReadTime);
    // console.log(ownReadTime);

    var customerTime = GetCustomerCurrentTime(timezone);

    customerTime = moment(customerTime);
    // console.log(customerTime);

    var duration = moment.duration(customerTime.diff(ownReadTime));
    var minutes = duration.asMinutes();
    if (minutes <= 30) {
      isStatusWorking = true;
    }
  }
  var unit =
    sensorInfo == undefined || sensor.unit == undefined ? "" : sensor.unit;
  return (
    <div
      className="one-sensor-container"
      // onClick={() => onSelectSensor(sensorName)}
    >
      <div className="one-sensor-name-container">
        <Popconfirm
          onConfirm={() => onDeleteSensor(sensor.sensor_id)}
          okText="Yes"
          cancelText="No"
          title={lang(langIndex, "jobentry_suretodelete")}
          placement="bottomLeft"
          className="sensor-delete-popup"
        >
          <div className="one-sensor-delete-button">X</div>
        </Popconfirm>
        <div className="one-sensor-name">{sensor.sensor_name}</div>
        <div
          className={
            isStatusWorking
              ? "one-sensor-status green-glow"
              : "one-sensor-status none-glow"
          }
        >
          &#8226;
        </div>
      </div>
      <div
        onClick={() => {
          setSelectedSensorId(sensor.sensor_id);
        }}
      >
        <div className="one-sensor-image-container-style">
          <img
            className="one-sensor-image-style"
            src={
              sensor.image == ""
                ? "https:/test.slymms.com/images/sensors/test sensor.png"
                : sensor.image
            }
          />
        </div>
        <SimpleInfoContainer
          title={lang(langIndex, "iiot_location")}
          value={sensor.location}
        />
        <SimpleInfoContainer
          title={lang(langIndex, "iiot_type")}
          value={
            SENSOR_TITLE_BY_ID[getEnumKeyByValue(SENSOR_TYPE, sensor.type)]
          }
        />

        <Row>
          <Col span={24}>
            <SimpleInfoContainer
              title={
                sensor.for_value1 == ""
                  ? `${lang(langIndex, "iiot_value")}1`
                  : sensor.for_value1
              }
              value={
                sensorInfo == undefined ? "-" : sensorInfo.value1 + " " + unit
              }
              valueSize={20}
            />
          </Col>
          {/* <Col span={8}>
                    <SimpleInfoContainer
                        title="Min"
                        value={sensorInfo == undefined ? "-" : sensorInfo.value1_min}
                    />
                </Col>
                <Col span={8}>
                    <SimpleInfoContainer
                        title="Max"
                        value={sensorInfo == undefined ? "-" : sensorInfo.value1_max}
                    />
                </Col> */}
        </Row>
        <Row>
          <Col span={24}>
            <SimpleInfoContainer
              title={
                sensor.for_value2 == ""
                  ? `${lang(langIndex, "iiot_value")}2`
                  : sensor.for_value2
              }
              value={
                sensorInfo == undefined ? "-" : sensorInfo.value2 + " " + unit
              }
              valueSize={20}
            />
          </Col>
          {/* <Col span={8}>
                    <SimpleInfoContainer
                        title="Min"
                        value={sensorInfo == undefined ? "-" : sensorInfo.value2_min}
                    />
                </Col>
                <Col span={8}>
                    <SimpleInfoContainer
                        title="Max"
                        value={sensorInfo == undefined ? "-" : sensorInfo.value2_max}
                    />
                </Col> */}
        </Row>
        <Row>
          <Col span={24}>
            <SimpleInfoContainer
              title={
                sensor.for_value3 == ""
                  ? `${lang(langIndex, "iiot_value")}3`
                  : sensor.for_value3
              }
              value={
                sensorInfo == undefined ? "-" : sensorInfo.value3 + " " + unit
              }
              valueSize={20}
            />
          </Col>
          {/* <Col span={8}>
                    <SimpleInfoContainer
                        title="Min"
                        value={sensorInfo == undefined ? "-" : sensorInfo.value3_min}
                    />
                </Col>
                <Col span={8}>
                    <SimpleInfoContainer
                        title="Max"
                        value={sensorInfo == undefined ? "-" : sensorInfo.value3_max}
                    />
                </Col> */}
        </Row>

        <SimpleInfoContainer
          title={lang(langIndex, "iiot_updatetime")}
          value={
            sensorInfo == undefined
              ? "-"
              : ownReadTime.format("MM/DD/YYYY HH:mm:ss")
          }
        />
      </div>
    </div>
  );
}

export default OneItem;
