import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Col, Row, Button, Radio, Divider, Select, Space } from "antd";

import {
  AppstoreOutlined,
  MenuOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import {
  pad,
  GetCustomerCurrentTime,
} from "../../services/common/cnc_functions";

import {
  PAGETYPE,
  menuReport,
  sizePad,
  sizeMobile,
  VIEWMODE,
} from "../../services/common/constants";
import { onSelectMenu, SetLanguage } from "../../services/redux/reducers/app";
import lang from "../../services/lang";

const { Option } = Select;

function MyBreadcrumb(props) {
  const dispatch = useDispatch();
  const { langIndex, page_type, customer_id } = useSelector((x) => x.app);

  const { customerInfo, setAutoScroll } = props;
  const { security_level } = props;
  const { viewMode, setViewMode, screenSize } = props;
  const { isFullScreen, setIsFullScreen } = props;

  const [currentTime, setCurrentTime] = useState("");
  const [customerTime, setCustomerTime] = useState("");

  const [tick, setTick] = useState(true);
  const [intervalId, setIntervalID] = useState(null);
  const app = useSelector((x) => x.app);
  useEffect(() => {
    setIntervalID(setInterval(timer, 1000));
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const timer = () => {
    setTick((t) => !t);
  };
  useEffect(() => {
    // if (selCustomerId != menuReport && customerInfo != [] && customerInfo != null && customerInfo['timezone'] != undefined) {

    const timezone = customerInfo["timezone"];
    let _cusTime = GetCustomerCurrentTime(timezone);

    let _curTime = new Date();
    setCustomerTime(_cusTime.toLocaleString());
    setCurrentTime(_curTime.toLocaleString());
    // console.log(_cusTime.toLocaleString());
    // console.log(_curTime.toLocaleString());
  }, [tick]);
  // console.log(typeof (customerTime), typeof (currentTime));

  const langMenu = (
    <Select
      // defaultValue={[props.langIndex]}
      value={langIndex}
      onChange={(v) => SetLanguage(v, dispatch)}
      className="language-select-menu"
      dropdownClassName="page-changer-style-dropdown"
    >
      <Option className="page-changer-item" value={0} >
        English
      </Option>
      <Option className="page-changer-item" value={1}>
        简体中文
      </Option>
      <Option className="page-changer-item" value={2}>
        Española
      </Option>
    </Select>
  );

  return (
    <div className="breadcrumb-style">
      {screenSize.width >= sizePad ? (
        <Row align="middle">
          <Col span={5}
            style={{ verticalAlign: "middle", textAlign: "center" }}
          >
            <h2 style={{ color: "#dddddd" }}>{customerInfo["name"]}</h2>
          </Col>
          <Col span={2} style={{ textAlign: "center" }}>
            {page_type != PAGETYPE.tms &&
              page_type != PAGETYPE.iiot &&
              page_type != PAGETYPE.cnc_maintenance && (
                <Select
                  defaultValue={PAGETYPE.cnc}
                  value={page_type}
                  onChange={(v) =>
                    onSelectMenu(customer_id, v, app.group_id, dispatch)
                  }
                  className="page-changer-style"
                  dropdownClassName="page-changer-style-dropdown"
                >
                  <Option className="page-changer-item" value={PAGETYPE.cnc}>
                    {lang(langIndex, "breadcrumb_dashboard")}
                  </Option>
                  <Option
                    className="page-changer-item"
                    value={PAGETYPE.cnc_job}
                  >
                    {lang(langIndex, "breadcrumb_job_entry")}
                  </Option>
                  <Option
                    className="page-changer-item"
                    value={PAGETYPE.cnc_report}
                  >
                    {lang(langIndex, "breadcrumb_reports")}
                  </Option>
                  {security_level == 4 && (
                    <Option
                      className="page-changer-item"
                      value={PAGETYPE.cnc_plant_setting}
                    >
                      {lang(langIndex, "breadcrumb_plant_settings")}
                    </Option>
                  )}
                  {/* {security_level == 4 && <Option className="page-changer-item" value={PAGETYPE.cnc_maintenance}>Maintenance</Option>} */}
                </Select>
              )}
          </Col>
          <Col span={8}>
            <div className="customer-logo-image-container">
              <img
                src={customerInfo["logo"]}
                alt="customer-logo"
                className="customer-logo-img"
              />
            </div>
          </Col>
          <Col span={9}>
            <Row justify={"end"} align={"middle"}>
              <Col flex={"50px"}>
                <Button
                  ghost
                  icon={<FullscreenOutlined />}
                  onClick={() => setIsFullScreen(true)}
                ></Button>
              </Col>
              {/* {page_type != PAGETYPE.tms &&
                page_type != PAGETYPE.iiot &&
                page_type != PAGETYPE.cnc_maintenance && (
                  <Col span={8}>
                    <Radio.Group
                      value={viewMode}
                      buttonStyle="solid"
                      onChange={(e) => setViewMode(e.target.value)}
                    >
                      <Radio.Button value={VIEWMODE.listView}>
                        {" "}
                        <MenuOutlined size="large" style={{ color: "white" }} />
                      </Radio.Button>
                      <Radio.Button value={VIEWMODE.tileView}>
                        {" "}
                        <AppstoreOutlined style={{ color: "white" }} />{" "}
                      </Radio.Button>
                    </Radio.Group>
                    <Divider type="vertical" />
                    
                  </Col>
                )} */}
              <Col flex={"auto"}>
                <Row justify={"space-around"} align={"middle"}>
                  <Col style={{ textAlign: "center" }}>{langMenu}</Col>
                  <Col>
                    <p style={{ margin: 0, padding: 0, color: "#aaaaaa" }}>
                      {lang(langIndex, "breadcrumb_current_time")}:{" "}
                      {currentTime}
                    </p>
                    <p style={{ margin: 0, padding: 0, color: "#aaaaaa" }}>
                      {lang(langIndex, "breadcrumb_factory_time")}:{" "}
                      {customerTime}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : screenSize.width >= sizeMobile ? (
        <Row align="middle" justify="center" style={{ marginTop: 10 }}>
          <Col span={8} style={{ textAlign: "center" }}>
            {page_type != PAGETYPE.tms &&
              page_type != PAGETYPE.iiot &&
              page_type != PAGETYPE.cnc_maintenance && (
                <Select
                  defaultValue={PAGETYPE.cnc}
                  value={page_type}
                  onChange={(v) =>
                    onSelectMenu(customer_id, v, app.group_id, dispatch)
                  }
                  className="page-changer-style"
                  dropdownClassName="page-changer-style-dropdown"
                >
                  <Option className="page-changer-item" value={PAGETYPE.cnc}>
                    {lang(langIndex, "breadcrumb_dashboard")}
                  </Option>
                  <Option
                    className="page-changer-item"
                    value={PAGETYPE.cnc_job}
                  >
                    {lang(langIndex, "breadcrumb_job_entry")}
                  </Option>
                  <Option
                    className="page-changer-item"
                    value={PAGETYPE.cnc_report}
                  >
                    {lang(langIndex, "breadcrumb_reports")}
                  </Option>
                  {security_level == 4 && (
                    <Option
                      className="page-changer-item"
                      value={PAGETYPE.cnc_plant_setting}
                    >
                      {lang(langIndex, "breadcrumb_plant_settings")}
                    </Option>
                  )}
                  {/* {security_level == 4 && <Option className="page-changer-item" value={PAGETYPE.cnc_maintenance}>Maintenance</Option>} */}
                </Select>
              )}
          </Col>
          <Col span={16}>
            {page_type != PAGETYPE.tms &&
              page_type != PAGETYPE.iiot &&
              page_type != PAGETYPE.cnc_maintenance && (
                <Row align="middle" justify={"space-around"}>
                  <Col>
                    {langMenu}
                    {/* <Radio.Group
                      value={viewMode}
                      buttonStyle="solid"
                      onChange={(e) => setViewMode(e.target.value)}
                    >
                      <Radio.Button value={VIEWMODE.listView}>
                        {" "}
                        <MenuOutlined size="large" style={{ color: "white" }} />
                      </Radio.Button>
                      <Radio.Button value={VIEWMODE.tileView}>
                        {" "}
                        <AppstoreOutlined style={{ color: "white" }} />{" "}
                      </Radio.Button>
                    </Radio.Group> */}
                  </Col>
                  <Col>
                    <p style={{ margin: 0, padding: 0, color: "#aaaaaa" }}>
                      {customerTime}
                    </p>
                  </Col>
                </Row>
              )}
          </Col>
        </Row>
      ) : (
        <Row align="middle" style={{ marginTop: 10 }}>
          <Col span={12} style={{ textAlign: "center" }}>
            <img
              src={customerInfo["logo"]}
              alt="customer-logo"
              className="img-mobile"
            />
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            {page_type != PAGETYPE.tms &&
              page_type != PAGETYPE.iiot &&
              page_type != PAGETYPE.cnc_maintenance && (
                <Select
                  defaultValue={PAGETYPE.cnc}
                  value={page_type}
                  onChange={(v) =>
                    onSelectMenu(customer_id, v, app.group_id, dispatch)
                  }
                  className="page-changer-style"
                  dropdownClassName="page-changer-style-dropdown"
                >
                  <Option className="page-changer-item" value={PAGETYPE.cnc}>
                    {lang(langIndex, "breadcrumb_dashboard")}
                  </Option>
                </Select>
              )}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default MyBreadcrumb;
