import React from "react";
import { Progress, Row, Col } from "antd";
import IndicatorItem from "./IndicatorItem";
import { sizePad, sizeMobile } from "../../../../services/common/constants";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
function IndicatorContainer(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { machineInfo, hstInfo, screenSize, lastGantt } = props;

  return screenSize.width >= sizeMobile ? (
    <div className="indicator-container">
      <div style={{ borderBottom: "1px solid white", textAlign: "left" }}>
        <h4 style={{ color: "#eeeeee" }}>{lang(langIndex, "cnc_keyindicator")}</h4>
      </div>
      <Row gutter={[16, 16]} align="middle">
        <Col span={8} style={{ marginTop: 30 }}>
          <IndicatorItem
            value={parseFloat(hstInfo["oee"]) / 100.0}
            id={"oee_" + machineInfo["id"]}
            title={lang(langIndex, "cnc_oee")}
          />
        </Col>
        <Col span={16}>
          <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <IndicatorItem
                value={parseFloat(hstInfo["availability"]) / 100.0}
                id={"ava_" + machineInfo["id"]}
                title={lang(langIndex, "cnc_availability")}
              />
            </Col>
            <Col span={8}>
              <IndicatorItem
                value={parseFloat(hstInfo["quality"]) / 100.0}
                id={"qua_" + machineInfo["id"]}
                title={lang(langIndex, "cnc_quality")}
              />
            </Col>
            <Col span={8}>
              <IndicatorItem
                value={parseFloat(hstInfo["performance"]) / 100.0}
                id={"per_" + machineInfo["id"]}
                title={lang(langIndex, "cnc_performance")}
              />
            </Col>
          </Row>
          <div style={{ height: 80, paddingTop: 20 }}>
            {screenSize.width >= sizePad ? (
              // desktop version
              <Row>
                <Col span={14}>
                  <Row>
                    <Col span={14}>
                      <h3 style={{ color: "#eeeeee", textAlign: "right" }}>
                        {lang(langIndex, "cnc_mainprogram")}:&nbsp;
                      </h3>
                    </Col>
                    <Col span={10}>
                      <h3 style={{ color: "#eeeeee", textAlign: "left" }}>
                        {lastGantt["main_program"] == undefined
                          ? ""
                          : lastGantt["main_program"]}
                      </h3>
                    </Col>
                    <Col span={14}>
                      <h3 style={{ color: "#eeeeee", textAlign: "right" }}>
                        {lang(langIndex, "cnc_currentprogram")}:&nbsp;
                      </h3>
                    </Col>
                    <Col span={10}>
                      <h3 style={{ color: "#eeeeee", textAlign: "left" }}>
                        {lastGantt["current_program"] == undefined
                          ? ""
                          : lastGantt["current_program"]}
                      </h3>
                    </Col>
                  </Row>
                  {/* <div style={{ textAlign: 'left' }}>
                                                <h4 style={{ color: "#eeeeee" }}>
                                                    Production Rate: {((parseFloat(hstInfo['oee']) / 1000000.0 + parseFloat(hstInfo['quality']) / 100.0) / 2 * 100.0).toFixed(2)}%
                                                    </h4>
                                            </div>
                                            <Progress
                                                percent={(parseFloat(hstInfo['oee']) / 1000000.0 + parseFloat(hstInfo['quality']) / 100.0) / 2 * 100.0}
                                                strokeWidth={20}
                                                showInfo={false}
                                                status='active' /> */}
                </Col>
                <Col span={10} style={{ textAlign: "left", paddingLeft: 20 }}>
                  <div>
                    <h3 style={{ color: "#00FF00" }}>
                      {lang(langIndex, "cnc_goodparts")}&nbsp;:&nbsp;{hstInfo["goodParts"]}
                    </h3>
                  </div>
                  <div>
                    <h3 style={{ color: "#FF1B00" }}>
                      {lang(langIndex, "cnc_badparts")}&nbsp;&nbsp;&nbsp;:&nbsp;
                      {hstInfo["badParts"]}
                    </h3>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={14}>
                      <h3 style={{ color: "#eeeeee", textAlign: "right" }}>
                        {lang(langIndex, "cnc_mainprogram")}:&nbsp;
                      </h3>
                    </Col>
                    <Col span={10}>
                      <h3 style={{ color: "#eeeeee", textAlign: "left" }}>
                        {lastGantt["main_program"] == undefined
                          ? ""
                          : lastGantt["main_program"]}
                      </h3>
                    </Col>
                    <Col span={14}>
                      <h3 style={{ color: "#eeeeee", textAlign: "right" }}>
                        {lang(langIndex, "cnc_currentprogram")}:&nbsp;
                      </h3>
                    </Col>
                    <Col span={10}>
                      <h3 style={{ color: "#eeeeee", textAlign: "left" }}>
                        {lastGantt["current_program"] == undefined
                          ? ""
                          : lastGantt["current_program"]}
                      </h3>
                    </Col>
                  </Row>
                  {/* <div style={{ textAlign: 'left' }}>
                                                <h4 style={{ color: "#eeeeee" }}>
                                                    Production Rate: {((parseFloat(hstInfo['oee']) / 1000000.0 + parseFloat(hstInfo['quality']) / 100.0) / 2 * 100.0).toFixed(2)}%
                                                    </h4>
                                            </div>
                                            <Progress
                                                percent={(parseFloat(hstInfo['oee']) / 1000000.0 + parseFloat(hstInfo['quality']) / 100.0) / 2 * 100.0}
                                                strokeWidth={20}
                                                showInfo={false}
                                                status='active' /> */}
                </Col>
                <Col span={24} style={{ textAlign: "left", paddingLeft: 20 }}>
                  <div>
                    <h4 style={{ color: "#00FF00" }}>
                      {lang(langIndex, "cnc_goodparts")}&nbsp;:&nbsp;{hstInfo["goodParts"]}
                    </h4>
                  </div>
                  <div>
                    <h4 style={{ color: "#FF1B00" }}>
                      {lang(langIndex, "cnc_badparts")}&nbsp;&nbsp;&nbsp;:&nbsp;
                      {hstInfo["badParts"]}
                    </h4>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <div style={{ padding: 10 }}>
      <div style={{ borderBottom: "1px solid white", textAlign: "left" }}>
        <h4 style={{ color: "#eeeeee" }}>{lang(langIndex, "cnc_keyindicator")}</h4>
      </div>
      <Row gutter={[16, 16]} align="middle">
        <Col span={24}>
          <Row justify={"center"} align={"middle"}>
            <Col span={12}>
              <IndicatorItem
                screenSize={screenSize}
                value={parseFloat(hstInfo["oee"]) / 100.0}
                id={"oee_" + machineInfo["id"]}
                title={lang(langIndex, "cnc_oee")}
              />
            </Col>
            <Col span={12} style={{ textAlign: "left" }}>
              <div>
                <h4 style={{ color: "#00FF00" }}>
                  {lang(langIndex, "cnc_goodparts")}&nbsp;:&nbsp;{hstInfo["goodParts"]}
                </h4>
              </div>
              <div>
                <h4 style={{ color: "#FF1B00" }}>
                  {lang(langIndex, "cnc_badparts")}&nbsp;&nbsp;&nbsp;:&nbsp;
                  {hstInfo["badParts"]}
                </h4>
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row>
            <Col span={8}>
              <IndicatorItem
                screenSize={screenSize}
                value={parseFloat(hstInfo["Utilization"]) / 100.0}
                id={"ava_" + machineInfo["id"]}
                title={lang(langIndex, "cnc_utilization")}
              />
            </Col>
            <Col span={8}>
              <IndicatorItem
                screenSize={screenSize}
                value={parseFloat(hstInfo["quality"]) / 100.0}
                id={"qua_" + machineInfo["id"]}
                title={lang(langIndex, "cnc_quality")}
              />
            </Col>
            <Col span={8}>
              <IndicatorItem
                screenSize={screenSize}
                value={parseFloat(hstInfo["performance"]) / 100.0}
                id={"per_" + machineInfo["id"]}
                title={lang(langIndex, "cnc_performance")}
              />
            </Col>
          </Row>
          <Col span={24}>
            <div style={{ marginTop: 5 }}>
              <Row>
                <Col span={14}>
                  <h3 style={{ color: "#eeeeee", textAlign: "right" }}>
                    {lang(langIndex, "cnc_mainprogram")}:&nbsp;
                  </h3>
                </Col>
                <Col span={10}>
                  <h3 style={{ color: "#eeeeee", textAlign: "left" }}>
                    {lastGantt["main_program"] == undefined
                      ? ""
                      : lastGantt["main_program"]}
                  </h3>
                </Col>
                <Col span={14}>
                  <h3 style={{ color: "#eeeeee", textAlign: "right" }}>
                    {lang(langIndex, "cnc_currentprogram")}:&nbsp;
                  </h3>
                </Col>
                <Col span={10}>
                  <h3 style={{ color: "#eeeeee", textAlign: "left" }}>
                    {lastGantt["current_program"] == undefined
                      ? ""
                      : lastGantt["current_program"]}
                  </h3>
                </Col>
              </Row>
              {/* <div style={{ textAlign: 'left' }}>
                                    <h4 style={{ color: "#eeeeee" }}>
                                        Production Rate: {((parseFloat(hstInfo['oee']) / 1000000.0 + parseFloat(hstInfo['quality']) / 100.0) / 2 * 100.0).toFixed(2)}%
                                                    </h4>
                                </div>
                                <Progress
                                    percent={(parseFloat(hstInfo['oee']) / 1000000.0 + parseFloat(hstInfo['quality']) / 100.0) / 2 * 100.0}
                                    strokeWidth={20}
                                    showInfo={false}
                                    status='active' /> */}
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  );
}

export default IndicatorContainer;
