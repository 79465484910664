import React from 'react'
import Chart from 'react-google-charts';
import moment from 'moment';
import "./OneLineChart.css";

function OneLineChart(props) {
    const { oeeData } = props;
    let chartData = [];
    chartData = oeeData.map(x => [moment(x.date, "MM/DD/YYYY").toDate(), parseInt(x.oee), parseInt(x.performance), parseInt(x.quality), parseInt(x.availability)]);
    chartData = [[{ type: 'date'}, 'OEE', 'Performance', 'Quality', 'Availability'], ...chartData];
    return (
        <div>
            <Chart
                className="pdf-util-line-chart-style"
                width={'95%'}
                height={'320px'}
                chartType="Line"
                
                loader={<div>Loading Chart</div>}
                data={chartData}
                options={{
                    lineWidth: 5,
                    
                    
                    tooltip: { text: 'date' },
                    hAxis: {
                        title: '',
                        slantedText: true,
                        
                    },
                    
                    // vAxis: {
                    //     title: 'Popularity',
                    // },
                    // series: {
                    //     1: { curveType: 'function' },
                    // },
                }}
                rootProps={{ 'data-testid': '2' }}
            />
        </div>
    )
}

export default OneLineChart
