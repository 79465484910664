import React from "react";

import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/loginPage/LoginPage";
import MyLayout from "./components/Layout/MyLayout";
import CNCPages from "./pages/CNCPages/CNCPages";
import TMSPage from "./pages/TMSPage/TMSPage";
import SensorPage from "./pages/sensorPage/sensor_page";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import Wrapper from "./pages/Wrapper/Wrapper";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
function App() {
  return (
    <div style={{ background: "#131313", width: "100%", height: "100%" }}>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/pages" component={Wrapper} /> */}
          {/* <Route exact path="/MachineDetailPage" component={MachineDetailPage} /> */}
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/" component={LoginPage} />
          <Route path="*" component={Wrapper} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
