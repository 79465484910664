import React, { useEffect, useState } from "react";
import "./MachineItem.css";
import { Col, Row, Tooltip } from "antd";
import { Avatar } from "antd";
import GaugeChart from "react-gauge-chart";

import { UserOutlined } from "@ant-design/icons";
import { MACHINE_IMAGE_BASE_URL } from "../../../../services/common/urls";

import OneGantt from "./OneGantt";
import "./glowEffect.scss";
import { sizePad, sizeMobile } from "../../../../services/common/constants";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
import VariablesWidget from "./VariablesWidget/VariablesWidget";

function MachineItem(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { machineInfo, operatorList, statusList, screenSize } = props;

  const { customer_id } = props;
  const { customerInfo } = props;
  const { onClickMachine } = props;
  const { hstList } = props;
  const { ganttList } = props;
  let hstInfo = undefined;
  let ganttInfo = undefined;
  let operatorName = lang(langIndex, "cnc_guest");
  let ledClassName = "col-000000";
  let operatorImage = "";
  let statusName = lang(langIndex, "cnc_offline");
  if (hstList.length > 0) {
    hstInfo = hstList.find(
      (hst) => hst["machine_id"] == machineInfo["machine_id"]
    );
  }

  if (ganttList != undefined && ganttList.length > 0) {
    ganttInfo = ganttList.filter(
      (gantt) => gantt["machine_id"] == machineInfo["machine_id"]
    );

    if (
      ganttInfo != undefined &&
      ganttInfo.length > 0 &&
      ganttInfo[ganttInfo.length - 1] != null
    ) {
      // console.log(ganttInfo[ganttInfo.length - 1]);
      var color = ganttInfo[ganttInfo.length - 1]["color"];
      ledClassName = "col-" + color.replace("#", "");
      statusName = ganttInfo[ganttInfo.length - 1]["status"];
    }
  }

  // read status data and set status of machine and operator name and operator photo
  if (statusList.length > 0) {
    try {
      var tmp = statusList.filter(
        (x) =>
          x.machine_id.toLowerCase().trim() ==
          machineInfo["machine_id"].toLowerCase().trim()
      );

      if (tmp.length > 0) {
        var tmpStatus = tmp[0];
        // console.log("tmpStatus", tmpStatus)
        // console.log("operatorList", operatorList)
        if (tmpStatus.status.toLowerCase().trim() == "offline") {
          statusName = lang(langIndex, "cnc_offline");
          ledClassName = "col-000000";
        }
        if (operatorList.length > 0) {
          var tmpOperator = operatorList.filter(
            (x) =>
              x.username_full.toLowerCase().trim() ==
              tmpStatus["Operator"].toLowerCase().trim()
          );
          if (tmpOperator.length > 0) {
            operatorImage = tmpOperator[0].user_picture;
            operatorName = tmpOperator[0].username_full;
          }
        }
      }
    } catch (err) {}
  }

  // compare last gantt and current time, and set the status of machine
  if (ganttInfo != undefined && ganttInfo.length > 0) {
    // get last gantt data
    var lastGanttTime = ganttInfo[ganttInfo.length - 1].end;
    var currentTime = Math.round(new Date().getTime() / 1000);
    // console.log(currentTime, lastGanttTime, currentTime - lastGanttTime)
    if (currentTime - lastGanttTime > 60 * 5) {
      statusName = lang(langIndex, "cnc_offline");
      ledClassName = "col-000000";
    }
  }

  // console.log(operatorImage);
  return screenSize.width >= sizePad ? ( // desktop Version
    <Col span={24}>
      <div
        className={"machine-item-style border-" + ledClassName}
        style={{ margin: 20 }}
      >
        <Row align="middle">
          <Col span={9}>
            <Row align={"middle"} onClick={() => onClickMachine(machineInfo)}>
              <Col span={8}>
                <div>
                  <img
                    className="machine-image"
                    src={
                      machineInfo["machine_picture_url"].includes("http")
                        ? machineInfo["machine_picture_url"]
                        : MACHINE_IMAGE_BASE_URL +
                          machineInfo["machine_picture_url"]
                    }
                  />
                </div>
              </Col>
              <Col span={8}>
                <h3 style={{ color: "white", textAlign: "center" }}>
                  {machineInfo["machine_id"]}
                </h3>
              </Col>
              <Col span={8}>
                <div style={{ textAlign: "center" }}>
                  {operatorImage == "" ? (
                    <Avatar size={96} icon={<UserOutlined />} />
                  ) : (
                    <img
                      className="main-operator-image-style"
                      style={{ width: 96, height: 96 }}
                      src={operatorImage}
                    />
                  )}
                  <h3 style={{ color: "white", textAlign: "center" }}>
                    {operatorName}
                  </h3>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={3}>
            <Tooltip
              title={lang(langIndex, "cnc_click_to_check_other_variable")}
            >
              <div style={{ width: "100%" }}>
                <VariablesWidget
                  machineInfo={machineInfo}
                  hstInfo={hstInfo}
                  langIndex={langIndex}
                  isMobile={false}
                />
              </div>
            </Tooltip>
          </Col>
          <Col span={9}>
            <OneGantt
              ganttInfo={ganttInfo}
              machineInfo={machineInfo}
              customerInfo={customerInfo}
            />
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            {
              <div style={{ textAlign: "center" }}>
                <div className="main-machine-status-led-container-style">
                  <a className={"led-one-line-style " + ledClassName}>
                    &#8226;
                  </a>
                </div>
                <div>
                  <h3 className="main-machine-status-style">{statusName}</h3>
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    </Col>
  ) : screenSize.width >= sizeMobile ? (
    // iPad Version
    <Col span={24}>
      <div
        className={"machine-item-style border-" + ledClassName}
        style={{ margin: 10 }}
      >
        <Row align="middle" onClick={() => onClickMachine(machineInfo)}>
          <Col span={3}>
            <div>
              <img
                className="machine-image"
                src={
                  machineInfo["machine_picture_url"].includes("http")
                    ? machineInfo["machine_picture_url"]
                    : MACHINE_IMAGE_BASE_URL +
                      machineInfo["machine_picture_url"]
                }
              />
            </div>
          </Col>
          <Col span={3}>
            <h4 style={{ color: "white", textAlign: "center" }}>
              {machineInfo["machine_id"]}
            </h4>
          </Col>
          <Col span={3}>
            <div style={{ textAlign: "center" }}>
              {operatorImage == "" ? (
                <Avatar icon={<UserOutlined />} />
              ) : (
                <img
                  style={{ width: 60, height: 60 }}
                  className="main-operator-image-style"
                  src={operatorImage}
                />
              )}
              <h4 style={{ color: "white", textAlign: "center" }}>
                {operatorName}
              </h4>
            </div>
          </Col>
          <Col span={4}>
            <div style={{ width: "100%" }}>
              <VariablesWidget
                machineInfo={machineInfo}
                hstInfo={hstInfo}
                langIndex={langIndex}
                isMobile={false}
              />
            </div>
          </Col>
          <Col span={9}>
            <OneGantt
              ganttInfo={ganttInfo}
              machineInfo={machineInfo}
              customerInfo={customerInfo}
              screenSize={screenSize}
            />
          </Col>
          <Col span={2} style={{ textAlign: "center" }}>
            {
              <div style={{ textAlign: "center" }}>
                <div className="main-machine-status-led-container-style">
                  <a
                    style={{ fontSize: 50 }}
                    className={"led-one-line-style " + ledClassName}
                  >
                    &#8226;
                  </a>
                </div>
                <div>
                  <h5
                    className="main-machine-status-style"
                    style={{ fontWeight: "normal" }}
                  >
                    {statusName}
                  </h5>
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    </Col>
  ) : (
    // Mobile Version
    <Col span={24}>
      <div
        className={"machine-item-style border-" + ledClassName}
        style={{ margin: 5 }}
      >
        <Row align="middle" onClick={() => onClickMachine(machineInfo)}>
          <Col span={4}>
            <div>
              <img
                className="machine-image"
                src={
                  machineInfo["machine_picture_url"].includes("http")
                    ? machineInfo["machine_picture_url"]
                    : MACHINE_IMAGE_BASE_URL +
                      machineInfo["machine_picture_url"]
                }
              />
            </div>
          </Col>
          <Col span={4}>
            <h5 style={{ color: "white", textAlign: "center" }}>
              {machineInfo["machine_id"]}
            </h5>
          </Col>
          <Col span={5}>
            <div style={{ textAlign: "center" }}>
              {operatorImage == "" ? (
                <Avatar icon={<UserOutlined />} />
              ) : (
                <img
                  style={{ width: 48, height: 48 }}
                  className="main-operator-image-style"
                  src={operatorImage}
                />
              )}
              <h5 style={{ color: "white", textAlign: "center" }}>
                {operatorName}
              </h5>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ width: "100%" }}>
              <VariablesWidget
                machineInfo={machineInfo}
                hstInfo={hstInfo}
                langIndex={langIndex}
                isMobile={true}
              />
            </div>
          </Col>
          <Col span={5} style={{ textAlign: "center" }}>
            {
              <div style={{ textAlign: "center" }}>
                <div className="main-machine-status-led-container-style">
                  <a
                    style={{ fontSize: 40 }}
                    className={"led-one-line-style " + ledClassName}
                  >
                    &#8226;
                  </a>
                </div>
                <div>
                  <h5
                    className="main-machine-status-style"
                    style={{ fontWeight: "normal" }}
                  >
                    {statusName}
                  </h5>
                </div>
              </div>
            }
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export default MachineItem;
