import * as actionType from "../actionType";
import Urls, { postRequest } from "../../common/urls";

const initialState = {
  sensorList: {},
  sensorInfo: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.sensorUpdateSensorList:
      var _sensorList = state.sensorList;
      _sensorList[action.data.customerId] = action.data.sensorData;
      return {
        ...state,
        sensorList: {
          ..._sensorList,
        },
      };
    case actionType.sensorUpdateSensorInfo:
      var _sensorInfo = state.sensorInfo;
      _sensorInfo[action.data.customerId] = { ...action.data.sensorInfo };
      return {
        ...state,
        sensorInfo: {
          ..._sensorInfo,
        },
      };
      break;
    case actionType.sensorAddNewSensor:
      var _sensorList = state.sensorList;
      _sensorList[action.data.customerId] = [
        ..._sensorList[action.data.customerId],
        action.data.sensorInfo,
      ];
      // console.log(_sensorList[action.data.customerId]);
      return {
        ...state,
        sensorList: {
          ..._sensorList,
        },
      };
      break;
    case actionType.sensorUpdateSensor:
      var _sensorList = state.sensorList;
      console.log("old: ", state.sensorList);
      var _customerSensorList = _sensorList[action.data.customerId];
      try {
        var _selectedSensor = _customerSensorList.filter(
          (x) => x.sensor_id == action.data.sensorInfo.sensor_id
        )[0];
        _selectedSensor = { ...action.data.sensorInfo };

        _customerSensorList = [
          ..._customerSensorList.filter(
            (x) => x.sensor_id != action.data.sensorInfo.sensor_id
          ),
          { ..._selectedSensor },
        ];

        _sensorList[action.data.customerId] = [..._customerSensorList];

        console.log("updated: ", _sensorList);
        return {
          ...state,
          sensorList: {
            ..._sensorList,
          },
        };
      } catch (err) {
        console.log("there's no registered sensor");
      }
      break;
    case actionType.sensorDeleteSensor:
      var _sensorList = state.sensorList;
      console.log("old: ", state.sensorList);
      var _customerSensorList = _sensorList[action.data.customerId];
      try {
        _customerSensorList = [
          ..._customerSensorList.filter(
            (x) => x.sensor_id != action.data.sensorId
          ),
        ];

        _sensorList[action.data.customerId] = [..._customerSensorList];

        console.log("deleted: ", _sensorList);
        return {
          ...state,
          sensorList: {
            ..._sensorList,
          },
        };
      } catch (err) {
        console.log("there's no registered sensor");
      }
      break;
    default:
      return state;
  }
};

export default reducer;

export function getSensorList(customerId, dispatch, callback) {
  const url = Urls.SENSOR_GET_LIST;
  postRequest(url, { customer_id: customerId }, (res) => {
    if (res.status == true) {
      callback(true);
      dispatch({
        type: actionType.sensorUpdateSensorList,
        data: {
          customerId: customerId,
          sensorData: res.data,
        },
      });
    }
  });
}

export function getSensorInfo(customerId, sensorIdList, dispatch) {
  const url = Urls.SENSOR_GET_INFO_LIST;
  const param = { idList: sensorIdList };
  postRequest(url, param, (res) => {
    if (res.status == true) {
      dispatch({
        type: actionType.sensorUpdateSensorInfo,
        data: {
          customerId: customerId,
          sensorInfo: res.data,
        },
      });
    }
  });
}
export function addSensor(customerId, sensorInfo, dispatch, callback) {
  const url = Urls.SENSOR_ADD_SENSOR;

  postRequest(url, { ...sensorInfo, customer_id: customerId }, (res) => {
    // console.log(res);
    if (res.status == true) {
      callback({
        status: true,
        message: `${sensorInfo.sensor_name} has successfully been added.`,
      });

      dispatch({
        type: actionType.sensorAddNewSensor,
        data: {
          customerId: customerId,
          sensorInfo: sensorInfo,
        },
      });
    } else {
      callback({
        status: false,
        message: `${res.message}`,
      });
    }
  });
}
export function updateSensorInfo(
  customerId,
  newSensorInfo,
  dispatch,
  callback
) {
  const url = Urls.SENSOR_UPDATE_SENSOR;
  console.log({ ...newSensorInfo, customer_id: customerId });
  postRequest(url, { ...newSensorInfo, customer_id: customerId }, (res) => {
    if (res.status == true) {
      callback(true);
      dispatch({
        type: actionType.sensorUpdateSensor,
        data: {
          customerId: customerId,
          sensorInfo: newSensorInfo,
        },
      });
    } else {
      callback(false);
      console.log("Error", res.message);
    }
  });
}

export function deleteSensor(customerId, sensorId, dispatch) {
  const url = Urls.SENSOR_DELETE_SENSOR;
  console.log({ customer_id: customerId, sensor_id: sensorId });
  postRequest(url, { customer_id: customerId, sensor_id: sensorId }, (res) => {
    if (res.status == true) {
      dispatch({
        type: actionType.sensorDeleteSensor,
        data: {
          customerId: customerId,
          sensorId: sensorId,
        },
      });
    } else {
      console.log("Error", res.message);
    }
  });
}
