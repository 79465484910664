import { combineReducers } from "redux";
import app from "./reducers/app";
import authService from "./reducers/auth_service";
import cncService from "./reducers/cnc_service";
import sensorService from "./reducers/sensor_service";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore } from "redux";

const rootReducer = combineReducers({
  app: app,
  authService: authService,
  sensorService: sensorService,
  cncService: cncService,
});

// applying persist redux
const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["sensorService", "cncService"],
};

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = createStore(persistedReducer, devTools);
export const persistor = persistStore(store);

export default () => {
  let store = store;
  let persistor = persistor;
  return { store, persistor };
};

// export default combineReducers({
//   app: app,
//   authService: authService,
//   sensorService: sensorService,
//   cncService: cncService,
// });
