import React , { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import logo_collapsed from "../../assets/images/logo_collapsed.png";
import logo_expanded from "../../assets/images/logo_expanded.png";
import {
  DashboardOutlined,
  MailOutlined,
  AlertOutlined,
  ClusterOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  Drawer,
  Button,
  Layout,
  Menu,
  Divider,
  Slider,
  Switch,
  Row,
} from "antd";
import { onSelectMenu } from "../../services/redux/reducers/app";
import {
  MENU_DIVIDER,
  PAGETYPE,
  menuReport,
  sizePad,
} from "../../services/common/constants";
import lang from "../../services/lang";
import { withRouter } from "react-router";

import FeatureModal from "./FeatureModal/FeatureModal";

const { Sider } = Layout;
function MySidebar(props) {
  const dispatch = useDispatch();
  const appData = useSelector((x) => x.app);
  const { langIndex } = appData;
  const { isMenuCollapsed, setIsMenuCollapsed, screenSize } = props;
  const { security_level } = props;
  const { customerIdList } = props;
  const { customerInfoList } = props;
  const {
    autoScroll,
    setAutoScroll,
    autoScrollSpeed,
    setAutoScrollSpeed,
    isWaitingToTop,
  } = props;

  const [isShowFeatureModal, setIsShowFeatureModal] = useState(false); 
  const menuList = customerIdList.sort().map((customer_id) => {
    // console.log("customerInfoList", customerInfoList);
    const customerInfo = customerInfoList[customer_id];
    const groupInfo = customerInfo["groupInfo"];

    const subMenu = groupInfo.map((group) => {
      return (
        <Menu.Item
          key={
            PAGETYPE.cnc + MENU_DIVIDER + customer_id + MENU_DIVIDER + group.id
          }
        >
          {group.name}
        </Menu.Item>
      );
    });
    return (
      <SubMenu
        key={PAGETYPE.submenu + MENU_DIVIDER + customer_id}
        icon={<DashboardOutlined />}
        title={customerInfoList[customer_id].name}
      >
        {/* <Menu.Item
          key={PAGETYPE.cnc + MENU_DIVIDER + customer_id}
          icon={<DashboardOutlined />}
        >
          {lang(langIndex, "sidebar_menu_dashboard")}
        </Menu.Item> */}

        <SubMenu
          key={PAGETYPE.cnc + MENU_DIVIDER + customer_id}
          icon={<DashboardOutlined />}
          // title={lang(langIndex, "sidebar_menu_dashboard")}
          title={"CNC"}
        >
          <Menu.Item
            key={
              PAGETYPE.cnc + MENU_DIVIDER + customer_id + MENU_DIVIDER + "all"
            }
          >
            {lang(langIndex, "All")}
          </Menu.Item>
          {subMenu}
        </SubMenu>

        <Menu.Item
          key={PAGETYPE.tms + MENU_DIVIDER + customer_id}
          icon={<ClusterOutlined />}
        >
          TMS
        </Menu.Item>
        <Menu.Item
          key={PAGETYPE.iiot + MENU_DIVIDER + customer_id}
          icon={<AlertOutlined />}
        >
          IIot
        </Menu.Item>
        {security_level == 4 && (
          <Menu.Item
            key={PAGETYPE.cnc_maintenance + MENU_DIVIDER + customer_id}
            icon={<ApiOutlined />}
          >
            {lang(langIndex, "sidebar_menu_maintanence")}
          </Menu.Item>
        )}
      </SubMenu>
    );
  });

  const onCustomerClicked = (item) => {
    var keyList = item.key.split(MENU_DIVIDER);

    onSelectMenu(keyList[1], keyList[0], keyList[2], dispatch);
    if (appData.isAutoScroll == true) setAutoScroll(false);

    // console.log("Sidebar props", props);
    // console.log("Wrapper props", props);
    if (keyList[0] == PAGETYPE.tms && appData.page_type != PAGETYPE.tms) {
      props.history.push("/pages/tms");
    }
    if (keyList[0] == PAGETYPE.iiot && appData.page_type != PAGETYPE.iiot) {
      props.history.push("/pages/iiot");
    }
    if (keyList[0] == PAGETYPE.cnc && appData.page_type != PAGETYPE.cnc) {
      props.history.push("/pages/machine");
    }
    if (
      keyList[0] == PAGETYPE.cnc_maintenance &&
      appData.page_type != PAGETYPE.cnc_maintenance
    ) {
      props.history.push("/pages/maintenance");
    }

    if (screenSize.width < sizePad) setIsMenuCollapsed(true);
  };

  return screenSize.width >= sizePad ? (
    // desktop version
    <Sider
      collapsible
      collapsed={isMenuCollapsed}
      onCollapse={() => setIsMenuCollapsed(!isMenuCollapsed)}
      className={isMenuCollapsed ? "menu-collapsed-style" : ""}
    >
      <div
        className="logo"
        style={{ height: 120, textAlign: "center", paddingTop: 40 }}
      >
        <FeatureModal
          isShowFeatureModal={isShowFeatureModal}
          setIsShowFeatureModal={setIsShowFeatureModal}
          customerInfoList={customerInfoList}
          customerIdList={customerIdList}
        />

        <img
          className="logo-image"
          alt="logo"
          src={isMenuCollapsed ? logo_collapsed : logo_expanded}
          style={{ maxHeight: 72, width: isMenuCollapsed ? 50 : 90 }}
        />
        {isMenuCollapsed ? (
          <h4 style={{ marginTop: 10, color: "white" }}></h4>
        ) : (
          <h3 style={{ marginTop: 10, color: "white" }}></h3>
        )}
      </div>
      <div
        className="content-scrollbar-style menu-container"
        style={{ maxHeight: screenSize.height - 300 }}
      >
        <Menu
          theme="dark"
          defaultSelectedKeys={[
            appData.page_type == PAGETYPE.cnc
              ? appData.page_type +
                MENU_DIVIDER +
                appData.customer_id +
                MENU_DIVIDER +
                appData.group_id
              : appData.page_type + MENU_DIVIDER + appData.customer_id,
            ,
          ]}
          defaultOpenKeys={[
            PAGETYPE.submenu + MENU_DIVIDER + appData.customer_id,
            appData.page_type + MENU_DIVIDER + appData.customer_id,
          ]}
          onClick={onCustomerClicked}
          mode="inline"
        >
          {menuList}
        </Menu>
      </div>
      <Divider />
      <div className="content-scrolling-controller-style">
        {isMenuCollapsed ? (
          <Row>
            <MailOutlined
              className="feature_request_collapsed"
              onClick={() => setIsShowFeatureModal(true)}
            />
          </Row>
        ) : (
          <Row>
            <Button
              className="feature_request"
              type="ghost"
              icon={<MailOutlined />}
              onClick={() => setIsShowFeatureModal(true)}
            >
              {lang(langIndex, "feature_Request_Form")}
            </Button>
          </Row>
        )}

        <span>{lang(langIndex, "sidebar_auto_scroll")}</span>
        <Switch
          className="auto-scroll-switch-desktop-style"
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={setAutoScroll}
          checked={autoScroll}
          disabled={isWaitingToTop}
        />
        <h2>{lang(langIndex, "sidebar_scroll_speed")}</h2>
        <Slider
          defaultValue={autoScrollSpeed}
          disabled={!autoScroll || isWaitingToTop}
          onAfterChange={setAutoScrollSpeed}
        />
      </div>
    </Sider>
  ) : (
    // mobile version
    <Drawer
      title={
        <div
          className="logo"
          style={{ height: 120, textAlign: "center", paddingTop: 40 }}
        >
          <img
            className="logo-image"
            alt="logo"
            src={isMenuCollapsed ? logo_collapsed : logo_expanded}
            style={{ maxHeight: 72, width: isMenuCollapsed ? 50 : 90 }}
          />
          <h3 style={{ marginTop: 10, color: "white" }}></h3>
        </div>
      }
      closable={true}
      onClose={() => setIsMenuCollapsed(true)}
      visible={!isMenuCollapsed}
      className={"menu-collapsed-style"}
      placement={"left"}
    >
      <Menu
        theme="dark"
        // selectedKeys={[appData.page_type + MENU_DIVIDER + appData.customer_id]}
        defaultSelectedKeys={[
          appData.page_type == PAGETYPE.cnc
            ? appData.page_type +
              MENU_DIVIDER +
              appData.customer_id +
              MENU_DIVIDER +
              appData.group_id
            : appData.page_type + MENU_DIVIDER + appData.customer_id,
        ]}
        defaultOpenKeys={[
          PAGETYPE.submenu + MENU_DIVIDER + appData.customer_id,
          appData.page_type + MENU_DIVIDER + appData.customer_id,
        ]}
        onClick={onCustomerClicked}
        mode="inline"
      >
        {menuList}
      </Menu>
      <Divider />
      <div className="content-scrolling-controller-style">
        <Row>
          <Button
            className="feature_request"
            type="ghost"
            icon={<MailOutlined />}
            onClick={() => setIsShowFeatureModal(true)}
          >
            {lang(langIndex, "feature_Request_Form")}
          </Button>
        </Row>
        <label style={{ color: "#eeeeee" }} htmlFor={"switch-autoscroll"}>
          {lang(langIndex, "sidebar_auto_scroll")}
        </label>
        <Switch
          width={20}
          name="switch-autoscroll"
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={setAutoScroll}
          checked={autoScroll}
          disabled={isWaitingToTop}
        />
        <h2>{lang(langIndex, "sidebar_scroll_speed")}</h2>
        <Slider
          defaultValue={autoScrollSpeed}
          disabled={!autoScroll || isWaitingToTop}
          onAfterChange={setAutoScrollSpeed}
        />
      </div>
    </Drawer>
  );
}

export default withRouter(MySidebar);
