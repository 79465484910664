import Urls, { postRequest } from './urls'
export function getDetailedSensorData(sensorId, currentDate, callback) {
    const url = Urls.SENSOR_GET_SENSOR_DATA;
    const param = {
        sensor_id: sensorId,
        read_date: currentDate,
    };
    postRequest(url, param, res => {
        if (res.status == true) {
            callback(res.data);
        } else {
            callback(null);
        }
    })
}
export function getDetailedSensorDataWithFromTo(sensorId, fromDate, toDate, callback) {
    const url = Urls.SENSOR_GET_SENSOR_DATA;
    const param = {
        sensor_id: sensorId,
        from_date: fromDate,
        to_date: toDate,
    };
    postRequest(url, param, res => {
        if (res == null) {
            callback(null);
        } else {
            if (res.status == true) {
                callback(res.data);
            } else {
                callback(null);
            }
        }
    })
}
export function getEnumKeyByValue(Enum, val) {
    return Object.keys(Enum).find(
        key => Enum[key] == val
    )
}