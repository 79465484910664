import React, { useState, useEffect } from "react";
import { message } from "antd";
import "./UserSettings.css";
import UserTable from "./UserTable/UserTable";

import UserManageDialog from "./UserManageDialog/UserManageDialog";
import {
  GetUserList,
  ExecuteQuery,
  AddUser,
  UpdateUser,
  DeleteUser,
} from "../../../../services/common/cnc_functions";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
function UserSettings(props) {
  const { langIndex } = useSelector((state) => state.app);
  const { selCustomerId, customerInfo } = props;
  const [isShowModal, setIsShowModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [initUserInfo, setInitUserInfo] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  // console.log(selCustomerId);
  useEffect(() => {
    GetUserList(selCustomerId, (userList) => {
      if (userList != null || userList.length > 0) {
        userList.forEach(function (entry) {
          entry.key = entry.id;
        });
        setUserList(userList);
      } else {
      }
    });
  }, []);

  const deleteUser = (id) => {
    const newData = userList.filter((element) => element.id != id);
    setUserList(newData);
    DeleteUser(id,(res) => {
      if (res.status == true) {
        console.log("this ran");
  };
});
  }
  
  const onClickEditRecord = (record) => {
    setInitUserInfo(record);
    setIsShowModal(true);
  };
  const onClickAddUser = () => {
    setInitUserInfo(null);
    setIsShowModal(true);
  };
  const addUser = (info) => {
    info["customer_id"] = selCustomerId;

    AddUser(info, (res) => {
      if (res.status == true) {
        var newItem = { ...info, key: info.id };
        setUserList([...userList, newItem]);
        message.success(lang(langIndex, "msg_update_success"));
        setIsShowModal(false);
      } else {
        message.error(lang(langIndex, "msg_something_wrong"));
      }
    });
  };
  const editUser = (info) => {
    console.log(info);
    const newData = [...userList];
    const index = newData.findIndex((item) => item.key === info.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...info });


      const newItem = newData[index];
      var data = newData[index];
      data = { ...data,customer_id: selCustomerId, security_level: newItem.security_level};
      //id: newItem.id, username: newItem.username, password: newItem.password, username_full: newItem.username_full, user_picture: newItem.user_picture, security_level: newItem.security_level};
      console.log(newItem.security_level);
      UpdateUser(data, (res) => {
        if (res.status == true) {
          
          
          message.success(lang(langIndex, "msg_update_success"));
          setUserList(newData);
      setIsShowModal(false);
    } else {
      message.error(lang(langIndex, "msg_something_wrong"));
    }

    });
  }
}

  

  return (
    <div>
      {isShowModal === true && (
        <UserManageDialog
          initUserInfo={initUserInfo}
          addUser={addUser}
          editUser={editUser}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
        />
      )}
      <div className="user-setting-title-container-style">
        <h1
          className="user-setting-page-title"
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ display: "inline-block", marginRight: 50 }}
        >
          {lang(langIndex, "plant_usersettings")}
        </h1>
        <a
          style={{ display: isCollapsed ? "none" : "inline-block" }}
          onClick={onClickAddUser}
        >
          + {lang(langIndex, "plant_adduser")}
        </a>
      </div>
      <div
        style={{ display: isCollapsed ? "none" : "block" }}
        className="user-setting-user-table-style"
      >
        <UserTable
          userList={userList}
          deleteUser={deleteUser}
          onClickEditRecord={onClickEditRecord}
        />
      </div>
    </div>
  );
    }

export default UserSettings;
