import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import "./GeneratePDF.css";
import PDFPreviewer from "./PDFPreviewer/PDFPreviewer";
import {
  GetReportPeriodData,
} from "../../../../../services/common/cnc_functions";
import { useSelector } from "react-redux";
import lang from "../../../../../services/lang";
function GeneratePDF(props) {
  const { langIndex } = useSelector((x) => x.app);
  const {
    customer_id,
    fromReportDate,
    toReportDate,
    selectedEmails,
    selectedMachine,
    selectedOperator,
    selectedJob,
    customerInfo,
    machineInfo,
  } = props;

  const { isGeneratingPDF, setIsGeneratingPDF } = props;
  const [showModal, setShowModal] = useState(false);
  const [pdfData, setPdfData] = useState({});
  const onGeneratePDF = () => {
    setIsGeneratingPDF(true);

    GetReportPeriodData(
      customer_id,
      selectedMachine,
      customerInfo["timezone"],
      fromReportDate,
      toReportDate,
      (res) => {
        var oee = res.data["oee"];
        setIsGeneratingPDF(false);
        if (res.status == false) {
          message.error(lang(langIndex, "msg_something_wrong"));
        } else {
          if (oee.length == 0) { // Checks for valid data and returns an error if there isn't
            message.error(lang(langIndex, "msg_no_data_print"));
            return;
          } else if (selectedOperator != "" || selectedJob != "") { // Checks if the user selected a job or op and if not returns just the machine data with no filters
            if (selectedJob != "" && selectedOperator != "") { // Runs if user chose both a op and a job
              var opInfo = getOperatorData(oee); // Runs method to return all the entries with the specified operators
              res.data["oee"] = getJobData(opInfo); // Runs method to return all the entries with the specificed Jobs. Uses the array from the previous function as params
            } else if (selectedOperator != "") { // If customer selected an operator it will call the function and return those entries with the operator
              res.data["oee"] = getOperatorData(oee);
            } else if (selectedJob != "") { // If the customer selected any jobs it will call the function and return those entries with those jobs
              res.data["oee"] = getJobData(oee);
            }
          }
          if (res.data["oee"] == 0) {
            message.error(lang(langIndex, "msg_no_data_print"));
            return;
          }

          try {
            setPdfData({ ...res.data });
            // If there is data to report then the CSV export will begin
          } catch (error) {
            message.error(
              "No Data Found For Selected Variables. Try Again With Different Filters"
            );
            //setShowModal(false);
            return;
          }
          setShowModal(true);
        }
      }
    );
  };
  const getOperatorData = (data) => {
    var oee = data;
    for (var i = 0; i < oee.length; i++) {
      var ops = "";

      for (var o = 0; o < selectedOperator.length; o++) {
        if (oee[i].Operator.includes(selectedOperator[o])) {
          //|| !selectedOperator.includes(oee[i].Operator)\
          if (ops == "") {
            ops += selectedOperator[o];
          } else {
            ops += ", " + selectedOperator[o];
          }
        }
      }
      if (oee.length == 0) {
        message.error(lang(langIndex, "msg_no_data_print"));
        return;
      }

      if (ops == "") {
        oee.splice(i, 1);
        i -= 1;
      } else {
        oee[i].Operator = ops;
      }
    }
    return oee;
  };

  const getJobData = (data) => {
    var oee = data;
    for (var i = 0; i < oee.length; i++) {
      var jobs = "";
      for (var j = 0; j < selectedJob.length; j++) {
        if (oee[i].orders.includes(selectedJob[j])) {
          //|| !selectedOperator.includes(oee[i].Operator)\
          if (jobs == "") {
            jobs += selectedJob[j];
          } else {
            jobs += ", " + selectedJob[j];
          }
        }
      }

      if (jobs == "") {
        oee.splice(i, 1);
        i -= 1;
      } else {
        oee[i].orders = jobs;
      }
    }
    return oee;
  };

  const handleOk = (e) => {
    setShowModal(false);
  };

  return (
    <div>
      {!showModal ? null : (
        <Modal
          title={null}
          footer={null}
          visible={showModal}
          onOk={handleOk}
          onCancel={handleOk}
          destroyOnClose={true}
          // bodyStyle={{ height: "25.7cm" }}
          width="21cm"
        >
          <PDFPreviewer
            pdfData={pdfData}
            customerInfo={customerInfo}
            machineInfo={machineInfo.length > 0 ? machineInfo[0] : undefined}
            setShowModal={setShowModal}
            selectedEmails={selectedEmails}
          />
        </Modal>
      )}

      <Button
        type="primary"
        disabled={selectedMachine.length != 1}
        loading={isGeneratingPDF}
        onClick={onGeneratePDF}
      >
        {lang(langIndex, "report_generatepdf")}
      </Button>
    </div>
  );
}

export default GeneratePDF;
