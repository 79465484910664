import React, { useState, useEffect } from "react";

import "./jobEntryTable.css";
import {
  Upload,
  message,
  DatePicker,
  Row,
  Col,
  Divider,
  Table,
  InputNumber,
  Input,
  Form,
  Popconfirm,
  Button,
  Space
} from "antd";



import JobAddModal from "../jobAddModal/jobAddModal";
import JobFilesModal from "../jobFilesModel/jobFilesModal";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
import Urls, { BASE_URL, postRequest } from "../../../../services/common/urls";

const { Search } = Input;
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={
            inputType === "datetime"
              ? [
                {
                  pattern:
                    "[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}",
                  required: true,
                  message: "YYYY-MM-DD HH:mm:ss ex:)2020-08-20 15:51:23",
                },
              ]
              : []
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function JobEntryTable(props) {
  const { langIndex } = useSelector((state) => state.app);
  const { entryList, updateEntry, addEntry, isBusy, setIsBusy, deleteEntry, selCustomerId, setSearchKey, setSearchDueDate } = props;
  const { security_level } = props;
  const [form] = Form.useForm();
  const [data, setData] = useState(entryList);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowFiles, setIsShowFiles] = useState(false);
  const [files, setFiles] = useState("");
  const [Id, setId] = useState("");

  const columns = security_level == 0 ? [
    {
      title: lang(langIndex, "jobentry_id"),
      dataIndex: "Id",
      key: "Id"
    },
    {
      title: lang(langIndex, "jobentry_jobid"),
      dataIndex: "jobID",
      key: "jobID",
      filterSearch: true
    },
    {
      title: lang(langIndex, "jobentry_customerid"),
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: lang(langIndex, "jobentry_partnumber"),
      dataIndex: "partNumber",
      key: "partNumber",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_programnumber"),
      dataIndex: "programNumber",
      key: "programNumber",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_description"),
      dataIndex: "description",
      key: "description",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_partspercycle"),
      dataIndex: "partsPerCycle",
      key: "partsPerCycle",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_targetcycletime"),
      dataIndex: "targetCycleTime",
      key: "targetCycleTime",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_qtyrequired"),
      dataIndex: "qtyRequired",
      key: "qtyRequired",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_qtycompleted"),
      dataIndex: "qtyCompleted",
      key: "qtyCompleted",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_orderdate"),
      dataIndex: "orderDate",
      key: "orderDate",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_duedate"),
      dataIndex: "dueDate",
      key: "dueDate",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_aux1data"),
      dataIndex: "aux1data",
      key: "aux1data",
      editable: true,
    },

    {
      title: lang(langIndex, "jobentry_aux2data"),
      dataIndex: "aux2data",
      key: "aux2data",
      editable: true,
    },

    {
      title: lang(langIndex, "jobentry_aux3data"),
      dataIndex: "aux3data",
      key: "aux3data",
      editable: true,
    },
  ] : [
    { title: lang(langIndex, "jobentry_id"), dataIndex: "Id", key: "Id" },
    { title: lang(langIndex, "jobentry_jobid"), dataIndex: "jobID", key: "jobID" },
    {
      title: lang(langIndex, "jobentry_customerid"),
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: lang(langIndex, "jobentry_partnumber"),
      dataIndex: "partNumber",
      key: "partNumber",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_programnumber"),
      dataIndex: "programNumber",
      key: "programNumber",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_description"),
      dataIndex: "description",
      key: "description",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_partspercycle"),
      dataIndex: "partsPerCycle",
      key: "partsPerCycle",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_targetcycletime"),
      dataIndex: "targetCycleTime",
      key: "targetCycleTime",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_qtyrequired"),
      dataIndex: "qtyRequired",
      key: "qtyRequired",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_qtycompleted"),
      dataIndex: "qtyCompleted",
      key: "qtyCompleted",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_orderdate"),
      dataIndex: "orderDate",
      key: "orderDate",
      editable: true,
    },
    {
      title: lang(langIndex, "jobentry_duedate"),
      dataIndex: "dueDate",
      key: "dueDate",
      editable: true,
    },

    {
      title: lang(langIndex, "jobentry_aux1data"),
      dataIndex: "aux1data",
      key: "aux1data",
      editable: true,
    },

    {
      title: lang(langIndex, "jobentry_aux2data"),
      dataIndex: "aux2data",
      key: "aux2data",
      editable: true,
    },

    {
      title: lang(langIndex, "jobentry_aux3data"),
      dataIndex: "aux3data",
      key: "aux3data",
      editable: true,
    },

    {
      title: lang(langIndex, "jobentry_operation"),
      dataIndex: "operation",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          <span>
            <a
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              {lang(langIndex, "jobentry_save")}
            </a>
            <Popconfirm
              title={lang(langIndex, "jobentry_suretocancel")}
              onConfirm={cancel}
            >
              <a>{lang(langIndex, "jobentry_cancel")}</a>
            </Popconfirm>
          </span>
        ) : (
          <div>
            <a disabled={editingKey !== ""} onClick={() => edit(record)}>
              {lang(langIndex, "jobentry_edit")}
            </a>

            <a style={{ marginLeft: 10, color: "green" }} onClick={() => onFiles(record)}>
              {lang(langIndex, "files")}
            </a>

            <Popconfirm
              title={lang(langIndex, "jobentry_suretodelete")}
              onConfirm={() => deleteEntry(record)}
            >
              <a
                style={{ marginLeft: 10, color: "red" }}
                disabled={editingKey !== ""}
              >
                {lang(langIndex, "jobentry_delete")}
              </a>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      updateEntry(key, row);
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const onSearchJobId = (e) => {
    setSearchKey(e.target.value)
  }

  const onSearchDueDate = (date, dateString) => {
    setSearchDueDate(dateString)
  }



  const edit = (record) => {
    // console.log(record);
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const onFiles = (record) => {
    if (record.files == undefined)
      setFiles('');
    else
      setFiles(record.files);

    setId(record.Id);
    setIsShowFiles(true);
  };


  const deleteRecord = (record) => {
    // console.log(record);
    deleteEntry(record.Id);
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "targetCycleTime" ||
            col.dataIndex === "qtyRequired" ||
            col.dataIndex === "qtyCompleted" ||
            col.dataIndex === "partsPerCycle"
            ? "number"
            : col.dataIndex === "orderDate" || col.dataIndex === "dueDate"
              ? "datetime"
              : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const onClickAdd = () => {
    setIsShowModal(true);
  };


  const uploadCSV = {
    name: 'file',
    action: Urls.UPLOAD_JOBENTRY_CSV,
    beforeUpload: file => {
      //console.log("fileType:", file.type);
      if (file.type !== 'application/vnd.ms-excel') {
        message.error(`${file.name} is not a csv file`);
      }
      var formData = new FormData();
      formData.append("file", file);
      formData.append("customerID", selCustomerId)
      setIsBusy(true)
      //console.log("FormData:", file)
      postRequest(
        Urls.UPLOAD_JOBENTRY_CSV,
        formData,
        (response) => {
          setIsBusy(false);
          window.location.reload(false);
        }
      );
      return file.type === 'application/vnd.ms-excel' ? true : Upload.LIST_IGNORE;
    },
    onChange: info => {

    },
  };


  return (
    <div>
      {isShowModal && (
        <JobAddModal
          addEntry={addEntry}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
        />
      )}

      {isShowFiles && (
        <JobFilesModal
          files={files}
          setFiles={setFiles}
          addEntry={addEntry}
          updateEntry={updateEntry}
          isShowFiles={isShowFiles}
          Id={Id}
          security_level={security_level}
          selCustomerId={selCustomerId}
          setIsShowFiles={setIsShowFiles}
        />
      )}



      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <Row>
          <Col span={12}>
            <Input
              style={{ width: 150 }}
              placeholder="Search JobId"
              size="middle "
              onChange={onSearchJobId}
            />
            <DatePicker  placeholder="Search DueDate" style={{ marginLeft: 15 }} onChange={onSearchDueDate} />
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {security_level != 0 && (
              <Button
                loading={isBusy}
                ghost
                onClick={onClickAdd}
                type="primary"
              >
                {lang(langIndex, "jobentry_addnew")}
              </Button>
            )}

            {security_level != 0 && (
              <Upload {...uploadCSV}
                style={{
                  marginLeft: 16
                }}>
                <Button ghost type="primary">{lang(langIndex, "jobentry_uploadcsv")}</Button>
              </Upload>
            )}
          </Col>
        </Row>
      </div>

      <Form form={form} component={false}>
        <Table
          className="entry-table-style"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // scroll={{ x: '100%' }}

          dataSource={entryList}
          // columns={columns}
          columns={mergedColumns}
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  );
}

export default JobEntryTable;
