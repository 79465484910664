import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Spin, Image } from "antd";
import {
  UserOutlined,
  VideoCameraOutlined,
  ArrowLeftOutlined,
  SettingOutlined,
  SendOutlined,
  ToolOutlined
} from "@ant-design/icons";

import "./MachineDetailPage.css";
import IndicatorContainer from "./Containers/IndicatorContainer";
import UtilizationContainer from "./Containers/UtilizationContainer";
import TimelineContainer from "./Containers/TimelineContainer";
import HistoryContainer from "./Containers/HistoryContainer";
import DetailGanttModal from "./Containers/DetailGanttModal";
import { MACHINE_IMAGE_BASE_URL } from "../../../services/common/urls";
import EmailSender from "./Containers/EmailSender";
import AppSettingModal from "./AppSettingModal/AppSettingModal";
import InstallConfigModal from "./InstallConfig/InstallConfigModal";
import { sizePad, sizeMobile } from "../../../services/common/constants";
import {
  GetCustomerCurrentTime,
  ConvertTimespanToDateBasedOnTimezone,
  GetTimeWithStyle,
} from "../../../services/common/cnc_functions";
import { useSelector } from "react-redux";
import lang from "../../../services/lang";

function MachineDetailPage(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { onCloseMachine, operatorList, statusList, screenSize , security_level} = props;
  const { customer_id, customerInfo, machineInfo, hstList, ganttList } = props;
  const [currentDate, setCurrentDate] = useState("");
  const [
    isMachineDetailPageContentLoading,
    setIsMachineDetailPageContentLoading,
  ] = useState(false);
  const [showDetailGanttModal, setShowDetailGanttModal] = useState(false);

  const [myHstList, setMyHstList] = useState({});
  const [myGanttList, setMyGanttList] = useState([]);

  const [isShowAppSettingModal, setIsShowAppSettingModal] = useState(false);
  const [isShowInstallConfigModal, setIsShowInstallConfigModal] = useState(false);
  const todayString = lang(langIndex, "cnc_today");

  // console.log(customerInfo)
  // console.log("currentDate", currentDate);
  let hstInfo = {};
  let ganttInfo = [];
  let additionalHstInfo = [];
  let operatorName = lang(langIndex, "cnc_guest");
  let operatorImage = "";
  let app_version = "-";
  // let statusName = "Offline";
  // let currentDate = "";
  if (operatorList.length > 0) {
    try {
      var tmp = operatorList.filter(
        (x) =>
          x.username.toLowerCase().trim() == operatorName.toLowerCase().trim()
      );
      // console.log(operatorName);
      if (tmp.length > 0) {
        operatorImage = tmp[0].user_picture;
      }
    } catch (err) {}
  }

  const openAppSettingDialog = () => {
    setIsShowAppSettingModal(true);
  };

  const openInstallConfigDialog = () => {
    setIsShowInstallConfigModal(true);
  };

  if (currentDate == undefined || currentDate == "") {
    // hstInfo = {};
    // ganttInfo = [];
    // additionalHstInfo = {};
    var useData = extractMachineData(hstList, ganttList);
  } else {
    var useData = extractMachineData(myHstList, myGanttList);
  }

  hstInfo = { ...useData["hstInfo"] };
  ganttInfo = [...useData["ganttInfo"]];
  additionalHstInfo = [...useData["additionalHstInfo"]];

  function extractHstFromGantt(_ganttInfo) {
    var _additionalHst = [];

    for (var i = 0; i < _ganttInfo.length; i++) {
      if (
        _additionalHst.length == 0 ||
        _additionalHst.filter((x) => x.status === _ganttInfo[i].status)
          .length == 0
      ) {
        _additionalHst.push({
          status: _ganttInfo[i].status,
          color: _ganttInfo[i].color,
          duration: parseFloat(
            parseFloat(_ganttInfo[i].end) - parseFloat(_ganttInfo[i].start)
          ),
        });
      } else {
        var _hst = _additionalHst.filter(
          (x) => x.status === _ganttInfo[i].status
        )[0];
        _hst.duration += parseFloat(
          parseFloat(_ganttInfo[i].end) - parseFloat(_ganttInfo[i].start)
        );
      }
    }
    for (var i = 0; i < _additionalHst.length; i++) {
      _additionalHst[i].duration = _additionalHst[i].duration / 3600;
    }

    // calculate offline time
    const timezone = customerInfo["timezone"];
    let startDate = GetCustomerCurrentTime(timezone);
    if (currentDate != undefined && currentDate != "") {
      startDate = new Date(currentDate);
    } else {
      startDate.setHours(0, 0, 0);
    }

    let endDate = GetCustomerCurrentTime(timezone);
    // console.log("customer time", endDate, "timezone", timezone, "currentDate", currentDate);
    if (currentDate != undefined && currentDate != "") {
      endDate = new Date(currentDate);
    } else {
      endDate.setHours(0, 0, 0);
    }

    endDate.setDate(endDate.getDate() + 1);
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);
    if (_ganttInfo.length > 0) {
      // console.log("startTime", ConvertTimespanToDateBasedOnTimezone(_ganttInfo[0].start, timezone));
      // console.log("endTime", ConvertTimespanToDateBasedOnTimezone(_ganttInfo[_ganttInfo.length - 1].end, timezone));

      var offlineTime = 0;

      // calculate sum of duration in _additionalHst
      const onlineTime = _additionalHst
        .map((item) => item.duration)
        .reduce((a, b) => a + b, 0);
      // calculate sum of all day
      let totalTime = 0;
      if (currentDate == undefined || currentDate == "") {
        endDate = ConvertTimespanToDateBasedOnTimezone(
          _ganttInfo[_ganttInfo.length - 1].end,
          timezone
        );
        totalTime = endDate.getTime() - startDate.getTime();
        totalTime = Math.ceil(totalTime / 1000);
        totalTime = totalTime / 3600;
      } else {
        totalTime = 24.0;
      }

      offlineTime = totalTime - onlineTime;

      // console.log("timestamps", startDate.getTime(), endDate.getTime());

      _additionalHst.push({
        status: lang(langIndex, "cnc_offline"),
        color: "#000000",
        duration: offlineTime,
      });
    }
    // ConvertTimespanToDateBasedOnTimezone
    // setAdditionalHstInfo(_additionalHst);
    // console.log(_additionalHst);
    return _additionalHst;
  }

  function extractMachineData(tmpHstList, tmpGanttList) {
    let _hstInfo = tmpHstList.filter(
      (hst) => hst["machine_id"] == machineInfo["machine_id"]
    );
    if (_hstInfo.length > 0) {
      _hstInfo = _hstInfo[0];
    }
    let _ganttInfo = tmpGanttList.filter(
      (gantt) => gantt["machine_id"] == machineInfo["machine_id"]
    );
    return {
      ganttInfo: _ganttInfo,
      hstInfo: _hstInfo,
      additionalHstInfo: extractHstFromGantt(_ganttInfo),
    };
  }

  if (statusList.length > 0) {
    try {
      var tmp = statusList.filter(
        (x) =>
          x.machine_id.toLowerCase().trim() ==
          machineInfo["machine_id"].toLowerCase().trim()
      );

      if (tmp.length > 0) {
        var tmpStatus = tmp[0];
        app_version = tmpStatus.app_version;
        if (operatorList.length > 0) {
          var tmpOperator = operatorList.filter(
            (x) =>
              x.username_full.toLowerCase().trim() ==
              tmpStatus["Operator"].toLowerCase().trim()
          );
          if (tmpOperator.length > 0) {
            operatorImage = tmpOperator[0].user_picture;
            operatorName = tmpOperator[0].username_full;
          }
        }
      }
    } catch (err) {}
  }

  var usingGantt =
    currentDate == undefined || currentDate == "" ? ganttInfo : myGanttList;
  usingGantt = usingGantt.filter((x) => x.machine_id == machineInfo.machine_id);
  var lastGantt =
    usingGantt.length == 0 ? {} : usingGantt[usingGantt.length - 1];
  // console.log("machineInfo", machineInfo);
  // console.log("lastGantt", lastGantt);

  return screenSize.width >= sizeMobile ? (
    // desktop, iPad version
    <div className="machine-detail-container">
      {isShowAppSettingModal && (
        <AppSettingModal
          isShowAppSettingModal={isShowAppSettingModal}
          setIsShowAppSettingModal={setIsShowAppSettingModal}
          customer_id={customer_id}
          security_level={security_level}
          machineInfo={machineInfo}
        />
      )}
      {isShowInstallConfigModal && (
        <InstallConfigModal
          isShowInstallConfigModal={isShowInstallConfigModal}
          setIsShowInstallConfigModal={setIsShowInstallConfigModal}
          customer_id={customer_id}
          security_level={security_level}
          machineInfo={machineInfo}
        />
      )}
      {showDetailGanttModal && (
        <DetailGanttModal
          showDetailGanttModal={showDetailGanttModal}
          setShowDetailGanttModal={setShowDetailGanttModal}
          ganttInfo={ganttInfo}
          machineInfo={machineInfo}
          customerInfo={customerInfo}
          currentDate={currentDate}
          security_level={security_level}
          myGanttList={myGanttList}
          setMyGanttList={setMyGanttList}
          customer_id={customer_id}
        />
      )}
      <Spin spinning={isMachineDetailPageContentLoading} size="large">
        <Row justify="center" style={{ width: "100%" }}>
          <Col className="detail-content-container">
            <Row justify="center" className="content-table" align="top">
              <Col span={18} style={{ textAlign: "left" }}>
                {screenSize.width >= sizePad ? (
                  <Row justify="center">
                    <Col span={3}>
                      <ArrowLeftOutlined
                        className="backward-button"
                        onClick={() => onCloseMachine("")}
                      />
                    </Col>
                    <Col span={12} style={{ textAlign: "center" }}>
                      <h2 style={{ color: "#eeeeee", marginLeft: 100 }}>
                        {machineInfo["machine_id"]}
                      </h2>
                    </Col>
                    <Col span={6}>
                      <div className="dateContainer">
                        <h2 style={{ color: "#eeeeee", marginLeft: 100 }}>
                          {currentDate == "" ? todayString : currentDate}
                        </h2>
                      </div>
                    </Col>
                    <Col span={2} style={{ textAlign: "right" }}>
                      <ToolOutlined 
                        className="app-setting-button"
                        onClick={openInstallConfigDialog}
                      />
                    </Col>
                    <Col span={1} style={{ textAlign: "center" }}>
                      <SettingOutlined
                        className="app-setting-button"
                        onClick={openAppSettingDialog}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={3}>
                      <ArrowLeftOutlined
                        className="backward-button"
                        onClick={() => onCloseMachine("")}
                      />
                    </Col>
                    <Col span={16} style={{ textAlign: "center" }}>
                      <h3 style={{ color: "#eeeeee" }}>
                        {machineInfo["machine_id"]}
                      </h3>
                    </Col>
                    <Col span={5}>
                      <div>
                        <h3 style={{ color: "#eeeeee" }}>
                          {currentDate == "" ? todayString : currentDate}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col span={6}>
                <div className="info-container" style={{ height: 70 }}>
                  <div className="user-name-container">
                    {operatorImage == "" ? (
                      <Avatar
                        className="detail-operator-image-style"
                        size={50}
                        icon={<UserOutlined />}
                      />
                    ) : (
                      <img
                        style={{ width: 50, height: 50 }}
                        className="detail-operator-image-style"
                        src={operatorImage}
                      />
                    )}
                    <h4 className="operator-name-style">{operatorName}</h4>
                  </div>
                  {/*  */}
                </div>
              </Col>

              <Col span={18}>
                <div className="info-container">
                  <IndicatorContainer
                    hstInfo={hstInfo}
                    machineInfo={machineInfo}
                    screenSize={screenSize}
                    lastGantt={lastGantt}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="info-container">
                  <div className="machine-image-container">
                    {machineInfo != "" && (
                      <img
                        style={{ maxWidth: "100%", height: 150 }}
                        src={
                          machineInfo["machine_picture_url"].includes("http")
                            ? machineInfo["machine_picture_url"]
                            : MACHINE_IMAGE_BASE_URL +
                              machineInfo["machine_picture_url"]
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="info-container">
                  <div className="intro-video-container">
                    <VideoCameraOutlined
                      size={100}
                      style={{ fontSize: 100, color: "white" }}
                    />
                  </div>
                </div>
              </Col>
              <Col span={18}>
                <Row>
                  <Col span={8}>
                    <div className="info-container">
                      <UtilizationContainer
                        hstInfo={hstInfo}
                        machineInfo={machineInfo}
                        additionalHstInfo={additionalHstInfo}
                        screenSize={screenSize}
                      />
                    </div>
                  </Col>
                  <Col span={16}>
                    <div className="info-container">
                      <TimelineContainer
                        ganttInfo={ganttInfo}
                        machineInfo={machineInfo}
                        customerInfo={customerInfo}
                        setShowDetailGanttModal={setShowDetailGanttModal}
                        currentDate={currentDate}
                        statusList={statusList}
                        screenSize={screenSize}
                        customer_id={customer_id}
                        security_level={security_level}
                        myGanttList={myGanttList}
                        setMyGanttList={setMyGanttList}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <div className="info-container">
                  <HistoryContainer
                    machineInfo={machineInfo}
                    customerInfo={customerInfo}
                    customer_id={customer_id}
                    // extractMachineData={extractMachineData}
                    setIsMachineDetailPageContentLoading={
                      setIsMachineDetailPageContentLoading
                    }
                    setCurrentDate={setCurrentDate}
                    setMyHstList={setMyHstList}
                    setMyGanttList={setMyGanttList}
                    currentDate={currentDate}
                    todayString={todayString}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginLeft: 50 }}>
            <Row>
              <h3 style={{ color: "#eeeeee" }}>
                {lang(langIndex, "cnc_lastupdate")}:
                {lastGantt["end"] == "undefined"
                  ? ""
                  : " " +
                    GetTimeWithStyle(
                      ConvertTimespanToDateBasedOnTimezone(
                        lastGantt["end"],
                        customerInfo["timezone"]
                      )
                    )}
              </h3>
            </Row>

            <Row>
              <h3 style={{ color: "#eeeeee" }}>
                {lang(langIndex, "cnc_interface")}:{" "}
                {lastGantt["interface"] == "undefined"
                  ? ""
                  : lastGantt["interface"]}
              </h3>
            </Row>
            <Row>
              <h3 style={{ color: "#eeeeee" }}>
                {lang(langIndex, "cnc_version")}:{" "}
                {app_version == undefined || app_version == null
                  ? ""
                  : " Ver " + app_version}
              </h3>
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  ) : (
    // mobile version
    <div className="machine-detail-container">
      {showDetailGanttModal && (
        <DetailGanttModal
          showDetailGanttModal={showDetailGanttModal}
          setShowDetailGanttModal={setShowDetailGanttModal}
          ganttInfo={ganttInfo}
          machineInfo={machineInfo}
          customerInfo={customerInfo}
          currentDate={currentDate}
          customer_id={customer_id}
          
        />
      )}
      <Spin spinning={isMachineDetailPageContentLoading} size="large">
        <Row justify="center" style={{ width: "100%" }}>
          <Col className="detail-content-container">
            <Row justify="center" className="content-table" align="top">
              <Col span={24} style={{ textAlign: "left" }}>
                <Row justify="center">
                  <Col span={3}>
                    <ArrowLeftOutlined
                      className="backward-button"
                      onClick={() => onCloseMachine("")}
                    />
                  </Col>
                  <Col span={12} style={{ textAlign: "center" }}>
                    <h3 style={{ color: "#eeeeee" }}>
                      {machineInfo["machine_id"]}
                    </h3>
                  </Col>
                  <Col span={9}>
                    <div className="dateContainer">
                      <h3 style={{ color: "#eeeeee" }}>
                        {currentDate == "" ? todayString : currentDate}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <div
                      className="info-container user-machine-info-container-mobile"
                      style={{ textAlign: "center" }}
                    >
                      <div style={{ marginTop: 10 }}>
                        {operatorImage == "" ? (
                          <Avatar size={60} icon={<UserOutlined />} />
                        ) : (
                          <img
                            style={{ width: 60, height: 60 }}
                            className="detail-operator-image-style"
                            src={operatorImage}
                          />
                        )}
                      </div>
                      <h4
                        className="operator-name-style"
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          marginTop: 0,
                        }}
                      >
                        {operatorName}
                      </h4>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="info-container user-machine-info-container-mobile">
                      <div className="machine-image-container-mobile">
                        {machineInfo != "" && (
                          <img
                            src={
                              machineInfo["machine_picture_url"].includes(
                                "http"
                              )
                                ? machineInfo["machine_picture_url"]
                                : MACHINE_IMAGE_BASE_URL +
                                  machineInfo["machine_picture_url"]
                            }
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="info-container user-machine-info-container-mobile">
                      <VideoCameraOutlined
                        size={60}
                        style={{ fontSize: 60, color: "white", marginTop: 18 }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <div className="info-container">
                  <IndicatorContainer
                    hstInfo={hstInfo}
                    machineInfo={machineInfo}
                    screenSize={screenSize}
                    lastGantt={lastGantt}
                  />
                </div>
              </Col>

              <Col span={24}>
                <div className="info-container">
                  <UtilizationContainer
                    hstInfo={hstInfo}
                    machineInfo={machineInfo}
                    additionalHstInfo={additionalHstInfo}
                    screenSize={screenSize}
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="info-container">
                  <TimelineContainer
                    ganttInfo={ganttInfo}
                    machineInfo={machineInfo}
                    customerInfo={customerInfo}
                    security_level={security_level}
                    setShowDetailGanttModal={setShowDetailGanttModal}
                    currentDate={currentDate}
                    statusList={statusList}
                    screenSize={screenSize}
                  />
                </div>
              </Col>

              <Col span={24}>
                <div className="info-container">
                  <HistoryContainer
                    machineInfo={machineInfo}
                    customerInfo={customerInfo}
                    customer_id={customer_id}
                    // extractMachineData={extractMachineData}
                    setIsMachineDetailPageContentLoading={
                      setIsMachineDetailPageContentLoading
                    }
                    setCurrentDate={setCurrentDate}
                    setMyHstList={setMyHstList}
                    setMyGanttList={setMyGanttList}
                    currentDate={currentDate}
                    todayString={todayString}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ marginLeft: 10 }}>
            <Row>
              <h3 style={{ color: "#eeeeee" }}>
                {lang(langIndex, "cnc_lastupdate")}:
                {lastGantt["end"] == "undefined"
                  ? ""
                  : " " +
                    GetTimeWithStyle(
                      ConvertTimespanToDateBasedOnTimezone(
                        lastGantt["end"],
                        customerInfo["timezone"]
                      )
                    )}
              </h3>
            </Row>
            <Row>
              <h3 style={{ color: "#eeeeee" }}>
                {lang(langIndex, "cnc_interface")}:{" "}
                {lastGantt["interface"] == "undefined"
                  ? ""
                  : lastGantt["interface"]}
              </h3>
            </Row>
            <Row>
              <h3 style={{ color: "#eeeeee" }}>
                {lang(langIndex, "cnc_version")}:{" "}
                {app_version == undefined || app_version == null
                  ? ""
                  : " Ver " + app_version}
              </h3>
            </Row>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default MachineDetailPage;
