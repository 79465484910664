import React, { useState, useEffect } from "react";

import "./CustomerLogo.css";
import CustomerImageUploader from "./CustomerImageUploader/CustomerImageUploader";
import { useSelector } from "react-redux";
import lang from "../../../../../services/lang";
function CustomerLogo(props) {
  const { customerLogo, setCustomerLogo } = props;
  const { langIndex } = useSelector((x) => x.app);
  return (
    <div>
      <h1 className="customerlogo-title-style">
        {lang(langIndex, "plant_customerlogo")}:
      </h1>
      <div className="customerlogo-value-container-style">
        <CustomerImageUploader
          key="SSSSSSSSS"
          user_picture={customerLogo}
          updateImage={setCustomerLogo}
          isLogo={true}
        />
      </div>
    </div>
  );
}

export default CustomerLogo;
