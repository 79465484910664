import moment from "moment";
import Urls, { postRequest } from "./urls";

import * as actionType from "../redux/actionType";

export function GetAllCustomerInfo(customerString, dispatch, callback) {
  //   console.log("customer", customerString);
  const url = Urls.GET_CUSTOMER_ALL_INFO;
  const param = {
    customer_string: customerString,
  };
  postRequest(url, param, (res) => {
    if (res == null) {
      callback(false);
    }
    // console.log(res);
    dispatch({
      type: actionType.cncSetAllCustomerInfo,
      data: res.data,
    });
    callback(res.data);
  });
}

export function GetInfo(customerId, dispatch) {
  const url = Urls.GET_TABLE;
  const table = customerId + "_info";
  const param = {
    table: table,
    where: "",
  };
  postRequest(url, param, (res) => {
    updateCustomerInfo(customerId, res.data[0], dispatch);
  });
}
export function updateCustomerInfo(customerId, info, dispatch) {
  // console.log(dispatch);
  dispatch({
    type: actionType.cncUpdateCustomerInfo,
    data: {
      customer_id: customerId,
      customer_info: info,
    },
  });
}
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function readOperatorList(callback) {
  const url = Urls.GET_TABLE;
  const table = "user_login_barcode";
  const param = {
    table: table,
    where: "",
  };
  
  postRequest(url, param, (res) => {
    if (res.status == true) {
      callback(res.data);
      //console.log(res);
    } else {
      callback(null);
      //console.log("this don't work");
    }
  });
}

export function GetMachineListDataOneTime(
  customer_id,
  timezone,
  startDate,
  endDate,
  machine_names,
  callback
) {
  // Read gantt and hst data
  const url = Urls.GET_MACHINE_DATA;
  const param = {
    customer_id: customer_id,
    startDate: startDate,
    endDate: endDate,
    machine_names: machine_names,
    timezone: timezone,
  };
  // console.log("Request machine realtime data", param);
  postRequest(url, param, (res) => {
    if (res == null) return;
    // filter gantt data

    // get machine list
    if (res.data.gantt.length > 0) {
      let tmpGantt = [];
      var machineList = res.data.gantt
        .map((item) => item.machine_id)
        .filter(onlyUnique);
      for (var i = 0; i < machineList.length; i++) {
        var tmp = res.data.gantt.filter(
          (item) => item.machine_id == machineList[i]
        );
        tmpGantt = tmpGantt.concat(FilterOneMachineGanttData(tmp));
      }
      res.data.gantt = [...tmpGantt];
    }
    callback(res);
  });
}

export function GetMachineListData(
  customer_id,
  timezone,
  startDate,
  endDate,
  machine_names,
  dispatch
) {
  // Read gantt and hst data
  const url = Urls.GET_MACHINE_DATA;
  const param = {
    customer_id: customer_id,
    startDate: startDate,
    endDate: endDate,
    machine_names: machine_names,
    timezone: timezone,
  };
  // console.log("Request machine realtime data", param);
  postRequest(url, param, (res) => {
    if (res == null) return;
    // filter gantt data

    // get machine list
    // console.log(res.data)
    if (res.data.gantt.length > 0) {
      let tmpGantt = [];
      var machineList = res.data.gantt
        .map((item) => item.machine_id)
        .filter(onlyUnique);
      for (var i = 0; i < machineList.length; i++) {
        var tmp = res.data.gantt.filter(
          (item) => item.machine_id == machineList[i]
        );
        tmpGantt = tmpGantt.concat(FilterOneMachineGanttData(tmp));
      }
      res.data.gantt = [...tmpGantt];
    }

    // add it to redux
    dispatch({
      type: actionType.cncUpdateMachineDetailInfo,
      data: {
        customer_id: customer_id,
        status_list: res.data.statusList,
        hst_list: res.data.hst,
        gantt_list: res.data.gantt,
      },
    });
  });
}

export function GetReportPeriodData(
  customer_id,
  machine_id,
  timezone,
  startDate,
  endDate,
  callback
) {
  const url = Urls.GET_REPORT_PERIOD_DATA;
  const param = {
    customer_id: customer_id,
    machine_id: machine_id,
    timezone: timezone,
    startDate: startDate,
    endDate: endDate,
  };

  //console.log("param", param);
  
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function GetReportPeriodDataByOperator(
  customer_id,
  operator,
  timezone,
  job_id,
  startDate,
  endDate,
  callback
) {
  const url = Urls.GET_REPORT_PERIOD_DATA_BY_OPERATOR;
  const param = {
    customer_id: customer_id,
    operator: operator,
    timezone: timezone,
    job_id: job_id,
    startDate: startDate,
    endDate: endDate,
  };

  //console.log("param", param);
  
  postRequest(url, param, (res) => {
    callback(res);
  });
}


export function GetHSTData(
  customer_id,
  machine_id,
  timezone,
  startDate,
  endDate,
  callback
) {
  const url = Urls.GET_REPORT_PERIOD_DATA;
  const param = {
    customer_id: customer_id,
    machine_id: machine_id,
    timezone: timezone,
    startDate: startDate,
    endDate: endDate,
  };
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function GetTest(customerId, callback) {
  const url = Urls.TEST_URL;
  const table = "sm_ks" + "_info";
  const param = {
    table: table,
    where: "",
  };

  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function GetJobEntryList(customerId, callback) {
  const url = Urls.GET_TABLE;
  const table = customerId + "_jobdata";
  const param = {
    table: table,
    where: "",
  };
  postRequest(url, param, (res) => {
    if (res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}

export function GetOperatorList(customerId, callback) {
  const url = Urls.GET_OPERATOR_LIST;
  const param = {
    customer_id: customerId
  };
  postRequest(url, param, (res) => {
    if (res != null && res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}

export function GetJobList(customerId, callback) {
  const url = Urls.GET_JOB_LIST;
  const param = {
    customer_id: customerId
  };
  postRequest(url, param, (res) => {
    if (res != null && res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}


export function GetMaintenanceEntry(customerId, callback) {
  const url = Urls.GET_TABLE;
  const table = customerId + "_maintenance";
  const param = {
    table: table,
    where: "",
  };

  postRequest(url, param, (res) => {
    if (res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}
export function GetIncycleTimes(param, callback) {
  const url = Urls.GET_INCYCLE_TIMES;

  postRequest(url, param, (res) => {
    if (res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}
export function GetUserList(customerId, callback) {
  const url = Urls.GET_TABLE;
  const table = "user_login_barcode";
  const param = {
    table: table,
    where: "`customer_id`='" + customerId + "'",
  };
  // console.log(param);
  postRequest(url, param, (res) => {
    if (res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}
export function GetMachineGroup(customerId, callback) {
  const url = Urls.GET_MACHINE_GROUP;
  const param = {
    customer_id: customerId,
  };
  postRequest(url, param, (res) => {
    if (res != null && res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}

export function ManageMachineGroup(selCustomerId, info, callback) {
  const url = Urls.MANAGE_MACHINE_GROUP;
  const param = {
    ...info,
    customer_id: selCustomerId,
  };
  postRequest(url, param, (res) => {
    if (res != null && res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}

export function GetMachineList(customerId, callback) {
  const url = Urls.GET_TABLE;
  const table = customerId + "_status";
  const param = {
    table: table,
  };
  // console.log(param);
  postRequest(url, param, (res) => {
    if (res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}

export function AddEntry(param, callback) {
  const url = Urls.ADD_ENTRY;
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function DeleteJobEntry(param, callback) {
  const url = Urls.DELETE_JOB_ENTRY;
  console.log(param, url + "URL");
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function UpdateJobEntry(param, callback) {
  const url = Urls.UPDATE_JOB_ENTRY;
  console.log(param, url + "URL");
  postRequest(url, param, (res) => {
    callback(res);
  });
}


export function AddMaintenanceEntry(param, callback) {
  const url = Urls.ADD_MAINTENANCE_ENTRY;
  console.log(param, url);
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function DeleteMaintenanceEntry(param, callback) {
  const url = Urls.DELETE_MAINTENANCE_ENTRY;
  console.log(param, url + "URL");
  postRequest(url, param, (res) => {
    callback(res);
  });
}
export function UpdateMaintenanceEntry(param, callback) {
  const url = Urls.UPDATE_MAINTENANCE_ENTRY;
  console.log(param, url + "URL");
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function AddUser(param, callback) {
  const url = Urls.ADD_USER;
  console.log("ADDUSER param", param);
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function UpdateUser(param, callback) {
  const url = Urls.UPDATE_USER;
  console.log("UPDATE USER param", param);
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function DeleteUser(param, callback) {
  const url = Urls.DELETE_USER;
  console.log("DELETE USER param", param);
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function AddMachine(param, callback) {
  const url = Urls.ADD_MACHINE;
  // console.log(param);
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function ExecuteQuery(query, callback) {
  const url = Urls.EXECUTE_QUERY;
  const param = {
    query: query,
  };
  postRequest(url, param, (res) => {
    if (res.status == true) {
      callback(res.data);
    } else {
      callback(null);
    }
  });
}

export function GetStatus(customerId, dispatch) {
  const url = Urls.GET_TABLE;
  const table = customerId + "_status";
  const param = {
    table: table,
    where: "",
  };

  postRequest(url, param, (res) => {
    if (res != null) {
      updateMachineListInfo(customerId, res.data, dispatch);
    } else {
      updateMachineListInfo(customerId, [], dispatch);
    }
  });
}
export function updateMachineListInfo(customerId, machineListInfo, dispatch) {
  dispatch({
    type: actionType.cncUpdateMachineListInfo,
    data: {
      customer_id: customerId,
      machine_info: machineListInfo,
    },
  });
}
export function GetHst(customerId, customerDate, callback) {
  const url = Urls.GET_TABLE;
  const table = customerId + "_hst";
  const where = "(`date`= '" + customerDate + "')";
  const param = {
    table: table,
    where: where,
  };
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function GetGantt(customerId, startDate, endDate, callback) {
  const url = Urls.GET_GANTT;
  const table = customerId + "_ganttdata";
  const param = {
    table: table,
    startDate: startDate,
    endDate: endDate,
  };
  postRequest(url, param, (res) => {
    callback(res);
  });
}
// Gets Reporting Info
export function GetReporting(customerId, customerDate, callback) {
  const url = Urls.GET_TABLE;
  const table = customerId + "_reporting";
  const where = "(`date`= '" + customerDate + "')";
  const param = {
    table: table,
    where: where,
  };
  postRequest(url, param, (res) => {
    callback(res);
  });
}

export function GetCustomerCurrentTime(customerTimezone) {
  var currentTime = Math.floor(Date.now() / 1000);
  var offset = new Date().getTimezoneOffset() * 60;
  currentTime += offset;
  currentTime += parseInt(customerTimezone) * 3600;
  return new Date(currentTime * 1000);
}
export function pad(num) {
  const size = 2;
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}
export function ConvertTimespanToDateBasedOnTimezone(timespan, timezone) {
  var offset = new Date().getTimezoneOffset() * 60;
  var resTime = parseInt(timespan);
  resTime += offset;
  // resTime += offset;
  resTime += parseInt(timezone) * 3600;

  return new Date(resTime * 1000);
}
export function GetTimeWithStyle(datetime) {
  var time = moment(datetime);

  return time.format("hh:mm:ss a");
}

function formattedNumber(myNumber) {
  return ("0" + myNumber).slice(-2);
}

export function getBetweenTime(from, to) {
  return humanitizeDuration(parseInt(to) - parseInt(from));
}
export function humanitizeDuration(seconds, secAvailable = true) {
  var duration = moment.duration(seconds, "seconds");
  try {
    var hour = duration.hours() + duration.days() * 24;
    var minutes = duration.minutes();
    var seconds = duration.seconds();

    if (secAvailable == false)
      duration =
        formattedNumber(hour) + "hours " + formattedNumber(minutes) + "minutes";
    else
      duration =
        formattedNumber(hour) +
        ":" +
        formattedNumber(minutes) +
        ":" +
        formattedNumber(seconds);
    return duration;
  } catch (_) {
    return "";
  }
}
export function getDurationInHour(seconds) {
  var duration = moment.duration(seconds, "seconds");
  try {
    var hour = duration.asHours().toFixed(2);
    return hour;
  } catch (_) {
    return "";
  }
}

function FilterOneMachineGanttData(_ganttInfo) {
  let tmpList = [];
  // remove overrided times
  for (var i = 0; i < _ganttInfo.length - 1; i++) {
    if (_ganttInfo[i].end > _ganttInfo[i + 1].start) {
      _ganttInfo[i].end = _ganttInfo[i + 1].start;
    }
  }

  // connect continuous data
  for (var i = 0; i < _ganttInfo.length; i++) {
    var connected = false;

    if (
      i < _ganttInfo.length - 1 &&
      _ganttInfo[i].status.toUpperCase() ==
        _ganttInfo[i + 1].status.toUpperCase() &&
      _ganttInfo[i].end == _ganttInfo[i + 1].start
    ) {
      _ganttInfo[i + 1].start = _ganttInfo[i].start;
      connected = true;
    }

    if (connected == false) {
      tmpList.push(_ganttInfo[i]);
    }
  }
  return tmpList;
}
