import React from "react";
import GaugeChart from "react-gauge-chart/dist/GaugeChart";
import { Progress } from "antd";
import { sizeMobile } from "../../../../services/common/constants";

function IndicatorItem(props) {
  const { id, value, title, textColor, screenSize, isForReport } = props;
  const color = textColor == undefined ? "#eeeeee" : textColor;

  // console.log("Percent-", title, "-", value);

  return screenSize == undefined || screenSize.width >= sizeMobile ? (
    <div style={{ textAlign: "center" }}>
      <GaugeChart
        id={id}
        nrOfLevels={20}
        textColor={color}
        colors={[
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#FFFF00",
          "#FFFF00",
          "#00ff00",
          "#00ff00",
        ]}
        arcWidth={0.3}
        percent={value == null || isNaN(value) ? 0 : value}
        hideText={isForReport == true}
      />

      {textColor == undefined ? (
        title == "OEE" ? (
          <h2 style={{ color: color }}>{title}</h2>
        ) : (
          <h3 style={{ color: color }}>{title}</h3>
        )
      ) : (
        <div>
          {isForReport && (
            <div style={{ fontSize: 12, lineHeight: 0.75 }}>{Math.round(value * 100)}%</div>
          )}
          <h2 style={{ color: color }}>{title}</h2>
        </div>
      )}
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <GaugeChart
        id={id}
        nrOfLevels={20}
        textColor={color}
        colors={[
          "#ff0000",
          "#ff0000",
          "#ff0000",
          "#FFFF00",
          "#FFFF00",
          "#00ff00",
          "#00ff00",
        ]}
        arcWidth={0.3}
        percent={value == null || isNaN(value) ? 0 : value}
      />

      {textColor == undefined ? (
        title == "OEE" ? (
          <h4 style={{ color: color }}>{title}</h4>
        ) : (
          <h5 style={{ color: color }}>{title}</h5>
        )
      ) : (
        <h5 style={{ color: color }}>{title}</h5>
      )}
    </div>
  );
}

export default IndicatorItem;
