import React from "react";
import "./MachineTable.css";
import { Table, Popconfirm } from "antd";
import { MACHINE_IMAGE_BASE_URL } from "../../../../../services/common/urls";
import { useSelector } from "react-redux";
import lang from "../../../../../services/lang";

function MachineTable(props) {
  const { langIndex } = useSelector((x) => x.app);
  const { machineList, onClickEditRecord, onClickDeleteRecord } = props;
  const columns = [
    { title: "Id", dataIndex: "id", key: "id" },
    {
      title: lang(langIndex, "plant_machineid"),
      dataIndex: "machine_id",
      key: "machine_id",
    },
    {
      title: lang(langIndex, "plant_picture"),
      dataIndex: "machine_picture_url",
      key: "machine_picture_url",
      render: (machine_picture_url) => {
        return (
          <img
            className="machine-picture-style"
            src={
              machine_picture_url.includes("http")
                ? machine_picture_url
                : MACHINE_IMAGE_BASE_URL + machine_picture_url
            }
          />
        );
      },
    },
    {
      title: lang(langIndex, "jobentry_operation"),
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <div>
            <a onClick={() => editRecord(record)}>{lang(langIndex, "jobentry_edit")}</a>
            
            
            <Popconfirm
              title={lang(langIndex, "jobentry_suretodelete")}
              onConfirm={() => deleteRecord(record)}
            >
              <a style={{ marginLeft: 10, color: "red" }}>
                {lang(langIndex, "jobentry_delete")}
              </a>
            </Popconfirm>
            
    



        </div>
        );
      },
    },
  ];
  const editRecord = (record) => {
    // console.log(record);
    onClickEditRecord(record);
  };
  const deleteRecord = (record) => {
    // console.log(record);
    onClickDeleteRecord(record);
  };

  return (
    <div>
      <Table
        className="machine-table-style"
        dataSource={machineList}
        columns={columns}
      />
    </div>
  );
}

export default MachineTable;
