import { Button, message } from 'antd'
import React, { useEffect, useState } from 'react'
import './ExportCSVButton.css'
import { ExportToCsv } from 'export-to-csv';
import { GetReportPeriodData, GetReportPeriodDataByOperator } from '../../../../../services/common/cnc_functions';
import { useSelector } from 'react-redux';
import lang from '../../../../../services/lang';

import Urls, { postRequest } from "../../../../../services/common/urls";

function ExportCSVButton(props) {
  const { langIndex } = useSelector(x => x.app)
  const { customer_id, fromReportDate, toReportDate, selectedMachine, selectedOperator, selectedJob, customerInfo, machineInfo } = props;
  const [isGeneratingCSV, setIsGeneratingCSV] = useState(false);

  const onGenerateCSV = () => { //This method will run once the generate CSV button is clicked
    setIsGeneratingCSV(true);

    // this will run if only the machines are selected
    if (selectedMachine != null && selectedMachine.length > 0 && selectedOperator == '' && selectedJob == '') { //Makes sure a valid machine is selected and that there is at least one (or more) machines selected
      //console.log("by machine");
      GetReportPeriodData(customer_id, selectedMachine, customerInfo['timezone'], fromReportDate, toReportDate, res => { //Queries report data from db using customer id, the machine(s) selected, timezone sync, and date settings
        if (res.status == false) { //If operator has not logged time for any machines it will exit the process
          message.error("No Machine"); // prints error message to user
          setIsGeneratingCSV(false);
        } else {
          if (res.data['oee'].length == 0) { //If there is no OEE data (machine has not been run those dates most likely) it will return an error saying there is no data to report
            message.error(lang(langIndex, "msg_no_data_print")); //prints no data to report error
            setIsGeneratingCSV(false);
            return;
          }
          
          exportToCSV({ ...res.data }); // If there is data to report then the CSV export will begin

        }

      })
      // This will run if only the operators are selected
    } else {
      //console.log(selectedOperator);
     try {
       
     
      GetReportPeriodDataByOperator(customer_id, selectedOperator, customerInfo['timezone'], selectedJob, fromReportDate, toReportDate, res => { // Method to get reports for a specific operator from db
        
        if (res.status == false) { //If operator has not logged time for any machines it will exit the process
          message.error("No Data For Selected Operator(s)"); // prints error message to user
          setIsGeneratingCSV(false);
        } else {
          if (res.data['oee'].length == 0) { //If there is no OEE data (machine has not been run those dates most likely) it will return an error saying there is no data to report
            message.error(lang(langIndex, "msg_no_data_print")); //prints no data to report error
            setIsGeneratingCSV(false);
            return;
          }
          if (selectedJob == '') {
            try {
              exportToCSV1({ ...res.data }); // If there is data to report then the CSV export will begin
            } catch (error) {
              message.error("No Data For Selected Operator(s)");
              setIsGeneratingCSV(false);
              return;
            }
          }
          else {
            try {
              exportToCSVJob({ ...res.data }); // If there is data to report then the CSV export will begin
            } catch (error) {
              message.error("No Data For Selected Filter(s)");
              setIsGeneratingCSV(false);
              return;
            }
          }

        }
      })
    
    } catch (error) {
       console.log(error + "HI");
    }


    }

  }



  // Exports CSV if only one operator is selected by user and nothing else
  const exportToCSV1 = (data) => {
    const hstData = data.oee;
    const appSettings = data.appSettings

    var csvData = []; // Array for CSV data
    var g = 1;
    for (var i = 0; i < hstData.length; i++) {

      // skips all entries without an operator
      if (hstData[i].Operator != "") {
        // this will run if the user selects an operator and a machine
          if (selectedMachine != "") {
            console.log(hstData[i].machine_id);
              if (selectedMachine.includes(hstData[i].machine_id)) {
                var hsv = hstData[i];
                var item = {
                  no: g,
                  date: hsv.date,
                  machine: hsv.machine_id,
                  Operator: hsv.Operator,
                  oee: `${hsv.oee / 100}`,
                  utilization: `${hsv.Utilization / 100}`,
                  availability: `${hsv.availability / 100}`,
                  quality: `${hsv.quality / 100}`,
                  performance: `${hsv.performance / 100}`,
                  goodParts: hsv.goodParts,
                  badParts: hsv.badParts,
                };
                g+=1;
              } else { // skip all entries that don't have the user's machine input requirements
                continue;
              }
          } else { // if the user does not input a machine id then it will pull all machine entries
            var hsv = hstData[i];
            var item = {
              no: g,
              date: hsv.date,
              machine: hsv.machine_id,
              Operator: hsv.Operator,
              oee: `${hsv.oee / 100}`,
              utilization: `${hsv.Utilization / 100}`,
              availability: `${hsv.availability / 100}`,
              quality: `${hsv.quality / 100}`,
              performance: `${hsv.performance / 100}`,
              goodParts: hsv.goodParts,
              badParts: hsv.badParts,
            };
            g+=1;
          }
            
      }
      else{
        continue;
      }

      
      item["Sq inches"] = hsv.sq_inches;
      item["Orders"] = hsv.orders;

      if (appSettings.length > 0) {
        item[
          appSettings[0].downtime_reason1 == ""
            ? "Downtime Reason1"
            : appSettings[0].downtime_reason1
        ] = hsv.r1t;
        item[
          appSettings[0].downtime_reason2 == ""
            ? "Downtime Reason2"
            : appSettings[0].downtime_reason2
        ] = hsv.r2t;
        item[
          appSettings[0].downtime_reason3 == ""
            ? "Downtime Reason3"
            : appSettings[0].downtime_reason3
        ] = hsv.r3t;
        item[
          appSettings[0].downtime_reason4 == ""
            ? "Downtime Reason4"
            : appSettings[0].downtime_reason4
        ] = hsv.r4t;
        item[
          appSettings[0].downtime_reason5 == ""
            ? "Downtime Reason5"
            : appSettings[0].downtime_reason5
        ] = hsv.r5t;
        item[
          appSettings[0].downtime_reason6 == ""
            ? "Downtime Reason6"
            : appSettings[0].downtime_reason6
        ] = hsv.r6t;
        item[
          appSettings[0].downtime_reason7 == ""
            ? "Downtime Reason7"
            : appSettings[0].downtime_reason7
        ] = hsv.r7t;
        item[
          appSettings[0].downtime_reason8 == ""
            ? "Downtime Reason8"
            : appSettings[0].downtime_reason8
        ] = hsv.r8t;
      } else {
        item["Downtime Reason1"] = hsv.r1t;
        item["Downtime Reason2"] = hsv.r2t;
        item["Downtime Reason3"] = hsv.r3t;
        item["Downtime Reason4"] = hsv.r4t;
        item["Downtime Reason5"] = hsv.r5t;
        item["Downtime Reason6"] = hsv.r6t;
        item["Downtime Reason7"] = hsv.r7t;
        item["Downtime Reason8"] = hsv.r8t;
      }

      item["Idle - Uncategorized"] = hsv.uncat;
      item["Total Downtime"] = 24 * 3600 - Number(hsv.inCycle);
      item["Total uptime"] = hsv.inCycle;
      csvData.push({ ...item });
    }


    var title = "";
    for (var i = 0; i < selectedMachine.length; i++) {
      if (i === 0) {
        title = title + selectedMachine[i];
      } else {
        title = title + "_" + selectedMachine[i];
      }
    }

    const options = {
      filename: `${title} ${fromReportDate}-${toReportDate}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `${title} ${fromReportDate}-${toReportDate}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csvData);
    setIsGeneratingCSV(false);
  }

  const exportToCSVJob = (data) => {
    const hstData = data.oee;
    const appSettings = data.appSettings
    var g = 1;
    var csvData = [];
    for (var i = 0; i < hstData.length; i++) {
      // machine, operator, job
        if (hstData[i].orders == "") {//|| (!(selectedJob.includes(hstData[i].orders)))
          continue;
        }
        else {

              if (selectedMachine != '' && selectedOperator != '') {
                if (hstData[i].machine_id == '' || hstData[i].Operator == '') {
                  continue;
                }
                else if(!selectedMachine.includes(hstData[i].machine_id)){
                  continue;
                }
                else {
                  var hsv = hstData[i];
                  var item = {
                    no: g,
                    date: hsv.date,
                    machine: hsv.machine_id,
                    Operator: hsv.Operator,
                    oee: `${hsv.oee / 100}`,
                    utilization: `${hsv.Utilization / 100}`,
                    availability: `${hsv.availability / 100}`,
                    quality: `${hsv.quality / 100}`,
                    performance: `${hsv.performance / 100}`,
                    goodParts: hsv.goodParts,
                    badParts: hsv.badParts,
                  };
                  g +=1;
                }

                
              }
              else if (selectedMachine != '' && selectedOperator == '') { // Checks if the user chose a machine but not an operator
                  if (hstData[i].machine_id == '' || !selectedMachine.includes(hstData[i].machine_id)) {
                    continue;
                  }
                  else {
                    var hsv = hstData[i];
                    var item = {
                      no: g,
                      date: hsv.date,
                      machine: hsv.machine_id,
                      Operator: hsv.Operator,
                      oee: `${hsv.oee / 100}`,
                      utilization: `${hsv.Utilization / 100}`,
                      availability: `${hsv.availability / 100}`,
                      quality: `${hsv.quality / 100}`,
                      performance: `${hsv.performance / 100}`,
                      goodParts: hsv.goodParts,
                      badParts: hsv.badParts,
                    };
                    g+=1;
                  }

              }
              else if (selectedMachine == '' && selectedOperator != '') {// Checks if the user chose an operator but not a machine
                      if (hstData[i].Operator == '') {
                        continue;
                      }
                      else {

                        var hsv = hstData[i];
                        var item = {
                          no: g,
                          date: hsv.date,
                          machine: hsv.machine_id,
                          Operator: hsv.Operator,
                          oee: `${hsv.oee / 100}`,
                          utilization: `${hsv.Utilization / 100}`,
                          availability: `${hsv.availability / 100}`,
                          quality: `${hsv.quality / 100}`,
                          performance: `${hsv.performance / 100}`,
                          goodParts: hsv.goodParts,
                          badParts: hsv.badParts,
                        };
                        g += 1;
                      }
          }

              else {
                var hsv = hstData[i];
                var item = {
                  no: g,
                  date: hsv.date,
                  machine: hsv.machine_id,
                  Operator: hsv.Operator,
                  oee: `${hsv.oee / 100}`,
                  utilization: `${hsv.Utilization / 100}`,
                  availability: `${hsv.availability / 100}`,
                  quality: `${hsv.quality / 100}`,
                  performance: `${hsv.performance / 100}`,
                  goodParts: hsv.goodParts,
                  badParts: hsv.badParts,
                };
                g+=1;
              }
            }


        item["Sq inches"] = hsv.sq_inches;
        item["Orders"] = hsv.orders;

        if (appSettings.length > 0) {
          item[
            appSettings[0].downtime_reason1 == ""
              ? "Downtime Reason1"
              : appSettings[0].downtime_reason1
          ] = hsv.r1t;
          item[
            appSettings[0].downtime_reason2 == ""
              ? "Downtime Reason2"
              : appSettings[0].downtime_reason2
          ] = hsv.r2t;
          item[
            appSettings[0].downtime_reason3 == ""
              ? "Downtime Reason3"
              : appSettings[0].downtime_reason3
          ] = hsv.r3t;
          item[
            appSettings[0].downtime_reason4 == ""
              ? "Downtime Reason4"
              : appSettings[0].downtime_reason4
          ] = hsv.r4t;
          item[
            appSettings[0].downtime_reason5 == ""
              ? "Downtime Reason5"
              : appSettings[0].downtime_reason5
          ] = hsv.r5t;
          item[
            appSettings[0].downtime_reason6 == ""
              ? "Downtime Reason6"
              : appSettings[0].downtime_reason6
          ] = hsv.r6t;
          item[
            appSettings[0].downtime_reason7 == ""
              ? "Downtime Reason7"
              : appSettings[0].downtime_reason7
          ] = hsv.r7t;
          item[
            appSettings[0].downtime_reason8 == ""
              ? "Downtime Reason8"
              : appSettings[0].downtime_reason8
          ] = hsv.r8t;
        } else {
          item["Downtime Reason1"] = hsv.r1t;
          item["Downtime Reason2"] = hsv.r2t;
          item["Downtime Reason3"] = hsv.r3t;
          item["Downtime Reason4"] = hsv.r4t;
          item["Downtime Reason5"] = hsv.r5t;
          item["Downtime Reason6"] = hsv.r6t;
          item["Downtime Reason7"] = hsv.r7t;
          item["Downtime Reason8"] = hsv.r8t;
        }

        item["Idle - Uncategorized"] = hsv.uncat;
        item["Total Downtime"] = 24 * 3600 - Number(hsv.inCycle);
        item["Total uptime"] = hsv.inCycle;
        csvData.push({ ...item });
      }
      


      var title = "";
      for (var i = 0; i < selectedMachine.length; i++) {
        if (i === 0) {
          title = title + selectedMachine[i];
        } else {
          title = title + "_" + selectedMachine[i];
        }
      }

      const options = {
        filename: `${title} ${fromReportDate}-${toReportDate}`,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `${title} ${fromReportDate}-${toReportDate}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(csvData);
      setIsGeneratingCSV(false);
    }

    const exportToCSV = (data) => {
      const hstData = data.oee;
      const appSettings = data.appSettings

      var csvData = [];
      for (var i = 0; i < hstData.length; i++) {


        var hsv = hstData[i];
        var item = {
          no: i + 1,
          date: hsv.date,
          machine: hsv.machine_id,
          Operator: hsv.Operator,
          oee: `${hsv.oee / 100}`,
          utilization: `${hsv.Utilization / 100}`,
          availability: `${hsv.availability / 100}`,
          quality: `${hsv.quality / 100}`,
          performance: `${hsv.performance / 100}`,
          goodParts: hsv.goodParts,
          badParts: hsv.badParts
        }
        item['Sq inches'] = hsv.sq_inches
        item['Orders'] = hsv.orders

        if (appSettings.length > 0) {
          item[appSettings[0].downtime_reason1 == '' ? 'Downtime Reason1' : appSettings[0].downtime_reason1] = hsv.r1t
          item[appSettings[0].downtime_reason2 == '' ? 'Downtime Reason2' : appSettings[0].downtime_reason2] = hsv.r2t
          item[appSettings[0].downtime_reason3 == '' ? 'Downtime Reason3' : appSettings[0].downtime_reason3] = hsv.r3t
          item[appSettings[0].downtime_reason4 == '' ? 'Downtime Reason4' : appSettings[0].downtime_reason4] = hsv.r4t
          item[appSettings[0].downtime_reason5 == '' ? 'Downtime Reason5' : appSettings[0].downtime_reason5] = hsv.r5t
          item[appSettings[0].downtime_reason6 == '' ? 'Downtime Reason6' : appSettings[0].downtime_reason6] = hsv.r6t
          item[appSettings[0].downtime_reason7 == '' ? 'Downtime Reason7' : appSettings[0].downtime_reason7] = hsv.r7t
          item[appSettings[0].downtime_reason8 == '' ? 'Downtime Reason8' : appSettings[0].downtime_reason8] = hsv.r8t
        } else {
          item['Downtime Reason1'] = hsv.r1t
          item['Downtime Reason2'] = hsv.r2t
          item['Downtime Reason3'] = hsv.r3t
          item['Downtime Reason4'] = hsv.r4t
          item['Downtime Reason5'] = hsv.r5t
          item['Downtime Reason6'] = hsv.r6t
          item['Downtime Reason7'] = hsv.r7t
          item['Downtime Reason8'] = hsv.r8t
        }

        item['Idle - Uncategorized'] = hsv.uncat
        item['Total Downtime'] = 24 * 3600 - Number(hsv.inCycle)
        item['Total uptime'] = hsv.inCycle
        csvData.push({ ...item })
      }


      var title = "";
      for (var i = 0; i < selectedMachine.length; i++) {
        if (i === 0) {
          title = title + selectedMachine[i];
        } else {
          title = title + "_" + selectedMachine[i];
        }
      }

      const options = {
        filename: `${title} ${fromReportDate}-${toReportDate}`,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: `${title} ${fromReportDate}-${toReportDate}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(csvData);
      setIsGeneratingCSV(false);
    }

    return (
      <div>
        <Button type="primary"
          disabled={selectedMachine.length == 0 && selectedOperator.length == 0 && selectedJob.length == 0}
          loading={isGeneratingCSV}
          onClick={onGenerateCSV}
        >
          {lang(langIndex, "report_generatecsv")}
        </Button>
      </div>
    )
  }

  export default ExportCSVButton
