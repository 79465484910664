import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { GetStatus } from '../../services/common/cnc_functions';
import MaintenancePage from '../CNCPages/maintenancePage/maintenancePage';
import './MaintenancePages.css'
function MainTenancePages(props) {
    const selCustomerId = props.app.customer_id;

    useEffect(() => {
        GetStatus(selCustomerId, props.dispatch);
    }, [selCustomerId]);

    const machineList = props.cncService.machineInfoList[selCustomerId]
    // console.log(machineList)
    var isPageLoading = machineList == undefined;

    return (
        <div>
            {isPageLoading && (
                <Spin spinning={isPageLoading} size="large">
                    <div style={{ width: "100%", textAlign: "center", fontSize: 20, color: "#eeeeee", height: '100vh', marginTop: 200, background: 'transparent' }}>
                        Loading Machine Info...
                    </div>
                </Spin>
            )}

            {!isPageLoading && (< MaintenancePage
                selCustomerId={selCustomerId}
                machineList={machineList}
            />)}
        </div>
    )
}

const mapStateToProps = (state, props) => ({
    cncService: state.cncService,
    app: state.app,
})
const mapDispatchToProps = (dispatch, props) => ({
    dispatch: dispatch
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainTenancePages)

