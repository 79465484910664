import React, { useEffect, useState } from "react";
import "./MachineSettings.css";
import { message } from "antd";
import MachineManageDialog from "./MachineManageDialog/MachineManageDialog";
import MachineTable from "./MachineTable/MachineTable";
import {
  GetMachineList,
  AddMachine,
  ExecuteQuery,
} from "../../../../services/common/cnc_functions";
import { useSelector } from "react-redux";
import lang from "../../../../services/lang";
function MachineSettings(props) {
  const { langIndex } = useSelector((state) => state.app);
  const { selCustomerId, customerInfo } = props;
  const [isShowModal, setIsShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [initMachineInfo, setInitMachineInfo] = useState(null);
  const { machineList, setMachineList } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { machineInfoList, setMachineInfoList } = props;

  // console.log(selCustomerId);
  useEffect(() => {
    GetMachineList(selCustomerId, (machineList) => {
      if (machineList != null || machineList.length > 0) {
        machineList.forEach(function (entry) {
          entry.key = entry.id;
        });
        setMachineList(machineList);
      } else {
      }
    });
  }, []);
  const onClickEditRecord = (record) => {
    setInitMachineInfo(record);
    setIsShowModal(true);
  };

  const onClickDeleteRecord = (record) => {
    setInitMachineInfo(record);
    setIsDeleting(true);
    deleteMachine(record);
  };

  const onClickAddMachine = () => {
    setInitMachineInfo(null);
    setIsShowModal(true);
  };
  const addMachine = (machineInfo) => {
    machineInfo["customer_id"] = selCustomerId;
    var id = machineList.length == 0 ? 1 :
      Math.max.apply(
        Math,
        machineList.map(function (o) {
          return o.id;
        })
      ) + 1;

    machineInfo["id"] = id;

    AddMachine(machineInfo, (res) => {
      if (res == null) return;
      if (res.status == true) {
        var newItem = { ...machineInfo, key: machineInfo.id };
        setMachineList([...machineList, newItem]);
        message.success(lang(langIndex, "msg_update_success"));
        setIsShowModal(false);
        var tmpMachineInfoList = machineInfoList;
        tmpMachineInfoList[selCustomerId] = machineList;
        setMachineInfoList(tmpMachineInfoList);
      } else {
        message.error(lang(langIndex, "msg_something_wrong"));
      }
    });
  };


  const editMachine = (machineInfo) => {
    const newData = [...machineList];

    const index = newData.findIndex((item) => item.key === machineInfo.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...machineInfo });

      const newItem = newData[index];
      let query =
        "UPDATE " +
        "`" +
        selCustomerId +
        "_status` " +
        "SET " +
        "`machine_id`='" +
        newItem.machine_id +
        "', " +
        "`machine_picture_url`='" +
        newItem.machine_picture_url +
        "' " +
        "WHERE `id`='" +
        newItem.id +
        "'";
      ExecuteQuery(query, (res) => {
        message.success(lang(langIndex, "msg_update_success"));
      });
      

      var tmpMachineInfoList = newData;

      //console.log("debuging", tmpMachineInfoList);


      tmpMachineInfoList[selCustomerId] = newData;
      setMachineInfoList(tmpMachineInfoList);

      setMachineList(newData);
      setIsShowModal(false);
    } else {
      message.error(lang(langIndex, "msg_something_wrong"));
    }
  };

  const deleteMachine = (machineInfo) => {
    setIsDeleting(true);
    const newData = [...machineList];

    const index = newData.findIndex((item) => item.key === machineInfo.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...machineInfo });

      const newItem = newData[index];
      let query =
        "DELETE FROM " +
        "`" +
        selCustomerId +
        "_status` " +
       
        "WHERE `id`='" +
        newItem.id +
        "'";
      ExecuteQuery(query, (res) => {
        message.success(lang(langIndex, "msg_update_success"));
        
      });
      newData.splice(index,1);
      var tmpMachineInfoList = newData;
      //console.log("debuging", tmpMachineInfoList);
      setMachineList(newData);
      setMachineInfoList(tmpMachineInfoList);
      
      setIsShowModal(false);
      
    } else {
      message.error(lang(langIndex, "msg_something_wrong"));
    }
  };


  return (
    <div>
      {isShowModal === true && (
        <MachineManageDialog
          initMachineInfo={initMachineInfo}
          addMachine={addMachine}
          editMachine={editMachine}
          deleteMachine={deleteMachine}
          isShowModal={isShowModal}
          isDeleting={isDeleting}
          setIsDeleting={setIsDeleting}
          setIsShowModal={setIsShowModal}
        />
      )}
      <div className="machine-setting-title-container-style">
        <h1
          className="machine-setting-page-title"
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{ display: "inline-block", marginRight: 50 }}
        >
          {lang(langIndex, "plant_machinesetting")}
        </h1>
        <a
          style={{ display: isCollapsed ? "none" : "inline-block" }}
          onClick={onClickAddMachine}
        >
          {" "}
          + {lang(langIndex, "plant_addmachine")}
        </a>
      </div>
      <div
        style={{ display: isCollapsed ? "none" : "block" }}
        className="machine-setting-machine-table-style"
      >
        <MachineTable
          machineList={machineList}
          onClickEditRecord={onClickEditRecord}
          onClickDeleteRecord={onClickDeleteRecord}
        />
      </div>
    </div>
  );
}

export default MachineSettings;
